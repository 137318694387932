import styled, { css } from 'styled-components';
import media from 'utils/mediaqueries';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black1};

  padding-bottom: 30rem;
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black2};
`;

export const CarouselExample = styled.div`
  width: 740px;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black2};

  margin-top: 30px;
`;

export const Category = styled.div`
  width: 100%;
  max-height: 48px;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 60px;
  margin-top: 40px;
`;

interface CategoryProps {
  type: 'Esportes' | 'Música' | 'Entretenimento';
}

export const CategoryBar = styled.div<CategoryProps>`
  width: 3px;
  height: 34px;
  margin-right: 14px;
  background-color: ${props => {
    switch (props.type) {
      case 'Esportes':
        return props.theme.colors.blue2;
      case 'Música':
        return props.theme.colors.green1;
      case 'Entretenimento':
        return props.theme.colors.pink1;
      default:
        return props.theme.colors.black2;
    }
  }};
`;

export const CategoryTitle = styled.h5`
  font-family: Poppins;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
`;

interface CarouselProps {
  isHovering?: boolean;
  last?: boolean;
}

export const CarouselExample2 = styled.div<CarouselProps>`
  width: 100%;
  height: ${props => (props.isHovering ? '30rem' : '30rem')};

  display: flex;
  flex-direction: row;
  padding: 0 60px;
  margin-top: 30px;
  margin-bottom: ${props => (props.last ? '60px' : '0')};

  overflow: hidden;
  /*check scrolling carousel*/
  position: relative;
  /* transition-delay: 0.5s; */

  &:hover {
    overflow: ${props => (props.isHovering ? 'visible' : 'hidden')};
    /*check scrolling carousel*/
    z-index: 5;
  }
`;

export const CarouselItem = styled.div`
  width: 356px;
  height: 200px;

  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black2};

  margin-top: 30px;
  margin-right: 30px;
`;

export const Text = styled.h5`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.colors.white1};
`;

export const Button = styled.button`
  margin-top: 16px;

  width: 78px;
  height: 33px;
  border-radius: 5px;
  border: 0;
  background-color: ${props => props.theme.colors.blue1};
  color: ${props => props.theme.colors.white1};
`;

export const CarouselContent = styled.div`
  /* height: 500px; */
  width: 100vw;
  /* margin-top: 85px; */
`;

export const BackgroundDiv = styled.div`
  height: 540px;
  width: 100%;
  /* margin-top: 85px; */
`;

export const Footer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 5;

  ${media.lessThan('sm')} {
    display: none;
  }
`;

export const HeaderCategories = styled.div`
  width: 100%;
  display: flex;
  background-color: ${props => props.theme.colors.black2};
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.black3};
  padding: 1.2rem 4.8rem;

  position: sticky;
  z-index: 5;
  top: 6rem;

  ${media.lessThan('md')} {
    padding: 1.2rem 2.1rem;
  }

  ${media.lessThan('sm')} {
    overflow-x: auto;
    gap: 3rem;
    padding: 8px 1.2rem;
  }
`;

export const ContentChips = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

interface iChipsProps {
  active: boolean;
}

export const Chips = styled.div<iChipsProps>`
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active, theme }) =>
    active ? theme.colors.black3 : theme.colors.white1};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.white2 : theme.colors.black3};
  border-radius: 5px;
  padding: 9px 20px;
  font-size: 14px;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.colors.white5};
  }

  :focus {
    border: 2px solid ${props => props.theme.colors.blue1};
  }

  ${({ active }) =>
    active &&
    css`
      &:hover {
        background-color: ${props => props.theme.colors.white1};
      }
    `}
`;

export const HomeBanner = styled.img`
  background-size: cover;
  width: 100%;
`;

export const ContainerEvents = styled.div`
  width: 100%;
  margin-top: 2.4rem;

  ${media.lessThan('sm')} {
    margin-top: 0;
  }
`;

export const ContentEvents = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  padding: 20px 188px;

  ${media.lessThan('xxl')} {
    padding: 0px 47px 0px 47px;
  }

  ${media.lessThan('xl')} {
    padding: 20px 75px;
  }

  ${media.lessThan('lg')} {
    padding: 20px 60px;
  }

  ${media.lessThan('md')} {
    padding: 20px 21px;
  }

  ${media.lessThan('sm')} {
    padding: 0;
  }
`;

export const EmptyDiv = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  max-width: 380px;
`;
