import styled, { keyframes } from 'styled-components';
import query from 'utils/mediaqueries';

const timeToAnimate = '2s';

const growUpTextAnimation = keyframes`
  from {
    width: 0;
    overflow: hidden;
    display: block;
  }
  to {
    width: auto;
    overflow: visible;
    display: block;
  }
`;

const growUpButtonAnimation = keyframes`
  0% {
    width: 4.8rem;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
`;

export const Container = styled.button`
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  z-index: 10;
  width: fit-content;
  height: 4.8rem;
  max-width: 18.6rem;
  padding: 1.6rem;
  border-radius: 5rem;
  background: ${({ theme }) => theme.colors.blue2};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  border: none;

  cursor: pointer;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  & svg.icon-float-button path,
  & svg.icon-float-button {
    min-width: 1.6rem;
    min-height: 1.6rem;
    transition: all 0.2s ease-in-out;
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.blue1};
    color: ${({ theme }) => theme.colors.white2};
  }

  &:focus-visible:not(:disabled) {
    outline: 0.2rem solid ${({ theme }) => theme.colors.white2};
    box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.colors.blue1};
  }

  &:active:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.blue2};
    outline: 0.1rem solid ${({ theme }) => theme.colors.white2};

    & > .float-button-label {
      color: ${({ theme }) => theme.colors.blue1};
    }

    & > svg.icon-float-button path {
      fill: ${({ theme }) => theme.colors.blue1};
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.black1};
    cursor: default;

    & > .float-button-label {
      color: ${({ theme }) => theme.colors.black3};
    }

    & > svg.icon-float-button path {
      fill: ${({ theme }) => theme.colors.black3};
    }
  }

  ${query.lessThan('sm')} {
    width: 4.8rem;
    height: 4.8rem;

    &:hover {
      animation: ${growUpButtonAnimation} ${timeToAnimate};
      animation-fill-mode: both;
    }
  }

  transition: all 0.2s ease-in-out;
`;

export const FloatButtonLabel = styled.p.attrs({
  className: 'float-button-label',
})`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.white2};
  margin: 0;
  white-space: nowrap;

  margin-left: 1.2rem;

  text-align: flex-end;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${query.lessThan('sm')} {
    width: 0;
    overflow: hidden;

    ${Container}:hover & {
      animation: ${growUpTextAnimation} 0.2s linear;
      animation-fill-mode: both;
    }
  }

  transition: all 0.2s ease-in-out;
`;
