import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { UnexpectedError, Forbidden, NotFound } from 'domain/errors';
import { PostConfirmUser } from 'domain/usecases/user/remote/ConfirmUser';
import 'infra/global/variables';

export class RemotePostConfirmUser implements PostConfirmUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<PostConfirmUser.Model>;

  constructor(url: string, httClient: HttpClient<PostConfirmUser.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  post = async (
    params: PostConfirmUser.Params,
  ): Promise<PostConfirmUser.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        return HttpStatusCode.badRequest;
      case HttpStatusCode.unauthorized:
        return HttpStatusCode.unauthorized;
      case HttpStatusCode.unprocessableEntity:
        return HttpStatusCode.unprocessableEntity;
      default:
        throw new UnexpectedError();
    }
  };
}
