import { iStore } from 'domain/interfaces/models';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

type DeviceOption = {
  label: string;
  value: string;
};

interface AudioDeviceConfigureContextProps {
  outputDevices: DeviceOption[];
  inputDevices: DeviceOption[];
  selectedInputDevice: DeviceOption;
  selectedOutputDevice: DeviceOption;
  handleSelectInputDevice: (device: DeviceOption) => void;
  handleSelectOutputDevice: (device: DeviceOption) => void;
}

interface AudioDeviceConfigureProviderProps {
  children: React.ReactNode;
}

export const AudioDeviceConfigureContext =
  createContext<AudioDeviceConfigureContextProps>(
    {} as AudioDeviceConfigureContextProps,
  );

const AudioDeviceConfigureProvider: React.FC<
  AudioDeviceConfigureProviderProps
> = ({ children }) => {
  const wrtc = useSelector((store: iStore) => store.wrtc);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedInputDevice, setSelectedInputDevice] = useState<DeviceOption>(
    () => {
      const device = localStorage.getItem('@netfans/inputDevice');
      if (device) return JSON.parse(device);
      return null;
    },
  );
  const [selectedOutputDevice, setSelectedOutputDevice] =
    useState<DeviceOption>(() => {
      const device = localStorage.getItem('@netfans/outputDevice');
      if (device) return JSON.parse(device);
      return null;
    });

  const formattedInputDevices = useMemo(() => {
    return devices
      ?.map(device => {
        if (device.kind !== 'audioinput') return null;

        return {
          label: device.label || 'Microfone',
          value: device.deviceId,
        };
      })
      .filter(Boolean) as DeviceOption[];
  }, [devices]);

  const formattedOutputDevices = useMemo(() => {
    return devices
      ?.map(device => {
        if (device.kind !== 'audiooutput') return null;

        return {
          label: device.label || 'Alto-falantes',
          value: device.deviceId,
        };
      })
      .filter(Boolean) as DeviceOption[];
  }, [devices]);

  const attachSinkId = useCallback((element: any, device: DeviceOption) => {
    if (typeof element?.sinkId !== 'undefined') {
      element
        .setSinkId(device.value)
        .then(() => {
          setSelectedOutputDevice(device);
        })
        .catch((error: any) => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
        });
    } else {
      console.warn('Browser does not support output device selection.');
    }
  }, []);

  const setInputDevice = useCallback(
    (device: DeviceOption) => {
      try {
        if (wrtc && wrtc.setAudioInputDevice) {
          wrtc.setAudioInputDevice(device.value);
          setSelectedInputDevice(device);
          localStorage.setItem('@netfans/inputDevice', JSON.stringify(device));
        }
      } catch (error) {
        console.error('Error setting input device', error);
      }
    },
    [wrtc],
  );

  const setOutputDevice = useCallback(
    (device: DeviceOption) => {
      if (wrtc && wrtc.trySetAudioOutputDevice) {
        wrtc
          .trySetAudioOutputDevice(device.value)
          .then(() => {
            const audios =
              document.getElementById('audioContainer')?.childNodes;
            if (audios)
              for (let i = 0; i < audios?.length; i += 1) {
                attachSinkId(audios[i], device);
              }
            const videoTrack = document.getElementById('eventLoopbackAudio');
            const audioTrack = document.getElementById('mixedaudio');

            attachSinkId(videoTrack, device);
            attachSinkId(audioTrack, device);

            setSelectedOutputDevice(device);
            localStorage.setItem(
              '@netfans/outputDevice',
              JSON.stringify(device),
            );
          })
          .catch(err => {
            console.error('Error setting output device', err);
          });
      }
    },
    [attachSinkId, wrtc],
  );

  const loadDevices = useCallback(async () => {
    try {
      if (wrtc.getMediaDevicesMonitor) {
        const devicesMonitor = await wrtc.getMediaDevicesMonitor();
        const response = await devicesMonitor.enumMediaDevices();
        setDevices(response);

        const foundInputDefault =
          response?.find(
            (device: MediaDeviceInfo) =>
              device.kind === 'audioinput' && device.deviceId === 'default',
          ) ??
          response?.find((device: MediaDeviceInfo) => {
            return device.kind === 'audioinput';
          });

        const foundOutputDefault =
          response?.find(
            (device: MediaDeviceInfo) =>
              device.kind === 'audiooutput' && device.deviceId === 'default',
          ) ??
          response?.find((device: MediaDeviceInfo) => {
            return device.kind === 'audiooutput';
          });

        setInputDevice(
          selectedInputDevice ?? {
            label: foundInputDefault?.label || 'Microfone',
            value: foundInputDefault?.deviceId || 'default',
          },
        );
        setOutputDevice(
          selectedOutputDevice ?? {
            label: foundInputDefault?.label || 'Alto-falantes',
            value: foundOutputDefault?.deviceId || 'default',
          },
        );
      }
    } catch (error) {
      // TODO: FIX - firefox bug: is not listing the default device AND output devices
      console.error('Firefox error, try another browser', error);
    }
  }, [
    selectedInputDevice,
    selectedOutputDevice,
    setInputDevice,
    setOutputDevice,
    wrtc,
  ]);

  useEffect(() => {
    if (wrtc && wrtc.getMediaDevicesMonitor) {
      const devicesMonitor = wrtc.getMediaDevicesMonitor();
      devicesMonitor.addEventListener('deviceListChanged', loadDevices);
    }

    return () => {
      if (wrtc && wrtc.getMediaDevicesMonitor) {
        const devicesMonitor = wrtc.getMediaDevicesMonitor();
        devicesMonitor.removeEventListener('deviceListChanged', loadDevices);
      }
    };
  }, [loadDevices, wrtc]);

  useEffect(() => {
    if (selectedInputDevice) setInputDevice(selectedInputDevice);
    if (selectedOutputDevice) setOutputDevice(selectedOutputDevice);
  }, [
    selectedInputDevice,
    selectedOutputDevice,
    setInputDevice,
    setOutputDevice,
  ]);

  return (
    <AudioDeviceConfigureContext.Provider
      value={{
        outputDevices: formattedOutputDevices,
        inputDevices: formattedInputDevices,
        selectedInputDevice,
        selectedOutputDevice,
        handleSelectInputDevice: setInputDevice,
        handleSelectOutputDevice: setOutputDevice,
      }}
    >
      {children}
    </AudioDeviceConfigureContext.Provider>
  );
};

function useAudioDeviceConfigure(): AudioDeviceConfigureContextProps {
  const context = useContext(AudioDeviceConfigureContext);

  if (!context) {
    throw new Error(
      'useAudioDeviceConfigure must be used within an AudioDeviceConfigureProvider',
    );
  }

  return context;
}

export { AudioDeviceConfigureProvider, useAudioDeviceConfigure };
