import React, { useEffect } from 'react';
import JoinPage from 'presentation/pages/Join/JoinPage';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { useHistory, useParams } from 'react-router';
import { makeRemoteAuthenticateTvConnection } from '../../usecases/tv/AuthenticateTvConnectionFactory';

interface iParams {
  short: string;
}

export const JoinFactory: React.FC = () => {
  const { user } = useSelector((store: iStore) => store.auth);

  const params = useParams<iParams>();
  const { push } = useHistory();

  useEffect(() => {
    if (user) {
      makeRemoteAuthenticateTvConnection()
        .auth({
          short: params.short,
        })
        .then(() => {
          push('/embed/rooms/36/room/1');
        })
        .catch(err => {
          console.log('err: ', err);
        });
    }
  }, [user, params, push]);

  return <JoinPage />;
};
