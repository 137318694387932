/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-wrap-multilines */
import { IconHelp, IconPlus } from 'presentation/base/icons';
import EventSmallPreview from 'presentation/components/EventSmallPreview';
import Header from 'presentation/components/Header';
import NewHomeBanner from 'presentation/components/HomeBanner';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';

import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { useHistory } from 'react-router';
import { makeArrayLengthMultipleOfN } from 'utils/makeArrayLengthMultipleOfN';
import { Button } from '../UI';
import FloatButton from '../UI/FloatButton';
import {
  Chips,
  Container,
  ContainerEvents,
  ContentChips,
  ContentEvents,
  EmptyDiv,
  HeaderCategories,
} from './styles';

interface CarouselItem {
  cover: string;
  title: string;
  event?: iEventItem;
}

interface iEvents {
  sportsCount: number;
  musicCount: number;
  entertainmentCount: number;
  events: iEventItem[];
}

const options = ['Todos', 'Esporte', 'Música', 'Entretenimento', 'Turfe'];

const MainPage: React.FC = () => {
  const { results, loading } = useSelector((store: iStore) => store.event);
  const { accessToken } = useSelector((store: iStore) => store.auth);

  const [hover, setHover] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [events, setEvents] = useState<iEvents>({
    sportsCount: 0,
    entertainmentCount: 0,
    musicCount: 0,
    events: [],
  });

  const history = useHistory();

  const redirectToCreateEventPage = () => {
    // verify if user is logged in
    if (!accessToken) {
      makeReduxActiveMessage().active({
        active: MessageOptions.loginModal,
      });
    }
    history.push('/new-event');
  };
  const eventsPerPage = 10;

  const loaderRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(entities => {
      const target = entities[0];

      if (target.isIntersecting) {
        setCurrentPage(old => old + 1);
      }
    }, observerOptions);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
  }, []);

  useEffect(() => {
    makeReduxUpdateWRTCInfo().update({ insideRoom: false });
  }, []);

  useEffect(() => {
    const updatedEvents: iEvents = {
      sportsCount: 0,
      musicCount: 0,
      entertainmentCount: 0,
      events: results,
    } as iEvents;

    setEvents(updatedEvents);
  }, [results]);

  const mappedEvents = useMemo(() => {
    return makeArrayLengthMultipleOfN(events?.events, 4);
  }, [events.events]);

  const currentEvents = useMemo(() => {
    const indexOfLastEvent = currentPage * eventsPerPage;

    return mappedEvents?.slice(0, indexOfLastEvent);
  }, [currentPage, mappedEvents]);

  return (
    <Container>
      {!!accessToken && (
        <FloatButton icon={IconPlus} action={redirectToCreateEventPage}>
          Agendar sessão
        </FloatButton>
      )}

      <Header />
      <HeaderCategories>
        <ContentChips>
          {options?.map((option, index) => (
            <>
              <Chips
                active={selectedOption === index}
                key={option}
                onClick={() => setSelectedOption(index)}
              >
                {option}
              </Chips>
            </>
          ))}
        </ContentChips>
        <Button
          icon={IconHelp}
          colorstyle="black3"
          size="very small"
          iconSize={20}
        />
      </HeaderCategories>
      <div style={{ width: '100%' }}>
        <NewHomeBanner />
      </div>
      {/* <CarouselContent>
        <ResponsiveCarousel skeleton={loading} content={pictures} />
      </CarouselContent> */}
      {/* // TODO: Quando o back-end tiver usuários admin implementar a lógica de que se o usuário for um admin e estiver logado renderiza o componente. [DP] */}
      {/* <EventCreation /> */}
      <ContainerEvents>
        {currentEvents?.length > 0 && selectedOption === 0 && (
          <ContentEvents>
            {currentEvents?.map(item => {
              if (!item) return <EmptyDiv />;

              const hasCategory = item.contentType?.id === selectedOption;

              if (!hasCategory)
                return (
                  <EventSmallPreview
                    key={item.id}
                    skeleton={loading}
                    className="item"
                    handleHover={setHover}
                    image={item.thumbnail}
                    event={item}
                  />
                );
              return null;
            })}
          </ContentEvents>
        )}

        {events?.sportsCount > 0 && selectedOption === 1 && (
          <ContentEvents>
            {currentEvents?.map(item => {
              if (!item) return <EmptyDiv />;

              const hasCategory = item.contentType?.id === selectedOption;

              if (hasCategory)
                return (
                  <EventSmallPreview
                    key={item.id}
                    skeleton={loading}
                    className="item"
                    handleHover={setHover}
                    image={item.thumbnail}
                    event={item}
                  />
                );
              return null;
            })}
          </ContentEvents>
        )}

        {events?.musicCount > 0 && selectedOption === 2 && (
          <ContentEvents>
            {currentEvents?.map(item => {
              if (!item) return <EmptyDiv />;

              const hasCategory = item.contentType?.id === selectedOption;

              if (hasCategory)
                return (
                  <EventSmallPreview
                    key={item.id}
                    skeleton={loading}
                    className="item"
                    handleHover={setHover}
                    image={item.thumbnail}
                    event={item}
                  />
                );
              return null;
            })}
          </ContentEvents>
        )}

        {events?.entertainmentCount > 0 && selectedOption === 3 && (
          <ContentEvents>
            {currentEvents?.map(item => {
              if (!item) return <EmptyDiv />;

              const hasCategory = item.contentType?.id === selectedOption;

              if (hasCategory)
                return (
                  <EventSmallPreview
                    key={item.id}
                    skeleton={loading}
                    className="item"
                    handleHover={setHover}
                    image={item.thumbnail}
                    event={item}
                  />
                );
              return null;
            })}
          </ContentEvents>
        )}

        <p
          ref={loaderRef}
          style={{
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: '#fff',

            display:
              currentEvents?.length === mappedEvents?.length ? 'none' : '',
          }}
        >
          Carregando...
        </p>
      </ContainerEvents>
      {/* <Footer>
        <Banner />
      </Footer> */}
    </Container>
  );
};
export default MainPage;
