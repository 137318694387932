import { Avatar } from '@mui/material';
import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import bannerBeta from 'presentation/base/images/bannerBeta.png';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Button } from '../UI';
import {
  AdContainer,
  Banner,
  Container,
  LeftAdContent,
  LeftAdInfo,
  RightAdContent,
  Subtitle,
  Title,
} from './styles';

export interface iParams {
  id: string;
  roomId: string;
}

export interface ITemplate {
  id: number;
  short: string;
  name: string;
  descr: string;
  logo: FormData | string;
  banner: FormData | string;
  color: string;
  label: string;
  url: string;
}

type InfoEventProps = {
  template?: ITemplate;
};

const mockEvent: iEventItem = {
  id: 9999,
  created: new Date().toLocaleDateString(),
  updated: new Date().toLocaleDateString(),
  short: 'Short Demo',
  name: 'Demo',
  organization: 'Org Demo',
  external:
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  media:
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  descr: 'Descr Demo',
  keywords: ['keyword1', 'keyword2'],
  competitive: false,
  thumbnail: 'https://i.imgur.com/J5LVg76.png',
  duration: 90,
  invitationLink: 'https://www.google.com',
  joinPolicy: 'PUBLIC',
  listPolicy: 'PUBLIC',
  paymentPolicy: 'FREE',
  schedule: new Date().toISOString(),
  category: {
    id: 1,
    descr: 'Sport',
    name: 'Sport',
  },
  contentType: {
    id: 1,
    descr: 'Sport',
    name: 'Sport',
  },
  layout: {
    id: 1,
    name: 'Layout 1',
    descr: 'Layout 1',
    competitive: false,
    seats: 50,
  },
  mediaType: 'Test',
  rooms: 120,
  banner: 'https://i.imgur.com/J5LVg76.png',
  sponsor: {
    descr: 'Sponsor',
    id: 1,
    name: 'Sponsor',
    banner: 'https://i.imgur.com/J5LVg76.png',
    color: '#4507a1',
    label: 'Sponsor',
    label2: 'Sponsor',
    level: 'Sponsor',
    logo: 'https://i.imgur.com/J5LVg76.png',
    slogan: 'Sponsor',
    url: 'https://www.google.com',
  },
  subCategories: [
    {
      id: 1,
      descr: 'Sport',
      name: 'Sport',
      subCategory: 'Sport',
    },
    {
      id: 2,
      descr: 'Music',
      name: 'Music',
      subCategory: 'Music',
    },
  ],
};

export const InfoEvent: React.FC<InfoEventProps> = ({ template }) => {
  const { results: events } = useSelector((store: iStore) => store.event);
  const location = useLocation();

  const isDemo = location.pathname.includes('demo');

  const { id }: iParams = useParams();

  const selectedEvent: iEventItem | undefined = useMemo(() => {
    return isDemo ? mockEvent : events?.find(item => item.id === Number(id));
  }, [events, id, isDemo]);

  const openNewTab = useCallback((url?: string) => {
    if (!url) return;

    const withHttp = !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    window.open(withHttp, '_blank', 'noopener,noreferrer');
  }, []);

  const isTemplate = useMemo(() => {
    return template !== undefined;
  }, [template]);

  const mapTemplate = useMemo(() => {
    return {
      id: template?.id ?? 0,
      short: template?.short ?? '',
      name: template?.name ?? '',
      descr: template?.descr ?? '',
      color: template?.color ?? '',
      label: template?.label ?? '',
      url: template?.url ?? '',
      sponsor: {
        name: template?.name ?? '',
        url: template?.url ?? '',
        label: template?.label ?? '',
        color: template?.color ?? '',
        banner: String(template?.banner) ?? '',
        logo: String(template?.logo) ?? '',
      },
    };
  }, [template]);

  return (
    <Container>
      <Banner
        src={
          (isTemplate ? mapTemplate : selectedEvent)?.sponsor?.banner ??
          bannerBeta
        }
        alt={`Banner do patrocinador: ${
          (isTemplate ? mapTemplate : selectedEvent)?.sponsor?.name
        }`}
      />
      <AdContainer>
        <LeftAdContent>
          <Avatar
            src={(isTemplate ? mapTemplate : selectedEvent)?.sponsor?.logo}
            alt={`Logo do patrocinador: ${
              (isTemplate ? mapTemplate : selectedEvent)?.sponsor?.name
            }`}
            sx={{ bgcolor: '#3D3B3B', width: '5rem', height: '5rem' }}
          />
          <LeftAdInfo>
            <Title>
              {(isTemplate ? mapTemplate : selectedEvent)?.sponsor?.name}
            </Title>
            <Subtitle>
              {`Ad • ${
                (isTemplate ? mapTemplate : selectedEvent)?.sponsor?.url
              }`}
            </Subtitle>
          </LeftAdInfo>
        </LeftAdContent>
        <RightAdContent>
          <Button
            title={(isTemplate ? mapTemplate : selectedEvent)?.sponsor?.label}
            color={(isTemplate ? mapTemplate : selectedEvent)?.sponsor?.color}
            onClick={() =>
              openNewTab(
                (isTemplate ? mapTemplate : selectedEvent)?.sponsor?.url,
              )
            }
          />
        </RightAdContent>
      </AdContainer>
    </Container>
  );
};

export default InfoEvent;
