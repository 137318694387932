import {
  DashboardTypes,
  iActionGetDashboard,
  iActionGetDashboardGetFailed,
  iActionGetDashboardGetSuccess,
} from 'domain/interfaces/redux/dashboard/get';
import { GetDashboard } from 'domain/usecases/dashboard/redux';

export const getDashboardRequest = (
  params: GetDashboard.Params,
): iActionGetDashboard => ({
  type: DashboardTypes.GET,
  payload: params,
});

export const getDashboardSuccess = (
  params: GetDashboard.Model,
): iActionGetDashboardGetSuccess => ({
  type: DashboardTypes.GET_SUCCESS,
  payload: params,
});

export const getDashboardFailed = (): iActionGetDashboardGetFailed => ({
  type: DashboardTypes.GET_FAILED,
});
