import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeReduxGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import { makeReduxGetAllFriendship } from 'main/factories/usecases/friendship/GetAllFriendshipFactory';
import { makeReduxGetAllLayout } from 'main/factories/usecases/layout/GetAllLayoutFactory';
import Room from 'presentation/pages/Room';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { closeModal } from 'utils/modalFunctions';
import { animalsList } from 'data/repository/mockData/animals.json';
import ParticipantsProvider from 'presentation/contexts/Participants';
import { useQuery } from 'presentation/hooks/useQuery';
import { makeReduxActiveMessage } from '../../usecases/message/UpdateFactory';
import { makeReduxLogin } from '../../usecases/auth/LoginFactory';

export const RoomFactory: React.FC = () => {
  const { id, email } = useSelector((store: iStore) => store.auth.user);
  const history = useHistory();
  const location = useLocation();

  const embed = useMemo(() => location.pathname.includes('embed'), [location]);

  const query = useQuery();

  const login = query.get('login');
  const password = query.get('password');

  useEffect(() => {
    makeReduxGetAllEvent().getAll({
      limit: 9999,
    });

    if (id) makeReduxGetAllFriendship().getAll({ user: Number(id) });
  }, [id]);

  useEffect(() => {
    if (!email || email === '') {
      makeReduxGetAllLayout().getAll({});
      makeReduxGetAllEvent().getAll({
        limit: 9999,
      });
      makeReduxGetAllCategory().getAll({});
    }
  }, [email]);

  useEffect(() => {
    if (embed && login && password) {
      makeReduxLogin().login({
        login,
        password,
        guest: false,
      });
      return;
    }

    if (embed && (!email || email === '')) {
      const guestName =
        animalsList[Math.floor(Math.random() * animalsList.length)];

      makeReduxLogin().login({
        login: `${guestName} anônimo`,
        password: 'convidado',
        guest: true,
      });
    }

    if (!embed && (!email || email === '')) {
      // makeReduxActiveMessage().active({
      //   active: MessageOptions.loginModal,
      //   actionOk: () => closeModal(),
      //   actionCancel: () => {
      //     closeModal();
      //     history.push('/');
      //   },
      // });

      const guestName =
        animalsList[Math.floor(Math.random() * animalsList.length)];

      makeReduxLogin().login({
        login: `${guestName} anônimo`,
        password: 'convidado',
        guest: true,
      });
    }
  }, [embed, login, password]);

  return (
    <ParticipantsProvider>
      <Room embed={embed} />
    </ParticipantsProvider>
  );
};
