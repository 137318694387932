import React from 'react';
import Box from '@mui/material/Box';
import { VolumeUp } from '@material-ui/icons';
import { ArrowDownIcon } from 'presentation/base/icons';
import {
  MobileVolumeDivisor,
  MobileVolumeType,
  MobileSliderContainer,
  PublicVolumSelider,
  TransmissionVolumeSlider,
} from './styles/StyledVolumeSlider';

interface volumeSliderProps {
  eventVolume: number;
  publicVolume: number;
  handleEventVolumeChange: (value: number) => void;
  handlePublicVolumeChange: (value: number) => void;
  isDisplayed: boolean;
  onClose: () => void;
}

const VolumeSlider: React.FC<volumeSliderProps> = ({
  eventVolume,
  publicVolume,
  handleEventVolumeChange,
  handlePublicVolumeChange,
  isDisplayed,
  onClose,
}) => {
  return (
    <MobileSliderContainer isDisplayed={isDisplayed}>
      {isDisplayed && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <MobileVolumeType>
            <span>Volume da transmissão</span>
            <ArrowDownIcon
              width="1.3rem"
              height="1.3rem"
              style={{ marginRight: '13px', marginTop: '8px' }}
              onClick={onClose}
            />
          </MobileVolumeType>
          <MobileVolumeDivisor>
            <VolumeUp
              style={{ width: '25px', height: '25px', marginBottom: '7px' }}
            />
            <Box width="300px">
              <TransmissionVolumeSlider
                size="medium"
                value={eventVolume}
                onChange={(_, value) =>
                  handleEventVolumeChange(value as number)
                }
                aria-label="transmission"
                valueLabelDisplay="auto"
              />
            </Box>
          </MobileVolumeDivisor>
          <MobileVolumeType>
            <span>Volume da plateia</span>
          </MobileVolumeType>
          <MobileVolumeDivisor>
            <VolumeUp
              style={{ width: '25px', height: '25px', marginBottom: '7px' }}
            />
            <Box width="300px">
              <PublicVolumSelider
                size="medium"
                value={publicVolume}
                onChange={(_, value) =>
                  handlePublicVolumeChange(value as number)
                }
                aria-label="public"
                valueLabelDisplay="auto"
              />
            </Box>
          </MobileVolumeDivisor>
        </div>
      )}
    </MobileSliderContainer>
  );
};

export default VolumeSlider;
