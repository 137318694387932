/* eslint-disable react/react-in-jsx-scope */
import { useForm } from 'react-hook-form';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxLogin } from 'main/factories/usecases/auth/LoginFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { IconGuestUser } from 'presentation/base/icons';
import { Button, Input } from '../../UI';
import { ModalContainer } from '../styles';
import {
  BodyModal,
  ButtonContainer,
  Content,
  HeaderModal,
  IconHeaderModal,
  TitleHeaderModal,
} from './styles';
import { schemaGuestLogin } from './validator';

interface props {
  message: iMessage;
}

const GuestLogin: React.FC<props> = ({ message }) => {
  const { active: actualMessageActive, actionCancel } = message;
  const messageName = MessageOptions.guestLogin;

  const isActive = actualMessageActive === messageName;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: schemaGuestLogin,
    defaultValues: {
      guestName: '',
    },
  });

  const handleGuestLogin = (data: { guestName: string }) => {
    const { guestName } = data;

    makeReduxLogin().login({
      login: guestName,
      password: 'convidado',
      guest: true,
    });

    makeReduxActiveMessage().active({
      active: MessageOptions.none,
      actionCancel,
    });
  };

  return isActive ? (
    <ModalContainer>
      <Content>
        <HeaderModal>
          <IconHeaderModal>
            <IconGuestUser />
          </IconHeaderModal>
          <TitleHeaderModal>Login anônimo</TitleHeaderModal>
        </HeaderModal>
        <BodyModal onSubmit={handleSubmit(handleGuestLogin)}>
          <Input
            {...register('guestName')}
            id="guestName"
            label="Apelido"
            required
            error={Boolean(errors?.guestName)}
            message={errors?.guestName?.message}
          />
          <ButtonContainer>
            <Button
              type="submit"
              className="button-modal-guest-user button-ok"
              title="Entrar como convidado"
            />
            <Button
              className="button-modal-guest-user button-cancel"
              title="Voltar"
              colorstyle="black3"
              onClick={actionCancel}
            />
          </ButtonContainer>
        </BodyModal>
      </Content>
    </ModalContainer>
  ) : null;
};

export default GuestLogin;
