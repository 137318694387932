import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const schema = {
  guestName: yup
    .string()
    .required('O nome do convidado é obrigatório')
    .min(3, 'O nome do convidado deve ter no mínimo 3 caracteres'),
};

export const schemaGuestLogin = yupResolver(yup.object().shape(schema));
