/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { makeReduxGetDashboard } from 'main/factories/usecases/dashboard/GetDashboardFactory';
import { Container, PaginationButton } from './styles';

const PAGE_CACHE_TIMEOUT = 60000; // 1 minute;

const Pagination: React.FC = () => {
  const { currentPage, totalPages, pagesCache } = useSelector(
    (store: iStore) => store.dashboard,
  );

  const getDashboard = makeReduxGetDashboard();

  const handlePagination = (page: number) => {
    if (page === currentPage || page < 1) return;

    const hasPageCache = pagesCache[page];

    const isTimeToUpdate =
      !hasPageCache ||
      Date.now() - hasPageCache?.timestamp > PAGE_CACHE_TIMEOUT;

    if (isTimeToUpdate) {
      getDashboard.get({
        currentPage: page,
        query: {
          limit: 10,
          offset: (page - 1) * 10,
        },
      });
    } else {
      getDashboard.setCurrentPage(page);
    }
  };

  return (
    <Container>
      <PaginationButton
        onClick={() => handlePagination(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Anterior
      </PaginationButton>
      {Array.from(Array(totalPages).keys()).map(page => (
        <PaginationButton
          key={page}
          active={page + 1 === currentPage}
          onClick={() => handlePagination(page + 1)}
        >
          {page + 1}
        </PaginationButton>
      ))}
      <PaginationButton
        onClick={() => handlePagination(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Próxima
      </PaginationButton>
    </Container>
  );
};

export default Pagination;
