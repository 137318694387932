/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import {
  iAuth,
  iEvent,
  iEventItem,
  iMessage,
  iReactions,
  iSpotsInfo,
  iStore,
  iWrtcClient,
} from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { makeRemoteGetAllReaction } from 'main/factories/usecases/reaction/GetAllReactionFactory';
import { makeReduxUpdateWrtc } from 'main/factories/usecases/wrtcClient/UpdateFactory';
import { useSelector } from 'react-redux';
import {
  IconArrowAbove,
  IconArrowBelow,
  IconPersonGrey,
  IconUser,
} from 'presentation/base/icons';
import ActionsWindow from 'presentation/components/ActionsWindow';
import { MessageProps } from 'presentation/components/Chat/interfaces';
import InfoEvent from 'presentation/components/InfoEvent';
import LivePlataform from 'presentation/components/LivePlataform';
import { RandomProfileUser } from 'presentation/components/randomProfileUser';
import { TooltipComponent } from 'presentation/components/TooltipComponent';
import AudioProvider from 'presentation/contexts/AudioContext';
import { MessagesPublicChatContext } from 'presentation/contexts/MessagesPublicChatContext';
import RoomProvider from 'presentation/contexts/RoomContext';
import useBreakPoint from 'presentation/hooks/useBreakPoint';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { WRTCClient } from 'services/wrtccli/netfans-wrtc-wrapper.js';
import {
  convertFromWRTCRoomName,
  convertToWRTCRoomName,
  getSpotIndexFromWRTCRoomName,
  onJoinRoomFailed,
} from 'utils';
import { formatDateWithDuration } from 'utils/formattedDate';
import { getSpatialization } from 'utils/getSpatialization';
import { closeModal } from 'utils/modalFunctions';
import { VolumeProvider } from 'presentation/contexts/VolumeContext';
import { makeRemoteSendReaction } from 'main/factories/usecases/event/SendReactionFactory';

import mainStore from '../../../data/store';
import { updateSpotsInfo } from '../../../data/store/reducer/spotsInfo/actions/update';
import Header from '../../components/Header';
import { ConnectComponent } from './mapper/Mapper';
import {
  BackArrow,
  CategoryBar,
  CategoryTitle,
  ChatAndParticipants,
  Container,
  EventDateDetails,
  EventTitleDetails,
  GridLayout,
  InfoEventBanner,
  RoomHeader,
  StyledPaginationItem,
  StyledPaginationReaction,
} from './styles';

import { iAnimation, iGifs, iMutedUsers, SpotsInfo } from './interface';
import Layout from './layouts';

export interface ownProps {
  eventId: string;
  roomName: string;
  wrtc: iWrtcClient;
  userLog: iAuth['user'];
  wrtcInfo: iAuth['wrtcInfo'];
  event: iEvent;
  active: iMessage['active'];
  spotsInfo: iSpotsInfo;
}

export interface externalProps {
  embed?: boolean;
}

const Room: React.FC<ownProps & externalProps> = ({
  eventId,
  roomName,
  wrtc,
  userLog,
  wrtcInfo,
  event,
  active,
  spotsInfo,
  embed,
}) => {
  const [speakingPeerIds, setSpeakingPeerIds] = useState<any>({});
  const [rooms, setRooms] = useState<Array<any>>([]);
  const [floors, setFloors] = useState<Array<any>>([]);
  const [chats, setChats] = useState<any[]>([]);
  const [newChatMessage, setNewChatMessage] = useState<any>(null);
  const [sendChatToProvider, setSendChatToProvider] = useState<
    MessageProps | undefined
  >(undefined);
  const [reactionButtons, setReactionButtons] = useState<Array<iReactions>>([]);
  const [reactionGif, setReactionGif] = useState<Array<iGifs>>([]);
  const [displayedLabels, setDisplayedLabels] = useState<number[]>([]);
  const [displayedUsers, setDisplayedUsers] = useState<number[]>([]);
  const [activeUsers, setActiveUsers] = useState<Array<SpotsInfo>>([]);
  const [isLogged, setLoggedState] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [updateInsideRoom, setUpdateInsideRoom] = useState(false);
  const [isVirtualRoomOpen, setIsVirtualRoomOpen] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState<iAnimation>(
    {} as iAnimation,
  );
  const [selectedEvent, setSelectedEvent] = useState<iEventItem>(
    {} as iEventItem,
  );
  const [isMicMuted, setIsMicMuted] = useState(true);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [openReactions, setOpenReactions] = useState(false);
  const [openVolumeBox, setOpenVolumeBox] = useState(false);
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [hasMutedUsers, setHasMutedUsers] = useState<iMutedUsers>(
    {} as iMutedUsers,
  );
  const [eventVolume, setEventVolume] = useState(100);
  const [publicVolume, setPublicVolume] = useState(100);

  const playerRef = useRef<any>(null);

  const history = useHistory();
  const location = useLocation();
  const { isMobile, orientation } = useWindowDimensions();

  const { accessToken } = useSelector((store: iStore) => store.auth);
  const authUser = useSelector((store: iStore) => store.auth.user);

  const attachSinkId = (element: any, deviceId: string) => {
    if (typeof element.sinkId !== 'undefined') {
      element
        .setSinkId(deviceId)
        .then(() => {
          // nop
        })
        .catch((error: any) => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
        });
    } else if (element) {
      console.warn('Browser does not support output device selection.');
    }
  };

  const updateReaction = useCallback(
    (peerId: number, animationUrl: string, audioUrl: string) => {
      setTriggerAnimation({
        peerId,
        animationUrl,
        audioUrl,
      });
    },
    [],
  );

  const muteMic = useCallback(
    (state: boolean) => {
      if (wrtc.setLocalInputDeviceMuteState)
        wrtc.setLocalInputDeviceMuteState(!state);
    },
    [wrtc],
  );

  const handleChangeRoomModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.changeRoomModal,
      // actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
      componentProps: {
        changeRoom: { layout: selectedEvent?.layout?.id },
      },
    });
  }, [selectedEvent]);

  const handleInviteModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.inviteModal,
      // actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  }, []);

  const handleReactionClick = useCallback(
    (name: string, reactionId?: number) => {
      const reaction = reactionButtons.find(item => item.name.includes(name));

      const gifUrl = reaction?.reaction;
      const audioUrl = reaction?.audio;

      if (gifUrl && audioUrl && wrtcInfo?.peerId) {
        updateReaction(wrtcInfo.peerId, gifUrl, audioUrl);

        if (wrtc.sendChatMessage) {
          makeRemoteSendReaction()
            .send({
              eventId: Number(eventId),
              peerId: wrtcInfo.peerId.toString(),
              body: {
                reaction: reactionId ?? 28,
                room: roomName,
                timestamp: new Date().toISOString(),
                user: Number(authUser.id),
              },
            })
            .catch(err => {
              console.log('reaction err: ', err);
            });

          wrtc.sendChatMessage(
            {
              messageType: 'reaction',
              userId: wrtcInfo.peerId,
              animationUrl: gifUrl,
              audioUrl,
            },
            -1,
          );
        }
      }
    },
    [reactionGif, updateReaction, wrtc, wrtcInfo?.peerId],
  );

  const handleEventVolumeChange = useCallback((value: number) => {
    setEventVolume(value);

    console.log('playerRef?.current: ', playerRef?.current);

    if (playerRef?.current?.setVolume) {
      console.log('updating playerRef volume: ', value);

      playerRef.current.setVolume(value);
    }
  }, []);

  const handlePublicVolumeChange = useCallback((value: number) => {
    const audio = document.getElementById(`audio`) as HTMLAudioElement;

    console.log('audio: ', audio);

    setPublicVolume(value);

    if (audio) {
      console.log('updating volume: ', value / 100);

      audio.volume = value / 100;
    }
  }, []);

  const handleGoBack = () => {
    if (wrtc.leaveRoom) wrtc.leaveRoom();
    if (wrtc.leaveFloor) wrtc.leaveFloor();
    if (wrtc.disconnect) wrtc.disconnect();

    window.location.pathname = `/rooms/${eventId}`;
  };

  const getRoomsSize = (layoutId: number) => {
    switch (layoutId) {
      case 1:
        return { maxLetter: 4, maxNumber: 6 };
      case 2:
        return { maxLetter: 4, maxNumber: 20 };
      default:
        return { maxLetter: 10, maxNumber: 22 };
    }
  };

  const renderHexagons = async () => {
    const array = [];
    let flag = true;

    const { maxLetter, maxNumber } = getRoomsSize(selectedEvent?.layout?.id);

    for (let letter = 0; letter < maxLetter; letter += 1) {
      for (let number = 0; number < maxNumber; number += 1) {
        const object = {
          // eslint-disable-next-line prefer-template
          id: 10 * letter + number,
          peerId: -1,
          peerName: '',
          x: getSpatialization(selectedEvent?.layout?.id, number, letter),
          y: letter,
          // label: `${String.fromCharCode(65 + letter)}${number + 1}`,
          label: '',
          state: 'normal',
          type: 'normal',
          disabled: false,
          actualUser: false,
          muted: false,
        };

        await Promise.all(
          // eslint-disable-next-line array-callback-return
          activeUsers.map(item => {
            if (
              !item.roomName ||
              (item.roomName &&
                convertFromWRTCRoomName(item.roomName) === roomName)
            ) {
              if (
                item.spotCoordJ ===
                  getSpatialization(
                    selectedEvent?.layout?.id,
                    number,
                    letter,
                  ) &&
                item.spotCoordI === letter
              ) {
                const name = item.peerName.split(' ');

                object.label = `${name[0]
                  .substring(0, 1)
                  .toUpperCase()}${name[1].substring(0, 1).toUpperCase()}`;

                object.peerId = item.peerId as number;
                object.peerName = item.peerName;

                object.muted =
                  (hasMutedUsers[object.peerId] || item.muted) ?? false;

                if (wrtc.getRemotePeerInputEdgeGain && !object.muted) {
                  object.muted =
                    wrtc.getRemotePeerInputEdgeGain(item.peerId) === 0 ||
                    hasMutedUsers[object.peerId];
                }

                if (wrtcInfo?.peerId === item.peerId) {
                  flag = false;
                  object.actualUser = true;
                  object.muted = !isMicMuted;
                }
              }
            }
          }),
        );

        array.push(object);
      }
    }

    setRooms(array);
  };

  const localMute = useCallback(
    async (data: any) => {
      if (wrtc.connect) {
        const updatedArray: Array<any> = [];

        await Promise.all(
          // eslint-disable-next-line array-callback-return
          rooms.map(hexagon => {
            const object = {
              ...hexagon,
            };
            const itSelfMuted = hasMutedUsers[object.peerId] ?? false;
            if (hexagon.x === data.x && hexagon.y === data.y) {
              object.muted = !hexagon.muted || itSelfMuted;
            }

            updatedArray.push(object);
          }),
        );

        setRooms(updatedArray);
      } else {
        toast('Servidor ainda não iniciado, tente novamente mais tarde', {
          type: 'error',
        });
      }
    },
    [rooms, wrtc.connect],
  );

  const muteAll = useCallback(
    async (state: boolean) => {
      if (wrtc.connect) {
        const updatedArray: Array<any> = [];

        await Promise.all(
          // eslint-disable-next-line array-callback-return
          rooms.map(hexagon => {
            const object = {
              ...hexagon,
            };

            if (hexagon.peerId !== -1 && !hexagon.actualUser) {
              object.muted = state;

              if (wrtc.setRemotePeerInputEdgeGain) {
                !state
                  ? wrtc.setRemotePeerInputEdgeGain(hexagon.peerId, 1)
                  : wrtc.setRemotePeerInputEdgeGain(hexagon.peerId, 0);
              }
            }

            updatedArray.push(object);
          }),
        );

        setRooms(updatedArray);
      } else {
        toast('Servidor ainda não iniciado, tente novamente mais tarde', {
          type: 'error',
        });
      }
    },
    [rooms, wrtc],
  );

  const updatePosition = useCallback(
    async (data: any) => {
      if (wrtc.connect) {
        const updatedArray: Array<any> = [];

        await Promise.all(
          // eslint-disable-next-line array-callback-return
          rooms.map(hexagon => {
            const object = {
              ...hexagon,
            };

            if (wrtc.getRemotePeerInputEdgeGain) {
              object.muted =
                wrtc.getRemotePeerInputEdgeGain(object.peerId) === 0;
            }

            if (hexagon.actualUser === true) {
              object.label = '';
              object.actualUser = false;
            }

            if (hexagon.x === data.x && hexagon.y === data.y) {
              object.label = `${String(userLog?.firstName)
                .substring(0, 1)
                .toUpperCase()}${String(userLog?.lastName)
                .substring(0, 1)
                .toUpperCase()}`;

              object.actualUser = true;
            }

            updatedArray.push(object);
          }),
        );

        setRooms(updatedArray);
      } else {
        toast('Servidor ainda não iniciado, tente novamente mais tarde', {
          type: 'error',
        });
      }
    },
    [rooms, userLog?.firstName, userLog?.lastName, wrtc],
  );

  const onHexagonClick = useCallback(
    (hexagon: any) => {
      if (hexagon.peerId !== -1) {
        if (!hexagon.actualUser) {
          // const object = {
          //   x: hexagon.x,
          //   y: hexagon.y,
          // };
          // localMute(object);
          // if (wrtc.setRemotePeerInputEdgeGain) {
          //   hexagon.muted
          //     ? wrtc.setRemotePeerInputEdgeGain(hexagon.peerId, 1)
          //     : wrtc.setRemotePeerInputEdgeGain(hexagon.peerId, 0);
          // }
          // toast(
          //   hexagon.muted
          //     ? 'Usuário não mais silenciado!'
          //     : 'Usuário silenciado!',
          //   { type: hexagon.muted ? 'info' : 'error' },
          // );
        }
      } else {
        if (wrtc.tryChangeInsideRoomSpot) {
          // Linha x Coluna
          wrtc.tryChangeInsideRoomSpot(hexagon.x, hexagon.y);
        }
        // Atualizar posição de um usuário na sala e atualizar tudo pros outros
        updatePosition(hexagon);
      }
    },
    [updatePosition, wrtc],
  );

  const onRequestInsideRoomPeersInfosResponse = (updatedSpotsInfo: any) => {
    mainStore.dispatch(updateSpotsInfo(updatedSpotsInfo));
    setActiveUsers(updatedSpotsInfo);
  };

  const onInsideRoomPeersSpotChanged = (
    j: number,
    i: number,
    pId: number,
    pName: string,
  ) => {
    const formattedArray = [];

    const curSpotsInfo = mainStore.getState().spotsInfo.results;
    for (let k = 0; k < curSpotsInfo.length; k += 1) {
      if (
        curSpotsInfo[k].spotCoordI !== i ||
        curSpotsInfo[k].spotCoordJ !== j
      ) {
        if (curSpotsInfo[k].peerId !== pId) {
          const obj = {
            peerId: curSpotsInfo[k].peerId,
            peerName: curSpotsInfo[k].peerName,
            roomName: '',
            spotCoordI: curSpotsInfo[k].spotCoordI,
            spotCoordJ: curSpotsInfo[k].spotCoordJ,
            muted: curSpotsInfo[k].muted,
          };
          formattedArray.push(obj);
        }
      }
    }
    if (pId !== -1) {
      formattedArray.push({
        peerId: pId,
        peerName: pName,
        roomName: '',
        spotCoordI: i,
        spotCoordJ: j,
        muted: hasMutedUsers[pId] ?? false,
      });
    }
    mainStore.dispatch(updateSpotsInfo(formattedArray));
    setActiveUsers(formattedArray);
  };

  const onInsideRoomPeerMuteStateChanged = (
    newMuteState: boolean,
    peerId: number,
  ) => {
    setHasMutedUsers((prevState: iMutedUsers) => ({
      ...prevState,
      [peerId]: newMuteState,
    }));
  };

  const onRemoteStreamAvailable = (stream: any, edgeId: number) => {
    let audio: any;
    const container = document.getElementById('audioContainer');
    let device: any;
    let deviceJsonStr: string | null;

    if (container != null) {
      audio = document.getElementById(`edge_${edgeId}`);
      if (audio == null) {
        audio = document.createElement('audio');
        audio.controls = false;
        audio.muted = false;
        audio.autoplay = true;
        audio.volume = 1.0; // 0.0 ~ 1.0 TODO: set volume from query parameter
        audio.id = `edge_${edgeId}`;
        container.appendChild(audio);
      }
      audio.srcObject = stream;
      deviceJsonStr = localStorage.getItem('@netfans/outputDevice');
      if (deviceJsonStr) {
        try {
          device = JSON.parse(deviceJsonStr);
        } catch (e) {
          console.warn('Unable to parse device id from local storage');
        }
        if (typeof device?.value !== 'undefined') {
          attachSinkId(audio, device.value);
        }
      }
    }
  };

  const onRemoteStreamRemoved = (edgeId: number) => {
    const container = document.getElementById('audioContainer');
    if (container != null) {
      const audio = document.getElementById(`edge_${edgeId}`);
      if (audio != null) {
        container.removeChild(audio);
      }
    }
  };

  const onMixedAudioStreamAvailable = (stream: any) => {
    let audio: any;
    const container = document.getElementById('audioContainer');
    if (container != null) {
      audio = document.getElementById(`mixed_audio`);
      if (audio == null) {
        audio = document.createElement('audio');
        audio.controls = false;
        audio.muted = false;
        audio.autoplay = true;
        audio.volume = 1.0; // 0.0 ~ 1.0 TODO: set volume from query parameter
        audio.id = `mixed_audio`;
        container.appendChild(audio);
      }
      audio.srcObject = stream;
    }
  };

  const onMixedAudioStreamRemoved = () => {
    const container = document.getElementById('audioContainer');
    if (container != null) {
      const audio = document.getElementById(`mixed_audio`);
      if (audio != null) {
        container.removeChild(audio);
      }
    }
  };

  const openChat = useCallback(
    async (peerId: number, isPublic = false, name?: string) => {
      const chat = chats.find(item => {
        if (isPublic) {
          return item?.isPublic && item?.id === peerId;
        }
        return (
          !item.isPublic &&
          (peerId === item?.from?.id || peerId === item?.to?.id)
        );
      });

      if (chat) {
        const newChats = await Promise.all(
          chats.map(item => {
            if (isPublic) {
              return item?.isPublic && item?.id === peerId
                ? { ...item, isDisplayed: !item?.isDisplayed }
                : item;
            }
            return !item.isPublic &&
              (peerId === item?.from?.id || peerId === item?.to?.id)
              ? { ...item, isDisplayed: !item?.isDisplayed }
              : item;
          }),
        );

        setChats(newChats);
      } else {
        const newChat = {
          // TODO: Alterar lógica do PeerID para evitar chats com mesmo ID
          id: peerId,
          messages: [],
          isPublic,
          isOpen: true,
          isDisplayed: true,
          to: {
            id: isPublic ? -1 : peerId,
            name,
            avatar: '',
            status: 'online',
          },
          from: {
            id: wrtcInfo?.peerId,
            name: userLog.fullName,
            avatar: '',
            status: 'online',
          },
        };

        setChats([...chats, newChat]);
      }
    },
    [chats, wrtcInfo?.peerId, userLog?.fullName],
  );

  const receiveMessage = (message: MessageProps) => {
    if (message && message.messageType === 'chat') setNewChatMessage(message);
    if (message && message.messageType === 'reaction')
      updateReaction(
        message.userId,
        String(message.animationUrl),
        String(message.audioUrl),
      );
  };

  const onAudioStreamLevelChanged = (newLevel: number, peer: number) => {
    // console.debug(`Peer[${peer}] new audio level: `, newLevel);
    // Threshold para ativar indicação visual
    if (newLevel > 0.1) {
      if (setSpeakingPeerIds)
        setSpeakingPeerIds((prevState: any) => ({
          ...prevState,
          [peer]: true,
        }));
    } else if (setSpeakingPeerIds)
      setSpeakingPeerIds((prevState: any) => ({
        ...prevState,
        [peer]: false,
      }));
  };

  const handleMuteMicrophone = useCallback(() => {
    setIsMicMuted(!isMicMuted);
    muteMic(!isMicMuted);
    if (!isAudioMuted) {
      muteAll(isMicMuted);
      setIsAudioMuted(!isMicMuted);
    }
  }, [isMicMuted, muteMic, muteAll, isAudioMuted]);

  // const handleMuteAudio = useCallback(() => {
  //   muteAll(isAudioMuted);
  //   setIsAudioMuted(!isAudioMuted);
  //   if (isMicMuted) {
  //     setIsMicMuted(!isAudioMuted);
  //     muteMic(!isAudioMuted);
  //   }
  // }, [isAudioMuted, muteMic, muteAll, isMicMuted]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      // moveOnRoom(
      //   e,
      //   rooms,
      //   selectedEvent?.layout?.id,
      //   onHexagonClick,
      //   wrtcInfo?.peerId,
      // );
    },
    [onHexagonClick, rooms, selectedEvent?.layout?.id, wrtcInfo?.peerId],
  );

  const renderPaginationItems = useMemo(() => {
    let renderReactions =
      reactionButtons?.map(item => {
        if (!displayedLabels.includes(item.id) || !item.enabled) return null;

        return (
          <TooltipComponent title={item.name} key={item.id} placement="right">
            <StyledPaginationItem key={item.id}>
              <StyledPaginationReaction
                onClick={() => handleReactionClick(item.name, item.id)}
                src={item.url}
                alt={item.name}
              />
            </StyledPaginationItem>
          </TooltipComponent>
        );
      }) ?? [];
    if (renderReactions?.length < 4) {
      renderReactions = [
        ...renderReactions,
        ...Array(4 - renderReactions.length).fill(
          <StyledPaginationItem>
            <IconUser />
          </StyledPaginationItem>,
        ),
      ];
    }

    return renderReactions;
  }, [reactionButtons, displayedLabels, handleReactionClick]);

  const getCategoryName = useCallback(():
    | 'Música'
    | 'Esportes'
    | 'Entretenimento' => {
    if (selectedEvent?.contentType?.name === 'music') return 'Música';
    if (selectedEvent?.contentType?.name === 'sports') return 'Esportes';
    if (selectedEvent?.contentType?.name === 'entertainment')
      return 'Entretenimento';

    return 'Música';
  }, [selectedEvent]);

  const breakpoint = useBreakPoint();

  const rangeRender = useMemo(() => {
    switch (breakpoint) {
      case 'sm':
        return 0;
      case 'md':
        return 3;
      case 'lg':
        return 4;
      case 'xl':
        return 5;
      case 'xxl':
        return 6;
      default:
        return 8;
    }
  }, [breakpoint]);

  const publicChat = useMemo(() => {
    if (!chats) return null;
    return chats.find(item => item.isPublic);
  }, [chats, openChat, userLog.fullName]);

  const renderUsersItems = useMemo(() => {
    let renderUsers =
      spotsInfo?.results?.map(item => {
        if (!displayedUsers.includes(Number(item.peerId))) return null;
        return (
          <TooltipComponent title={item.peerName.split(':')[0]} placement="top">
            <StyledPaginationItem
              className="item"
              key={item.peerId}
              style={{ position: 'relative' }}
              onClick={() => {
                if (item.peerId !== wrtcInfo?.peerId)
                  openChat(item.peerId, false, item.peerName);
              }}
            >
              <RandomProfileUser peerId={item.peerId} size="5rem" />
            </StyledPaginationItem>
          </TooltipComponent>
        );
      }) ?? [];

    if (renderUsers?.length < rangeRender) {
      renderUsers = [
        ...renderUsers,
        ...Array(rangeRender - renderUsers.length).fill(
          <StyledPaginationItem>
            <IconUser width="5rem" height="5rem" />
          </StyledPaginationItem>,
        ),
      ];
    }

    return renderUsers;
  }, [
    spotsInfo?.results,
    displayedUsers,
    wrtcInfo?.peerId,
    openChat,
    rangeRender,
  ]);

  const displayRobot = useMemo(() => {
    return selectedEvent?.name?.includes('X2R4');
  }, [selectedEvent?.name]);

  useEffect(() => {
    const client = new WRTCClient(window.config.clientConfig);

    client.onremotestreamavailable = onRemoteStreamAvailable;
    client.onremotestreamremoved = onRemoteStreamRemoved;
    client.oninsideroompeersspotchanged = onInsideRoomPeersSpotChanged;
    client.oninsideroompeermutestatechanged = onInsideRoomPeerMuteStateChanged;
    client.onaudiostreamlevelchanged = onAudioStreamLevelChanged;
    client.onrequestinsideroompeersinfosresponse =
      onRequestInsideRoomPeersInfosResponse;
    client.onchatmessage = receiveMessage;

    const onRequestAllFloorsInfosResponse = (data: any) => {
      if (data?.floors) setFloors(data?.floors);
    };

    // client.setReverbImpulseResponse(
    //   'https://minio.v4h.cloud/public/EmptyApartmentBedroom.m4a',
    // );

    const loginSuccess = () => {
      const peerId = client.getLocalPeersId();
      if (peerId) makeReduxUpdateWRTCInfo().update({ peerId });

      setUpdateInsideRoom(true);
    };

    const loginFailed = (cause?: any) => {
      toast(
        'Não foi possível estabelecer comunicação com o servidor, tente novamente mais tarde',
        { type: 'error' },
      );
      client.disconnect();
    };

    client.onconnect = (name: string) => {
      client.loginPeer(name, accessToken, loginSuccess, loginFailed);
    };

    // Se a página for recarregada ou acessada via link
    if (
      !isLogged &&
      wrtc.connect &&
      updateInsideRoom &&
      selectedEvent?.name !== ''
    ) {
      if (wrtc.requestAllFloorsInfos) {
        wrtc.requestAllFloorsInfos(0, onRequestAllFloorsInfosResponse);
      }
    }

    if (!wrtc.onaudiostreamlevelchanged) {
      makeReduxUpdateWRTCInfo().update({
        peerId: undefined,
        insideRoom: false,
      });
      makeReduxUpdateWrtc().update(client);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInsideRoom, selectedEvent]);

  useEffect(() => {
    setActiveUsers([]);
  }, [location.pathname]);

  // Atualizar usuários ativos quando houver mudanças no spotsInfo
  useEffect(() => {
    const visibleUsers: Array<number> = [];
    spotsInfo?.results?.forEach((item, index) => {
      if (index < 4) visibleUsers.push(Number(item.peerId));

      return null;
    });
    setDisplayedUsers(visibleUsers);
    // if (spotsInfo) setActiveUsers(spotsInfo.results);
  }, [spotsInfo]);

  // Atualizar usuários hexágonos quando houver mudanças nos usuários da sala
  useEffect(() => {
    renderHexagons();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUsers, hasMutedUsers, isMicMuted]);

  useEffect(() => {
    if (!wrtcInfo?.insideRoom) {
      makeReduxUpdateWRTCInfo().update({ insideRoom: true });
    }

    renderHexagons();
  }, [wrtcInfo?.insideRoom]);

  useEffect(() => {
    const notConnectedToWRTC =
      (!wrtcInfo?.peerId || wrtcInfo?.peerId === -1) && userLog?.email;

    if (!wrtc.connect && !connecting) {
      // Se o usuário estiver autenticado e não houver WRTC, instanciar WRTC e conectar
      const client = new WRTCClient(window.config.clientConfig);

      client.onrequestinsideroompeersinfosresponse =
        onRequestInsideRoomPeersInfosResponse;
      client.onremotestreamavailable = onRemoteStreamAvailable;
      client.onremotestreamremoved = onRemoteStreamRemoved;
      client.oninsideroompeersspotchanged = onInsideRoomPeersSpotChanged;
      client.oninsideroompeermutestatechanged =
        onInsideRoomPeerMuteStateChanged;
      client.onaudiostreamlevelchanged = onAudioStreamLevelChanged;
      client.onchatmessage = receiveMessage;

      // client.setReverbImpulseResponse(
      //   'https://minio.v4h.cloud/public/EmptyApartmentBedroom.m4a',
      // );

      const loginSuccess = () => {
        const peerId = client.getLocalPeersId();
        if (peerId) makeReduxUpdateWRTCInfo().update({ peerId });

        setUpdateInsideRoom(true);
      };

      const loginFailed = (cause?: any) => {
        toast(
          'Não foi possível estabelecer comunicação com o servidor, tente novamente mais tarde',
          { type: 'error' },
        );
        client.disconnect();
      };

      client.onconnect = (name: string) => {
        setConnecting(true);
        client.loginPeer(name, accessToken, loginSuccess, loginFailed);
      };

      if (notConnectedToWRTC) {
        try {
          client.connect(
            `${userLog.firstName} ${userLog.lastName}:${userLog.id}`,
          );
          makeReduxUpdateWrtc().update(client);
        } catch (e) {
          console.log(e);
        }
      } else {
        makeReduxUpdateWrtc().update(client);
      }
    }

    if (wrtc.connect && notConnectedToWRTC) {
      try {
        wrtc.connect(`${userLog.firstName} ${userLog.lastName}:${userLog.id}`);
      } catch (e) {
        console.log(e);
      }
    }

    if (active === 'none' && userLog.email) {
      makeReduxUpdateWRTCInfo().update({ insideRoom: true });
      renderHexagons();
    } else {
      renderHexagons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLog, location.pathname, wrtc.connect, wrtcInfo?.peerId, history]);

  useEffect(() => {
    event?.results?.forEach((item: iEventItem) => {
      if (item.id === Number(eventId)) setSelectedEvent(item);
    });
  }, [event, eventId]);

  const { messages } = useContext(MessagesPublicChatContext);

  useEffect(() => {
    const chatPublic = chats?.filter(chat => chat.isPublic);
    if (wrtcInfo?.peerId && !chatPublic?.length) {
      openChat(-1, true, userLog?.fullName);
      const newChat = {
        id: -1,
        messages: messages ?? [],
        isPublic: true,
        isOpen: false,
        isDisplayed: false,
        to: {
          id: -1,
          name: '',
          avatar: '',
          status: 'online',
        },
        from: {
          id: wrtcInfo?.peerId,
          name: userLog?.fullName,
          avatar: '',
          status: 'online',
        },
      };

      setChats([...chats, newChat]);
    }
  }, [chats, messages, openChat, userLog?.fullName, wrtcInfo]);

  useEffect(() => {
    if (!newChatMessage) return;

    const chat = chats.find(item => {
      if (newChatMessage?.chat?.isPublic) {
        return item.isPublic && item?.id === newChatMessage?.chat?.id;
      }

      return (
        !item.isPublic &&
        (newChatMessage?.chat?.from?.id === item?.from?.id ||
          newChatMessage?.chat?.from?.id === item?.to?.id)
      );
    });

    if (chat) {
      const newChats = chats.map(item => {
        if (newChatMessage?.chat?.isPublic) {
          return item.isPublic && item?.id === newChatMessage?.chat?.id
            ? { ...item, isDisplayed: false }
            : item;
        }
        return !item.isPublic &&
          (newChatMessage?.chat?.from?.id === item?.from?.id ||
            newChatMessage?.chat?.from?.id === item?.to?.id)
          ? { ...item, isDisplayed: true }
          : item;
      });

      setChats(newChats);
    } else {
      const newChat = {
        id: newChatMessage.chat?.isPublic ? -1 : newChatMessage.chat?.to?.id,
        messages: newChatMessage.chat?.messages,
        isPublic: newChatMessage.chat?.isPublic,
        isOpen: newChatMessage.chat?.isOpen,
        isDisplayed: newChatMessage.chat?.isDisplayed,
        to: {
          id: newChatMessage.chat?.from?.id,
          name: newChatMessage.chat?.from?.name,
          avatar: '',
          status: 'online',
        },
        from: {
          id: newChatMessage.chat?.isPublic
            ? wrtcInfo?.peerId
            : newChatMessage.chat?.to?.id,
          name: newChatMessage.chat?.isPublic
            ? userLog.fullName
            : newChatMessage.chat?.to?.name,
          avatar: '',
          status: 'online',
        },
      };

      setChats([...chats, newChat]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChatMessage]);

  useEffect(() => {
    const onRequestAllFloorsInfosResponse = (data: any) => {
      if (data?.floors) setFloors(data?.floors);
    };

    if (wrtc.disconnect) wrtc.disconnect();
    if (wrtc.requestAllFloorsInfos)
      wrtc.requestAllFloorsInfos(0, onRequestAllFloorsInfosResponse);

    // check if call is necessary
    /* makeReduxGetAllEvent().getAll({
      limit: 9999,
    }); */

    makeRemoteGetAllReaction()
      .getAll({
        limit: 9999,
      })
      .then(res => {
        const reaction: iReactions[] = [];
        const gifs: iGifs[] = [];
        const displayed: number[] = [];

        res.records.forEach((item, index) => {
          if (item.type === 'GIF')
            gifs.push({
              name: item.name,
              animationUrl: item.url,
              audioUrl: item.audio,
            });
          if (item.type === 'REACTION' && item.enabled) {
            if (displayed.length < 4) displayed.push(item.id);

            reaction.push(item);
          }

          if (item.reaction) reaction.push(item);
        });

        setReactionButtons(reaction);
        setReactionGif(gifs);
        setDisplayedLabels(displayed);
      })
      .catch((err: any) => {
        console.log('Erro ao buscar reações', err);
      });
  }, []);

  useEffect(() => {
    if (floors.length && roomName && wrtc.joinFloor) {
      const onJoinRoomSuccess = () => {
        console.debug('Join room success');
        const query = new URLSearchParams(location.search);

        if (roomName) {
          history.push({
            pathname: embed
              ? `/embed/rooms/${eventId}/room/${roomName}${
                  query.values.length ? `?${query}` : ''
                }`
              : `/rooms/${eventId}/room/${roomName}`,
          });
        }
      };

      const WRTCRoomName = convertToWRTCRoomName(roomName);
      const spotIndex = getSpotIndexFromWRTCRoomName(WRTCRoomName);

      const found = floors.find(
        (item: any) => item?.name === `floor_${selectedEvent?.id}`,
      );

      let x = 0;
      let y = 0;

      if (selectedEvent?.layout?.id === 1) {
        x = 4;
        y = 6;
      }

      if (selectedEvent?.layout?.id === 2) {
        x = 4;
        y = 20;
      }

      if (selectedEvent?.layout?.id === 3 || selectedEvent?.layout?.id === 4) {
        x = 10;
        y = 22;
      }

      if (!found && wrtc.createFloor)
        wrtc.createFloor(`floor_${selectedEvent?.id}`, 0, 10, 15); // TODO: Remove mock when receiving room space from server

      wrtc.joinFloor(
        `floor_${selectedEvent?.id}`,
        0,
        () => {
          console.log('Deu bom');

          if (wrtc.setVirtualRoomFrameSize) wrtc.setVirtualRoomFrameSize(y, x);

          if (wrtc.joinRoom) {
            console.log(WRTCRoomName, spotIndex);
            wrtc.joinRoom(
              WRTCRoomName,
              spotIndex.toString(),
              onJoinRoomSuccess,
              onJoinRoomFailed,
            );

            setLoggedState(true);
          }
        },
        () => console.log('Deu ruim'),
      );
    }
  }, [floors]);

  useEffect(() => {
    if (!newChatMessage) return;

    if (newChatMessage.chat?.isPublic) {
      setSendChatToProvider({ ...newChatMessage, userId: wrtcInfo?.peerId });
    } else {
      setSendChatToProvider(newChatMessage);
    }

    setNewChatMessage(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats, wrtcInfo?.peerId]);

  const handleShowDescription = useCallback(() => {
    setShowDescription(!showDescription);
  }, [showDescription]);

  return (
    <RoomProvider rooms={rooms} setRooms={setRooms}>
      <AudioProvider
        remoteMute={localMute}
        localMute={handleMuteMicrophone}
        isMicMuted={isMicMuted}
        hasMutedUsers={hasMutedUsers}
      >
        <VolumeProvider playerRef={playerRef}>
          {/* <ModalBeta /> */}
          <Container embed={embed} onKeyUp={handleKeyPress} tabIndex={0}>
            {!embed && <Header />}
            {!embed && (
              <RoomHeader>
                <BackArrow onClick={handleGoBack} />
                <CategoryBar type={getCategoryName()} />
                <CategoryTitle>
                  {`${selectedEvent?.name} - ${
                    displayRobot ? 'Torcida' : 'Sala'
                  } ${roomName}`}
                </CategoryTitle>
              </RoomHeader>
            )}
            {!embed && !isMobile && (
              <GridLayout showDescription={showDescription}>
                <LivePlataform
                  showDescription={showDescription}
                  selectedEvent={selectedEvent}
                  handleReactionClick={handleReactionClick}
                  handleMuteMicrophone={handleMuteMicrophone}
                  isMicMuted={isMicMuted}
                  openReactions={openReactions}
                  setOpenReactions={setOpenReactions}
                  reactionButtons={reactionButtons}
                  eventVolume={eventVolume}
                  publicVolume={publicVolume}
                  handleEventVolumeChange={handleEventVolumeChange}
                  handlePublicVolumeChange={handlePublicVolumeChange}
                  playerRef={playerRef}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    >
                      <EventTitleDetails>
                        {selectedEvent?.name}
                      </EventTitleDetails>
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          cursor: 'pointer',
                          marginLeft: 8,
                        }}
                        onClick={handleShowDescription}
                      >
                        {showDescription ? (
                          <IconArrowAbove />
                        ) : (
                          <IconArrowBelow />
                        )}
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 16 }}
                    >
                      <EventDateDetails>
                        {formatDateWithDuration(
                          selectedEvent.schedule,
                          selectedEvent.duration,
                        )}
                      </EventDateDetails>
                      <IconPersonGrey />
                    </div>
                  </div>
                </LivePlataform>

                {displayRobot ? (
                  <Layout
                    handleClick={onHexagonClick}
                    rooms={rooms}
                    speakingPeerIds={speakingPeerIds}
                    triggerAnimation={triggerAnimation}
                    setTriggerAnimation={setTriggerAnimation}
                    selectedEvent={selectedEvent}
                    layout={selectedEvent?.layout?.id}
                    reactionsButtons={reactionButtons}
                    displayedLabelsByMap={displayedLabels}
                    spotsInfo={spotsInfo}
                    displayedUsersByMap={displayedUsers}
                    roomName={roomName}
                    handleChangeRoomModal={handleChangeRoomModal}
                    isRoomsPage
                    openChat={openChat}
                    isMicMuted={isMicMuted}
                    openReactions={openReactions}
                    setOpenReactions={setOpenReactions}
                    handleReactionClick={handleReactionClick}
                    handleMuteMicrophone={handleMuteMicrophone}
                    onHexagonClick={onHexagonClick}
                    remoteMute={localMute}
                    publicChat={publicChat}
                    newMessage={sendChatToProvider}
                    handleNewMessageAdded={() =>
                      setSendChatToProvider(undefined)
                    }
                    participants={activeUsers}
                    embed={embed}
                  />
                ) : (
                  <ChatAndParticipants>
                    <ActionsWindow
                      publicChat={publicChat}
                      handleNewMessageAdded={() =>
                        setSendChatToProvider(undefined)
                      }
                      newMessage={sendChatToProvider}
                      participants={activeUsers}
                    />
                  </ChatAndParticipants>
                )}

                {displayRobot ? (
                  <ChatAndParticipants>
                    <ActionsWindow
                      publicChat={publicChat}
                      handleNewMessageAdded={() =>
                        setSendChatToProvider(undefined)
                      }
                      newMessage={sendChatToProvider}
                      participants={activeUsers}
                    />
                  </ChatAndParticipants>
                ) : (
                  <Layout
                    handleClick={onHexagonClick}
                    rooms={rooms}
                    speakingPeerIds={speakingPeerIds}
                    triggerAnimation={triggerAnimation}
                    setTriggerAnimation={setTriggerAnimation}
                    selectedEvent={selectedEvent}
                    layout={selectedEvent?.layout?.id}
                    reactionsButtons={reactionButtons}
                    displayedLabelsByMap={displayedLabels}
                    spotsInfo={spotsInfo}
                    displayedUsersByMap={displayedUsers}
                    roomName={roomName}
                    handleChangeRoomModal={handleChangeRoomModal}
                    isRoomsPage
                    openChat={openChat}
                    isMicMuted={isMicMuted}
                    openReactions={openReactions}
                    setOpenReactions={setOpenReactions}
                    handleReactionClick={handleReactionClick}
                    handleMuteMicrophone={handleMuteMicrophone}
                    onHexagonClick={onHexagonClick}
                    remoteMute={localMute}
                    publicChat={publicChat}
                    newMessage={sendChatToProvider}
                    handleNewMessageAdded={() =>
                      setSendChatToProvider(undefined)
                    }
                    participants={activeUsers}
                    embed={embed}
                  />
                )}

                <InfoEventBanner>
                  <InfoEvent />
                </InfoEventBanner>
              </GridLayout>
            )}
            {isMobile && !embed && (
              <Layout
                handleClick={onHexagonClick}
                rooms={rooms}
                speakingPeerIds={speakingPeerIds}
                triggerAnimation={triggerAnimation}
                setTriggerAnimation={setTriggerAnimation}
                selectedEvent={selectedEvent}
                layout={selectedEvent?.layout?.id}
                reactionsButtons={reactionButtons}
                displayedLabelsByMap={displayedLabels}
                spotsInfo={spotsInfo}
                displayedUsersByMap={displayedUsers}
                roomName={roomName}
                handleChangeRoomModal={handleChangeRoomModal}
                isRoomsPage
                openChat={openChat}
                isMicMuted={isMicMuted}
                openReactions={openReactions}
                setOpenReactions={setOpenReactions}
                handleReactionClick={handleReactionClick}
                handleMuteMicrophone={handleMuteMicrophone}
                onHexagonClick={onHexagonClick}
                remoteMute={localMute}
                publicChat={publicChat}
                newMessage={sendChatToProvider}
                handleNewMessageAdded={() => setSendChatToProvider(undefined)}
                participants={activeUsers}
                embed={embed}
                reactionButtons={reactionButtons}
              />
            )}

            {embed && (
              <Layout
                handleClick={onHexagonClick}
                rooms={rooms}
                speakingPeerIds={speakingPeerIds}
                triggerAnimation={triggerAnimation}
                setTriggerAnimation={setTriggerAnimation}
                selectedEvent={selectedEvent}
                layout={selectedEvent?.layout?.id}
                reactionsButtons={reactionButtons}
                displayedLabelsByMap={displayedLabels}
                spotsInfo={spotsInfo}
                displayedUsersByMap={displayedUsers}
                roomName={roomName}
                handleChangeRoomModal={handleChangeRoomModal}
                isRoomsPage
                openChat={openChat}
                isMicMuted={isMicMuted}
                openReactions={openReactions}
                setOpenReactions={setOpenReactions}
                handleReactionClick={handleReactionClick}
                handleMuteMicrophone={handleMuteMicrophone}
                onHexagonClick={onHexagonClick}
                remoteMute={localMute}
                publicChat={publicChat}
                newMessage={sendChatToProvider}
                handleNewMessageAdded={() => setSendChatToProvider(undefined)}
                participants={activeUsers}
                embed={embed}
              />
            )}
          </Container>
        </VolumeProvider>
      </AudioProvider>
    </RoomProvider>
  );
};

export default ConnectComponent(Room);
