import {
  deleteFailed,
  deleteSuccess,
} from 'data/store/reducer/event/actions/delete';
import { iActionDelete } from 'domain/interfaces/redux/event/delete';
import { DeleteEvent } from 'domain/usecases/event/remote/DeleteEvent';
import { makeRemoteDeleteEvent } from 'main/factories/usecases/event/DeleteEventFactory';
import { call, put } from 'redux-saga/effects';
import { iStore } from 'domain/interfaces/models';
import { makeReduxGetDashboard } from 'main/factories/usecases/dashboard/GetDashboardFactory';
import storeDev from '../..';
import { getAllRequest } from '../../reducer/event/actions/getAll';

export function* onDelete(action: iActionDelete) {
  const remoteDeleteEvent = makeRemoteDeleteEvent();

  try {
    const response: DeleteEvent.Model = yield call(
      remoteDeleteEvent.delete,
      action.payload,
    );

    yield put(
      deleteSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(deleteFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function* onDeleteSuccess() {
  yield put(
    getAllRequest({
      limit: 9999,
    }),
  );

  const { dashboard } = storeDev.getState() as iStore;
  const { currentPage } = dashboard;

  const getDashboardData = makeReduxGetDashboard();

  yield put(
    getDashboardData.get({
      currentPage,
      query: {
        limit: 10,
        offset: 10 * (currentPage - 1),
      },
    }),
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onDeleteFailed() {}
