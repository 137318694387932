import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SecondaryHeader = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
  z-index: 2;

  width: 100%;
  height: 6rem;
  padding: 1.2rem 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${({ theme }) => theme.colors.black2};
  color: ${({ theme }) => theme.colors.white2};

  box-shadow: 0px 0.4rem 1rem rgba(0, 0, 0, 0.25);
`;

export const SecondaryHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 1.2rem;

  p.title {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.5rem;
  }
`;

export const SecondaryHeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
`;
