import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { AuthenticateTvConnection as UsecaseRemoteAuthenticateTvConnection } from 'domain/usecases/tv/remote';

import { RemoteAuthenticateTvConnection } from 'data/repository/tv';

/**
 * send request via API.
 */
export const makeRemoteAuthenticateTvConnection =
  (): UsecaseRemoteAuthenticateTvConnection =>
    new RemoteAuthenticateTvConnection(
      makeApiUrl('/tv/connection/authentication'),
      makeHttpClient(),
    );
