import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { SendReaction as UsecaseRemoteSendReaction } from 'domain/usecases/event/remote';

import { RemoteSendReaction } from 'data/repository/event';

/**
 * send request via API.
 */
export const makeRemoteSendReaction = (): UsecaseRemoteSendReaction =>
  new RemoteSendReaction(
    makeApiUrl('/events/{eventId}/parts/{part}/reactions'),
    makeHttpClient(),
  );
