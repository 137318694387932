/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
import { SpotsInfo } from 'data/store/ducks/spotsInfo/types';
import { iReactions, iSpotsInfo, iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxGetAllFriendship } from 'main/factories/usecases/friendship/GetAllFriendshipFactory';
import { makeRemoteRequestFriendship } from 'main/factories/usecases/friendship/RequestFriendshipFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';

import {
  DrawerIcon,
  IconAddFriend,
  IconConfiguration,
  IconEmoticon,
  IconFullScreenPortrait,
  IconMic,
  IconMicMuted,
  IconShare2,
  IconSound,
  IconUser,
} from 'presentation/base/icons';
import PublicChat from 'presentation/components/Chat/PublicChat';
import { MessageProps } from 'presentation/components/Chat/interfaces';
import { FooterResponsive } from 'presentation/components/FooterInsideRoom/footerResponsive';
import InteractiveMap from 'presentation/components/InteractiveMap';
import ArenaLayout from 'presentation/components/Layouts/ArenaLayout';
import CineLayout from 'presentation/components/Layouts/CineLayout';
import TheaterLayout from 'presentation/components/Layouts/TheaterLayout';
import MobileAdContentScreen from 'presentation/components/MobileAdContentScreen';
import MobileContentScreen from 'presentation/components/MobileContentScreen';
import NewChat from 'presentation/components/NewChat';
import ParticipantsList from 'presentation/components/ParticipantsList';
import ClapprComponent from 'presentation/components/Player';
import ReactionsContent from 'presentation/components/ReactionsContent';
import SideBarInfoEvent from 'presentation/components/SideBarInfoEvent';
import Subheader from 'presentation/components/SubHeader';
import TabNavigation from 'presentation/components/TabNavigation';
import { TooltipComponent } from 'presentation/components/TooltipComponent';
import { Button } from 'presentation/components/UI';
import { RandomProfileUser } from 'presentation/components/randomProfileUser';
import { RoomContext } from 'presentation/contexts/RoomContext';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { TimingObject } from 'services/timingsrc/index.js';
import { exitFullscreen, requestFullscreen } from 'utils/handleFullscreen';
import { isIPhone } from 'utils/isIPhone';
import { closeModal } from 'utils/modalFunctions';
import { moveOnRoom } from 'utils/moveOnRoom';
import { Actions } from '../../components/FooterInsideRoom/styles';
import { iAnimation } from './interface';
import {
  Box,
  EventPlayer,
  FullScreenLandscaped,
  FullscreenDiv,
  IconsContainer,
  LayoutsContainer,
  MobileContentContainer,
  MobileContentLayout,
  MobileFooterPlaceholder,
  MobilePlayerContainer,
  MobileScreenContainer,
  ParticipantContainer,
  ParticipantItem,
  ParticipantsActionButton,
  ParticipantsItemActions,
  ParticipantsItemRow,
  ParticipantsMic,
  ParticipantsMutedMic,
  StyledPaginationItem,
  VideoContainer,
} from './styles';

interface OnProgressProps {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

interface iUsers {
  id?: number;
  peerName: string;
  peerId: number;
  userId?: number;
  x: number;
  y: number;
  type: 'R' | 'A' | undefined;
  iAmRequester?: boolean;
  remoteMuteState?: boolean;
}

export interface iParams {
  id: string;
  roomId: string;
}

interface LayoutProps {
  rooms: any[];
  handleClick: (hexagon: any) => any;
  speakingPeerIds: string[];
  triggerAnimation?: iAnimation;
  setTriggerAnimation?: React.Dispatch<React.SetStateAction<iAnimation>>;
  selectedEvent: iEventItem;
  layout?: number;
  reactionsButtons: iReactions[];
  displayedLabelsByMap: number[];
  spotsInfo: iSpotsInfo;
  displayedUsersByMap: number[];
  roomName: string;
  hasPlayer?: boolean;
  isChangingRoom?: boolean;
  isCentered?: boolean;
  isRoomsPage?: boolean;
  layoutSize?: string; // 'small' | 'medium' | 'large' | 'default';
  handleChangeRoomModal: () => void;
  handleMuteMicrophone?: () => void;
  isMicMuted?: boolean;
  localMuteState?: boolean;
  localMute?: () => void;
  openReactions?: boolean;
  openVolume?: boolean;
  handleReactionClick?: (reaction: string) => void;
  setOpenReactions?: (open: boolean) => void;
  setOpenVolume?: (open: boolean) => void;
  openChat?: any;
  onHexagonClick?: (hexagon: any) => void;
  remoteMute?: (data: any) => void;
  publicChat?: any;
  handleNewMessageAdded?: () => void;
  newMessage?: MessageProps;
  participants?: SpotsInfo[];
  embed?: boolean;
  reactionButtons?: iReactions[];
  reactionVolume?: number;
  to?: TimingObject;
}

const layoutEnum = ['lounge', 'cine', 'theater', 'arena'];

const options = [
  { label: 'BATE-PAPO AO VIVO' },
  { label: 'PARTICIPANTES' },
  { label: 'LINHA DO TEMPO', disabled: true },
];

const Layout: React.FC<LayoutProps> = ({
  children,
  handleClick,
  rooms,
  setTriggerAnimation,
  speakingPeerIds,
  triggerAnimation,
  selectedEvent,
  layout = 1,
  reactionsButtons,
  displayedLabelsByMap,
  displayedUsersByMap,
  roomName,
  handleChangeRoomModal,
  hasPlayer,
  layoutSize,
  isChangingRoom = false,
  isCentered = false,
  isRoomsPage = false,
  isMicMuted = false,
  openReactions = false,
  openVolume = false,
  handleMuteMicrophone,
  localMuteState,
  localMute,
  handleReactionClick,
  setOpenReactions,
  setOpenVolume,
  openChat,
  onHexagonClick,
  remoteMute,
  publicChat,
  handleNewMessageAdded,
  newMessage,
  participants,
  embed,
  reactionButtons,
  reactionVolume,
  to,
}) => {
  const { isFullScreen, setIsFullScreen } = useContext(RoomContext);
  // const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [isIphone, setIsIphone] = React.useState(false);
  const [displayedLabels, setDisplayedLabels] = useState<number[]>([]);
  const [displayedUsers, setDisplayedUsers] = useState<number[]>([]);
  const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState<number>(0);
  const [gridSquareSize, setGridSquareSize] = useState<string>('large');
  const [gridSquareSizeBool, setGridSquareSizeBool] = useState<boolean>(false);
  const [isVirtualRoomOpen, setIsVirtualRoomOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [users, setUsers] = useState<any[]>(rooms);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [isAdDisplayed, setIsAdDisplayed] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [idFriend, setIdFriend] = useState(0);
  const [tabActive, setTabActive] = useState(-1);

  const wrtcInfo = useSelector((state: iStore) => state.auth.wrtcInfo);
  const spotsInfo = useSelector((state: iStore) => state.spotsInfo);
  const category = useSelector((state: iStore) => state.category);
  const { user: userLog, wrtcInfo: infoUser } = useSelector(
    (store: iStore) => store.auth,
  );
  const { active } = useSelector((state: iStore) => state.message);
  const { records } = useSelector((state: iStore) => state.friendShip);
  const { isMobile, orientation } = useWindowDimensions();
  const playerRef = useRef<ReactPlayer | null>(null);
  const newPlayerRef = useRef<HTMLVideoElement | null>(null);
  const fullScreenPlayerRef = useRef<ReactPlayer | null>(null);
  const newFullScreenPlayerRef = useRef<HTMLVideoElement | null>(null);
  const wrtc = useSelector((store: iStore) => store.wrtc);

  const params: iParams = useParams();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleAction = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'options-popover' : undefined;

  const handleContainerSize = useCallback((size: typeof layoutSize): string => {
    switch (size) {
      case 'smaller':
        return '1.5rem';
      case 'small':
        return '2rem';
      case 'medium':
        return '4rem';
      case 'large':
        return '5rem';
      default:
        return '5rem';
    }
  }, []);

  const handleInsideMapNavigationModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.insideMapModal,
      // actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
      componentProps: {
        insideMap: {
          roomName,
          selectedEvent,
          rooms,
          layout,
          size: handleContainerSize(layoutSize),
          handleClick,
        },
      },
    });
  }, [
    roomName,
    selectedEvent,
    rooms,
    layout,
    handleContainerSize,
    layoutSize,
    handleClick,
  ]);

  const handlePreviousUser = () => {
    if (!spotsInfo?.results.length) return;

    const ids = spotsInfo.results
      .map((item, index) => {
        if (displayedLabels.includes(Number(item.peerId)) && index - 1 >= 0) {
          return spotsInfo.results[index - 1].peerId;
        }
      })
      .filter(Boolean);

    if (displayedUsers.length <= ids.length) setDisplayedUsers(ids as number[]);
  };

  const handleNextUser = () => {
    if (!spotsInfo?.results?.length) return;

    const ids = spotsInfo.results
      .map((item, index) => {
        if (
          displayedUsers.includes(Number(item.peerId)) &&
          index + 1 < spotsInfo.results.length
        ) {
          return spotsInfo.results[index + 1].peerId;
        }
      })
      .filter(Boolean);

    if (displayedUsers.length <= ids.length) setDisplayedUsers(ids as number[]);
  };

  const handleProgress = (data: OnProgressProps) => {
    setCurrentTimeInSeconds(data.playedSeconds);
  };

  const handleInviteModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.inviteModal,
      actionCancel: () => closeModal(),
    });
  }, []);

  const handleConfigurationModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.roomsConfigure,
      actionCancel: () => closeModal(),
    });
  }, []);

  // const handleProgress = (data: SyntheticEvent<HTMLVideoElement, Event>) => {
  //   const video = data.currentTarget;
  //   const { currentTime } = video;

  //   setCurrentTimeInSeconds(currentTime);
  // };

  const Player = useCallback(
    () =>
      embed ? (
        <></>
      ) : (
        <div
          style={{
            position: 'relative',
            zIndex: 0,
            alignSelf: 'center',
          }}
        >
          <Button
            id="fullscreen-btn"
            tooltipProps={{
              title: isFullScreen
                ? 'Sair do modo tela cheia'
                : 'Modo tela cheia',
              placement: 'top',
            }}
            size="very small"
            style={{
              zIndex: 10,
              position: 'absolute',
              bottom: 0,
              right: 0,
              borderRadius: '5px 0 0 0',
            }}
            type="button"
            onClick={() => setIsFullScreen(!isFullScreen)}
            icon={IconFullScreenPortrait}
            iconSize={20}
          />

          {selectedEvent?.media?.includes('.m3u8') ||
          selectedEvent?.external?.includes('.m3u8') ? (
            <ClapprComponent
              source={selectedEvent?.external || selectedEvent?.media}
            />
          ) : (
            <EventPlayer
              ref={playerRef}
              playing={isPlaying}
              // onProgress={handleProgress}
              url={selectedEvent?.external || selectedEvent?.media}
              height="250px"
              width="400px"
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              config={{
                youtube: {
                  playerVars: { fs: 0, rel: 0, showinfo: 0, modestbranding: 1 },
                },
                file: {
                  attributes: {
                    controlsList: 'nofullscreen nodownload noremoteplayback',
                    disablePictureInPicture: true,
                  },
                },
              }}
            />
          )}

          <div
            style={{
              display: 'flex',
              position: 'absolute',
              width: '100%',
              bottom: '0',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            <Button
              id="reset"
              title="Reset"
              onClick={() => to?.update({ position: 0 })}
            />
            <Button
              id="play"
              title="Play"
              onClick={() => to?.update({ velocity: 1 })}
            />
            <Button
              id="pause"
              title="Pause"
              onClick={() => to?.update({ velocity: 0 })}
            />
            <Button
              id="reverse"
              title="Reverse"
              onClick={() => to?.update({ velocity: -1 })}
            />
          </div>
        </div>
      ),
    [isFullScreen, selectedEvent, layout, embed, to],
  );

  const displayRobot = useMemo(() => {
    return selectedEvent?.name?.includes('X2R4');
  }, [selectedEvent?.name]);

  const renderLayouts = useMemo(() => {
    switch (layoutEnum[layout - 1]) {
      case 'lounge':
        return (
          <InteractiveMap
            roomName={roomName}
            selectedEvent={selectedEvent}
            layout={layout}
            rooms={rooms}
            preview={gridSquareSizeBool}
            handleClick={handleClick}
            speakingPeerIds={speakingPeerIds}
            triggerAnimation={triggerAnimation}
            setTriggerAnimation={setTriggerAnimation}
            containerSize={layoutSize}
            size={handleContainerSize(layoutSize)}
            Player={hasPlayer ? undefined : Player}
            isChangingRoom={isChangingRoom}
            isRoomsPage={isRoomsPage}
            isHovering={isCentered || isHovering}
            isFullscreen={isFullScreen}
            embed={embed}
            robot={displayRobot}
          />
        );

      case 'cine':
        return (
          <CineLayout
            roomName={roomName}
            selectedEvent={selectedEvent}
            layout={layout}
            rooms={rooms}
            size={handleContainerSize(layoutSize)}
            containerSize={layoutSize}
            handleClick={handleClick}
            speakingPeerIds={speakingPeerIds}
            triggerAnimation={triggerAnimation}
            setTriggerAnimation={setTriggerAnimation}
            Player={hasPlayer ? undefined : Player}
            isChangingRoom={isChangingRoom}
            isRoomsPage={isRoomsPage}
            isHovering={isCentered || isHovering}
            isFullscreen={isFullScreen}
          />
        );

      case 'theater':
        return (
          <TheaterLayout
            roomName={roomName}
            selectedEvent={selectedEvent}
            layout={layout}
            rooms={rooms}
            size={handleContainerSize(layoutSize)}
            containerSize={layoutSize}
            handleClick={handleClick}
            speakingPeerIds={speakingPeerIds}
            triggerAnimation={triggerAnimation}
            setTriggerAnimation={setTriggerAnimation}
            Player={hasPlayer ? undefined : Player}
            isChangingRoom={isChangingRoom}
            isRoomsPage={isRoomsPage}
            isHovering={isCentered || isHovering}
            isFullscreen={isFullScreen}
          />
        );

      case 'arena':
        return (
          <ArenaLayout
            roomName={roomName}
            selectedEvent={selectedEvent}
            layout={layout}
            rooms={rooms}
            size={handleContainerSize(layoutSize)}
            containerSize={layoutSize}
            handleClick={handleClick}
            speakingPeerIds={speakingPeerIds}
            triggerAnimation={triggerAnimation}
            setTriggerAnimation={setTriggerAnimation}
            Player={hasPlayer ? undefined : Player}
            isChangingRoom={isChangingRoom}
            isRoomsPage={isRoomsPage}
            isHovering={isCentered || isHovering}
            isFullscreen={isFullScreen}
          />
        );
      default:
        return null;
    }
  }, [
    layout,
    roomName,
    selectedEvent,
    rooms,
    gridSquareSizeBool,
    handleClick,
    speakingPeerIds,
    triggerAnimation,
    setTriggerAnimation,
    layoutSize,
    handleContainerSize,
    hasPlayer,
    Player,
    isChangingRoom,
    isRoomsPage,
    isCentered,
    isHovering,
    isFullScreen,
    embed,
    displayRobot,
  ]);

  const renderUsersItems = useMemo(() => {
    let renderUsers =
      spotsInfo?.results?.map(item => {
        if (!displayedUsers.includes(Number(item.peerId))) return null;
        return (
          <TooltipComponent
            title={item.peerName.split(':')[0]}
            placement="left"
          >
            <StyledPaginationItem
              key={item.peerId}
              style={{ position: 'relative' }}
            >
              {/* <IconUser />
            <CategoryTitle style={{ position: 'absolute' }}>
              {item.peerName.substring(0, 2).toLocaleUpperCase()}
            </CategoryTitle> */}
              <RandomProfileUser peerId={item.peerId} />
            </StyledPaginationItem>
          </TooltipComponent>
        );
      }) ?? [];

    if (renderUsers?.length < 4) {
      renderUsers = [
        ...renderUsers,
        ...Array(4 - renderUsers.length).fill(
          <StyledPaginationItem>
            <IconUser />
          </StyledPaginationItem>,
        ),
      ];
    }

    return renderUsers;
  }, [spotsInfo?.results, displayedUsers]);

  const isActualUser = useCallback(
    (peerId: number) => {
      return Number(wrtcInfo?.peerId) === peerId;
    },
    [wrtcInfo?.peerId],
  );

  const getMuteState = useCallback(
    (peerId: number): boolean => {
      return wrtc?.getRemotePeerInputEdgeGain?.(peerId) === 0 ?? false;
    },
    [wrtc],
  );

  const handleRemoteMute = useCallback(
    (peerId: number, x: number, y: number) => {
      const isMuted = getMuteState(peerId);

      remoteMute?.({ x, y });

      if (wrtc.setRemotePeerInputEdgeGain) {
        isMuted
          ? wrtc.setRemotePeerInputEdgeGain(peerId, 1)
          : wrtc.setRemotePeerInputEdgeGain(peerId, 0);
      }

      // toast(isMuted ? 'Usuário não mais silenciado!' : 'Usuário silenciado!', {
      //   type: isMuted ? 'info' : 'error',
      // });
    },
    [getMuteState, remoteMute, wrtc],
  );

  const renderMic = useCallback(
    (item: iUsers) => {
      if (isActualUser(item?.peerId ?? -1)) {
        return localMuteState ? (
          <ParticipantsMic onClick={() => localMute?.()} />
        ) : (
          <ParticipantsMutedMic onClick={() => localMute?.()} />
        );
      }

      return getMuteState(item.peerId) ? (
        <ParticipantsMutedMic
          onClick={() => handleRemoteMute?.(item.peerId, item.x, item.y)}
        />
      ) : (
        <ParticipantsMic
          onClick={() => handleRemoteMute?.(item.peerId, item.x, item.y)}
        />
      );
    },
    [getMuteState, handleRemoteMute, isActualUser, localMute, localMuteState],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      moveOnRoom(
        e,
        rooms,
        selectedEvent?.layout?.id,
        onHexagonClick,
        wrtcInfo?.peerId,
      );
    },
    [onHexagonClick, rooms, selectedEvent?.layout?.id, wrtcInfo?.peerId],
  );

  const handlePlayerRef = useCallback((player: any) => {
    playerRef.current = player;
  }, []);

  useEffect(() => {
    setDisplayedLabels(displayedLabelsByMap);
    setDisplayedUsers(displayedUsersByMap);
  }, [displayedLabelsByMap, displayedUsersByMap]);

  const disableAddFriend = useCallback(
    (friendId: number): boolean => {
      return (
        records?.findIndex(
          el =>
            Number(el.addressee.id) === friendId ||
            Number(el.requester.id) === friendId,
        ) !== -1
      );
    },
    [records],
  );

  useEffect(() => {
    try {
      if (isFullScreen) {
        if (newFullScreenPlayerRef?.current)
          newFullScreenPlayerRef.current.currentTime = currentTimeInSeconds;

        fullScreenPlayerRef?.current?.seekTo(currentTimeInSeconds, 'seconds');
      } else {
        if (newPlayerRef?.current)
          newPlayerRef.current.currentTime = currentTimeInSeconds;

        playerRef?.current?.seekTo(currentTimeInSeconds, 'seconds');
        exitFullscreen(document);
      }
    } catch (error) {
      console.log('Error on fullscreen: ', error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen]);

  useEffect(() => {
    // OBS: The request to fullscreen only work if the button is clicked
    const button = document.getElementById('fullscreen-btn');
    button?.addEventListener('click', () => {
      if (!isFullScreen) requestFullscreen(document);
      else exitFullscreen(document);
    });
  }, [isFullScreen]);

  useEffect(() => {
    if (JSON.stringify(rooms) !== JSON.stringify(users)) {
      setUsers(rooms);
    }
  }, [rooms]);

  useEffect(() => {
    if (active === MessageOptions.insideMapModal) {
      handleInsideMapNavigationModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms]);

  useEffect(() => {
    if (isMobile && orientation === 'landscape') {
      setIsFullScreen(true);
    }
    if (isMobile && orientation === 'portrait') {
      setIsFullScreen(false);
    }
  }, [orientation, isMobile]);

  useEffect(() => {
    setIsIphone(isIPhone());
  }, []);

  useEffect(() => {
    tabActive === 2 &&
      makeReduxGetAllFriendship().getAll({
        user: Number(userLog.id),
      });
  }, [tabActive, userLog.id]);

  // const size = '5rem';

  if (embed || (!isFullScreen && !isMobile)) {
    return (
      <LayoutsContainer
        isRoomsPage={isRoomsPage}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        embed={embed}
      >
        <VideoContainer embed={embed}>
          <ScrollContainer
            className="scroll-container"
            horizontal
            vertical
            style={{
              padding: isChangingRoom ? '0.2rem' : '0',
              width: '100%',
              height: '100%',
            }}
          >
            {!isFullScreen && renderLayouts}
          </ScrollContainer>
        </VideoContainer>
        {embed && (
          <IconsContainer>
            <Box onClick={handleMuteMicrophone} isMic btnActive={isMicMuted}>
              {isMicMuted ? <IconMic /> : <IconMicMuted />}
            </Box>
            <Box disabled>
              <IconSound />
            </Box>
            <Box>
              <TooltipComponent
                title="teste"
                placement="bottom"
                tooltipType="reactions"
                open={openReactions}
                titleContent={
                  <ReactionsContent
                    reactions={reactionButtons || []}
                    handleReactionsClick={handleReactionClick}
                    openReactions={openReactions}
                    setOpenReactions={() => setOpenReactions?.(!openReactions)}
                  />
                }
              >
                <Actions
                  onClick={() => setOpenReactions?.(!openReactions)}
                  id="btn_reaction"
                >
                  <IconEmoticon />
                </Actions>
              </TooltipComponent>
            </Box>
            <Box onClick={handleInviteModal}>
              <IconShare2 />
            </Box>
            <Box onClick={handleConfigurationModal}>
              <IconConfiguration />
            </Box>
          </IconsContainer>
        )}
      </LayoutsContainer>
    );
  }

  // Mobile
  if (!isFullScreen && !embed && isMobile && isRoomsPage) {
    return (
      <>
        <Subheader title={`Sala ${params?.roomId}`} />
        <MobileScreenContainer>
          <MobilePlayerContainer
            isDescriptionDisplaying={isDisplayed || isAdDisplayed}
          >
            <div style={{ height: '202px' }}>
              <ClapprComponent
                source={selectedEvent?.external || selectedEvent?.media}
                rounded
                playerRef={handlePlayerRef}
              />
              <div
                style={{
                  display: 'flex',
                  position: 'absolute',
                  width: '100%',
                  bottom: '0',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                }}
              >
                <Button
                  id="reset"
                  title="Reset"
                  onClick={() => to?.update({ position: 0 })}
                />
                <Button
                  id="play"
                  title="Play"
                  onClick={() => to?.update({ velocity: 1 })}
                />
                <Button
                  id="pause"
                  title="Pause"
                  onClick={() => to?.update({ velocity: 0 })}
                />
                <Button
                  id="reverse"
                  title="Reverse"
                  onClick={() => to?.update({ velocity: -1 })}
                />
              </div>
            </div>
          </MobilePlayerContainer>
          <MobileFooterPlaceholder>
            <FooterResponsive
              selectedEvent={selectedEvent}
              handleMuteMicrophone={handleMuteMicrophone}
              isMicMuted={isMicMuted}
              handleReactionClick={handleReactionClick}
              openReactions={openReactions}
              reactionsButtons={reactionsButtons}
              setOpenReactions={setOpenReactions}
              handleDescriptionClick={() =>
                setIsDisplayed(prevState => !prevState)
              }
              handleAdInfoClick={() =>
                setIsAdDisplayed(prevState => !prevState)
              }
              playerRef={playerRef}
            />
          </MobileFooterPlaceholder>
          {orientation === 'portrait' && (
            <>
              <NewChat
                isMobile
                closeChat={() => setTabActive(-1)}
                isOpen={tabActive === 0}
                chat={publicChat}
                key={publicChat?.id}
                chatId={publicChat?.id}
                handleNewMessageAdded={handleNewMessageAdded}
                newMessage={newMessage}
              />
              <ParticipantsList
                isMobile
                participants={participants}
                onClose={() => setTabActive(-1)}
                isOpen={tabActive === 1}
              />
              <MobileContentContainer>
                <MobileContentLayout
                  isDescriptionDisplaying={isDisplayed || isAdDisplayed}
                >
                  <div>{renderLayouts}</div>
                </MobileContentLayout>
                <TabNavigation
                  active={tabActive}
                  options={options}
                  setState={setTabActive}
                />
              </MobileContentContainer>

              <MobileContentScreen
                selectedEvent={selectedEvent}
                isDisplayed={isDisplayed}
                onClose={() => setIsDisplayed(false)}
              />

              <MobileAdContentScreen
                selectedEvent={selectedEvent}
                isDisplayed={isAdDisplayed}
                onClose={() => setIsAdDisplayed(false)}
              />
            </>
          )}
        </MobileScreenContainer>
      </>
    );
  }

  /* FullScreen */
  return (
    <FullscreenDiv
      orientation={orientation}
      isMobile={isMobile}
      isFullScreen={isFullScreen}
      isIphone={isIphone}
    >
      <div
        style={{
          width: isMenu ? 'calc(100% - 42rem)' : '100%',
          height: isMobile ? '100%' : 'calc(100% - 8rem)',
          position: 'relative',
        }}
      >
        <EventPlayer
          ref={fullScreenPlayerRef}
          width="100%"
          height="100%"
          playing={isPlaying}
          // onProgress={handleProgress}
          url={selectedEvent?.external || selectedEvent?.media}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          config={{
            youtube: {
              playerVars: { fs: 0, rel: 0, showinfo: 0, modestbranding: 1 },
            },
            file: {
              attributes: {
                controlsList: 'nofullscreen nodownload noremoteplayback',
                disablePictureInPicture: true,
              },
            },
          }}
        />

        <DrawerIcon
          style={{
            zIndex: 10,
            position: 'absolute',
            bottom: 0,
            right: 27,
            top: '50%',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '8px',

            // transform: isMenu ? 'rotate(0deg)' : 'rotate(180deg)',
            // transition: 'transform 0.3s ease-in-out',
          }}
          onClick={() => setIsMenu(!isMenu)}
        />

        {!isMobile && (
          <div>
            {/* <VirtualAudienceModal
              user={{ ...userLog, id: 4, name: 'Lista de participantes' }}
              participants={spotsInfo?.results}
              event={selectedEvent}
              isOpen={isVirtualRoomOpen}
              onClose={() => setIsVirtualRoomOpen(false)}
              localMuteState={isMicMuted}
              localMute={handleMuteMicrophone}
              remoteMute={remoteMute}
            /> */}
            {/* <FooterInsideRoom
              handleChangeRoomModal={handleChangeRoomModal}
              handleKeyPress={handleKeyPress}
              handleMuteMicrophone={handleMuteMicrophone}
              handleNextUser={handleNextUser}
              handlePreviousUser={handlePreviousUser}
              handleReactionClick={handleReactionClick}
              isMicMuted={isMicMuted}
              isVirtualRoomOpen={isVirtualRoomOpen}
              openChat={openChat}
              openReactions={openReactions}
              setOpenReactions={setOpenReactions}
              reactionButtons={reactionsButtons}
              setIsVirtualRoomOpen={setIsVirtualRoomOpen}
              roomName={roomName}
              selectedEvent={selectedEvent}
              renderUsersItems={renderUsersItems}
              handleInsideMapNavigationModal={handleInsideMapNavigationModal}
              isFullScreen={isFullScreen}
            /> */}
          </div>
        )}
      </div>

      {isMenu && !isMobile && (
        <SideBarInfoEvent
          rooms={rooms}
          publicChat={publicChat}
          handleNewMessageAdded={handleNewMessageAdded!}
          newMessage={newMessage}
          participants={participants}
          renderLayouts={renderLayouts}
        />
      )}

      {isMobile && (
        <FullScreenLandscaped isMenu={isMenu}>
          <TabNavigation
            active={tabActive}
            options={options}
            setState={setTabActive}
            isMenu={isMenu}
          />

          <ScrollContainer
            className="scroll-container"
            horizontal
            vertical
            style={{
              padding: '0.7rem',
              height: '100%',
            }}
          >
            {tabActive === 0 && <div>{renderLayouts}</div>}
            {tabActive === 1 && (
              <MobileContentLayout>
                <PublicChat
                  isMobile
                  chat={publicChat}
                  key={publicChat?.id}
                  chatId={publicChat?.id}
                  handleNewMessageAdded={handleNewMessageAdded}
                  newMessage={newMessage}
                />
              </MobileContentLayout>
            )}
            {tabActive === 2 && (
              <ParticipantContainer>
                {users?.map((participant, idx) => {
                  if (participant.peerName === '') return <></>;
                  const friendId = Number(participant.peerName.split(':')[1]);
                  const disabled =
                    friendId === Number(userLog.id) ||
                    disableAddFriend(friendId);

                  return (
                    <ParticipantItem key={idx}>
                      <ParticipantsItemRow>
                        <RandomProfileUser
                          peerId={participant.peerId}
                          style={{ width: 32, height: 32 }}
                        />
                        <h4>{participant.peerName.split(':')[0]}</h4>
                      </ParticipantsItemRow>
                      <ParticipantsItemActions>
                        <ParticipantsItemRow>
                          <ParticipantsActionButton>
                            {renderMic(participant)}
                          </ParticipantsActionButton>
                        </ParticipantsItemRow>
                        <ParticipantsItemRow>
                          <ParticipantsActionButton
                            disabled={disabled}
                            onClick={async () => {
                              await makeRemoteRequestFriendship()
                                .request({
                                  user: Number(userLog.id),
                                  addressee: friendId,
                                })
                                .then(() => {
                                  makeReduxGetAllFriendship().getAll({
                                    user: Number(userLog.id),
                                  });
                                });
                            }}
                          >
                            <IconAddFriend />
                          </ParticipantsActionButton>
                        </ParticipantsItemRow>
                      </ParticipantsItemActions>
                    </ParticipantItem>
                  );
                })}
              </ParticipantContainer>
            )}
          </ScrollContainer>
        </FullScreenLandscaped>
      )}
    </FullscreenDiv>
  );
};

export default Layout;
