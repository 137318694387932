import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import {
  BadRequestError,
  InvalidCredentialsError,
  UnexpectedError,
  UnProcessableEntity,
} from 'domain/errors';
import { AuthenticateTvConnection } from 'domain/usecases/tv/remote';
import 'infra/global/variables';

export class RemoteAuthenticateTvConnection
  implements AuthenticateTvConnection
{
  private readonly url: string;

  private readonly httpClient: HttpClient<AuthenticateTvConnection.Model>;

  constructor(
    url: string,
    httClient: HttpClient<AuthenticateTvConnection.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  auth = async (
    params: AuthenticateTvConnection.Params,
  ): Promise<AuthenticateTvConnection.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
