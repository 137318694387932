import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { PostConfirmUser as UsecaseRemotePostConfirmUser } from 'domain/usecases/user/remote/ConfirmUser';

import { RemotePostConfirmUser } from 'data/repository/user/ConfirmUser';

/**
 * send request via API.
 */

export const makeRemotePostConfirmUser = (): UsecaseRemotePostConfirmUser =>
  new RemotePostConfirmUser(
    makeApiUrl('/users/confirmation'),
    makeHttpClient(),
  );
