/* eslint-disable react/require-default-props */
import { defaultTheme } from 'presentation/base/themes';
import React, { memo } from 'react';

import ReactSelect, {
  components,
  DropdownIndicatorProps,
  MenuProps,
  NoticeProps,
} from 'react-select';

import { IconDropdownIndicator } from 'presentation/base/icons';

import { Message } from '../Input/styles';
import { Container, customStyles, Label, StNoOptionsMessage } from './styles';

export interface iOption {
  label: string;
  value: string | number;
  isDisabled?: boolean;
}

interface SelectProps {
  id?: string;
  menuPlacement?: 'auto' | 'top' | 'bottom';
  options: iOption[];
  buttonOnSelect?: () => React.ReactNode;
  placeholder?: string;
  message?: string;
  label: string;
  required?: boolean;
  success?: boolean;
  error?: boolean;
  variant?: 'primary' | 'secondary';
  status?: 'success' | 'error';
  size?: 'small' | 'medium' | 'large' | 'full';
  color?: keyof typeof defaultTheme.colors;
  value?: iOption;
  defaultValue?: iOption;
  menuPortalTarget?: HTMLElement | null;
  disabled?: boolean;
  isLoading?: boolean;
  ref?: React.Ref<any>;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const Select: React.FC<SelectProps> = ({
  id,
  options,
  buttonOnSelect,
  placeholder,
  size = 'full',
  color = 'black2',
  menuPlacement = 'auto',
  variant,
  status,
  message,
  label,
  required,
  success = false,
  error = false,
  value,
  defaultValue,
  menuPortalTarget,
  disabled = false,
  isLoading = false,
  onBlur,
  ...rest
}) => {
  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <IconDropdownIndicator width="1rem" height="1rem" fill="none" />
      </components.DropdownIndicator>
    );
  };

  const Menu = (props: MenuProps) => {
    return (
      <components.Menu {...props}>
        {props.children}
        {buttonOnSelect && buttonOnSelect()}
      </components.Menu>
    );
  };

  const NoOptionsMessage = (props: NoticeProps) => {
    return (
      <components.NoOptionsMessage {...props}>
        <StNoOptionsMessage>{props.children}</StNoOptionsMessage>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Container size={size}>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <ReactSelect
        id={id}
        classNamePrefix="select"
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        styles={customStyles({ status, error })}
        noOptionsMessage={() => 'Nada para selecionar aqui!'}
        options={options}
        isSearchable={false}
        isDisabled={disabled || isLoading}
        isLoading={isLoading}
        onBlur={onBlur}
        components={{
          DropdownIndicator,
          Menu,
          NoOptionsMessage,
        }}
        menuPlacement="auto"
        menuPosition="fixed"
        menuPortalTarget={menuPortalTarget}
        maxMenuHeight={200}
        {...rest}
      />
      <Message error={error}>{message}</Message>
    </Container>
  );
};

export default memo(Select, (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.options === nextProps.options
  );
});
