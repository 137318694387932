import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetTvConnection as UsecaseRemoteGetTvConnection } from 'domain/usecases/tv/remote';

import { RemoteGetTvConnection } from 'data/repository/tv';

/**
 * send request via API.
 */
export const makeRemoteGetTvConnection = (): UsecaseRemoteGetTvConnection =>
  new RemoteGetTvConnection(
    makeApiUrl('/tv/connection/{token}'),
    makeHttpClient(),
  );
