import { MessageOptions } from 'domain/interfaces/redux/message';
import { CreateEvent } from 'domain/usecases/event/remote';
import { makeRemoteCreateEvent } from 'main/factories/usecases/event/CreateEventFactory';
import { makeRemoteSendMiniatureEvent } from 'main/factories/usecases/event/SendMiniatureEventFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type createEventResponse = {
  mediaType: 'LIVE' | 'VOD' | 'EXTERNAL';
  date: string;
  url?: string;
  accessKey?: string;
};

type NewEventContextProps = {
  isSuccess: boolean;
  response: createEventResponse;
  resetForm: () => void;
  nextStep: () => void;
};

export const NewEventContext = createContext<NewEventContextProps>({
  isSuccess: false,
} as NewEventContextProps);

const NewEventProvider: React.FC = ({ children }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [response, setResponse] = useState<createEventResponse>({
    mediaType: 'LIVE',
    date: '',
  });

  const {
    trigger,
    getValues,
    unregister,
    register,
    reset,
    formState: { errors, isValid },
  } = useFormContext();

  const getDuration = useCallback((start: string, end?: string) => {
    if (!end) return 0;

    const [startHour, startMinute] = start.split(':');
    const [endHour, endMinute] = end.split(':');

    const startHourInMinutes = Number(startHour) * 60;
    const endHourInMinutes = Number(endHour) * 60;

    const startInMinutes = startHourInMinutes + Number(startMinute);
    const endInMinutes = endHourInMinutes + Number(endMinute);

    const duration = endInMinutes - startInMinutes;
    return duration;
  }, []);

  const resetForm = useCallback(() => {
    reset();
    setIsSuccess(false);
    setResponse({
      mediaType: 'LIVE',
      date: '',
    });
  }, [reset]);

  const handleSubmitForm = useCallback(async () => {
    const createEvent = makeRemoteCreateEvent();

    // Extraindo variaveis auxiliares para tratamento de dados
    const {
      schedule: scheduleStart,
      scheduleEndHour,
      scheduleStartHour,
      mediaType,
      noForecast,
    } = getValues();
    unregister([
      'scheduleEndHour',
      'scheduleStartHour',
      'scheduleEnd',
      'schedulingHourStart',
      'noForecast',
    ]);

    const duration = getDuration(scheduleStartHour, scheduleEndHour);

    const schedule = scheduleStart.toISOString();

    const dataFormatted: CreateEvent.Params = {
      ...(getValues() as CreateEvent.Params),
      duration: noForecast ? null : duration,
      schedule,
      joinPolicy: 'PUBLIC',
      paymentPolicy: 'FREE',
      listPolicy: 'PUBLIC',
      rooms: 50,
      competitive: false,
    };

    try {
      makeReduxActiveMessage().active({
        active: MessageOptions.creatingEvent,
      });

      const formData = new FormData();
      if (dataFormatted.media && mediaType === 'VOD') {
        formData.append('media', dataFormatted.media as string);
      }
      formData.append('thumbnail', dataFormatted.thumbnail as string);

      formData.append('banner', dataFormatted.thumbnail as string);

      const responseImage = await makeRemoteSendMiniatureEvent()
        .sendMiniature({
          body: formData,
        })
        .then(responseToUploadImage => responseToUploadImage)
        .catch(error => error);

      const { media, banner, thumbnail } = responseImage;

      if (mediaType !== 'VOD') delete dataFormatted.media;

      const eventResponse = await createEvent.create({
        ...dataFormatted,
        thumbnail,
        banner,
        layoutId: 1,
        ...(mediaType === 'EXTERNAL' && { external: getValues()?.media }),
        ...(media && mediaType === 'VOD' && { media }),
      });

      setResponse({
        ...response,
        date: schedule,
        mediaType,
        ...(mediaType === 'LIVE' && {
          url: eventResponse.liveToken?.url,
          accessKey: eventResponse.liveToken?.streamKey,
        }),
      });

      resetForm();

      makeReduxActiveMessage().active({
        active: MessageOptions.successOnCreateEvent,
        content: JSON.stringify({
          name: dataFormatted.name,
          date: schedule,
          mediaType,
          ...(mediaType === 'LIVE' && {
            url: eventResponse.liveToken?.url,
            accessKey: eventResponse.liveToken?.streamKey,
          }),
        }),
      });
    } catch (e) {
      register('schedulingHourStart', {
        value: {
          label: scheduleStart,
          value: scheduleStart,
        },
      });

      makeReduxActiveMessage().active({
        active: MessageOptions.none,
      });
    }
  }, [getDuration, getValues, register, resetForm, response, unregister]);

  const nextStep = useCallback(async () => {
    if (isValid) handleSubmitForm();
    else trigger();
  }, [handleSubmitForm, isValid, trigger]);

  return (
    <NewEventContext.Provider
      value={{
        isSuccess,
        response,
        resetForm,
        nextStep,
      }}
    >
      {children}
    </NewEventContext.Provider>
  );
};

export const useNewEvent = (): NewEventContextProps => {
  const context = useContext(NewEventContext);
  if (!context) {
    throw new Error('useNewEvent must be used within an NewEventProvider');
  }
  return context;
};

export default NewEventProvider;
