import { iMessage } from 'domain/interfaces/models';
import { Sponsor } from 'domain/interfaces/models/Sponsors';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxCreateSponsor } from 'main/factories/usecases/sponsor/CreateSponsorFactory';
import { IconAdviser, IconCheck, IconClose } from 'presentation/base/icons';
import { defaultTheme } from 'presentation/base/themes';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  schema,
  schemaCreateSponsor,
} from 'validation/validators/CreateSponsor/CreateSponsorValidator';
import LogoDropzone from '../../Dropzone';
import { colors } from '../../EventsForm/mocks/publicity';
import { Button, Input, NewSelect } from '../../UI';
import { Container as ModalContainer } from '../../messages/Background';
import {
  Body,
  CheckBoxStyled,
  CheckboxContainer,
  CircleFill,
  CloseButton,
  ContentInput,
  Divider,
  Footer,
  Header,
  IconHeader,
  Label,
  LabelHeader,
  ModalContent,
  Title,
} from './styles';

interface props {
  message: iMessage;
}

type iCreateSponsor = Omit<Sponsor, 'id' | 'short'>;

const CreateTemplate: React.FC<props> = ({ message }) => {
  const [colorActive, setColorActive] = React.useState(colors[0]);

  const { active, actionCancel } = message;
  const isActive = active === MessageOptions.createTemplateToSponsor;

  const formHook = useForm<iCreateSponsor>({
    resolver: schemaCreateSponsor,
    shouldFocusError: true,
    defaultValues: {
      name: '',
      descr: '',
      url: '',
      label: '',
      color: colors[0],
    },
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = formHook;

  const handleSelectColor = useCallback(
    (color: keyof typeof defaultTheme.colors) => {
      setColorActive(color);
      setValue('color', defaultTheme.colors[color]);
    },
    [setValue],
  );

  const handleResetForm = useCallback(() => {
    reset();
    setValue('color', colors[0]);
    setColorActive(colors[0]);
  }, [reset, setValue]);

  const handleCloseModal = useCallback(() => {
    handleResetForm();
    actionCancel?.();
  }, [actionCancel, handleResetForm]);

  const submitForm = () => {
    const { name, descr, logo, url, label, color, banner } = getValues();

    const formData = new FormData();

    formData.append('name', name);
    formData.append('descr', descr);
    formData.append('logo', logo);
    formData.append('url', url);
    formData.append('label', label);
    formData.append('color', color);
    formData.append('banner', banner);

    makeReduxCreateSponsor().create(formData);

    handleResetForm();
  };

  useEffect(() => {
    setValue('color', colors[0]);
  }, [register, setValue]);

  const handleSelect = useCallback(
    (field: keyof typeof schema, option: { label: string; value: any }) => {
      const { value } = option;

      setValue(field, value);

      trigger(field);
    },
    [setValue, trigger],
  );

  return isActive ? (
    <FormProvider {...formHook}>
      <ModalContainer tabIndex={0}>
        <ModalContent id="container-create-template-modal">
          <Header>
            <Title>
              <IconHeader>
                <IconAdviser />
              </IconHeader>
              <LabelHeader>Criar anúncio</LabelHeader>
            </Title>
            <CloseButton type="button" onClick={handleCloseModal}>
              <IconClose />
            </CloseButton>
          </Header>
          <Divider />
          <Body>
            <Input
              {...register('name')}
              value={watch('name')}
              label="Nome da predefinição"
              error={Boolean(errors.name)}
              message={errors.name?.message}
              required
              color="black4"
              autoFocus
            />
            <Input
              {...register('descr')}
              value={watch('descr')}
              label="Anunciante"
              error={Boolean(errors.descr)}
              message={errors.descr?.message}
              required
              color="black4"
            />
            <LogoDropzone
              {...register('logo')}
              id="dropzone-logo-sponsor"
              height="18rem"
              className="logo-dropzone-create-event"
              label="Logotipo do anunciante"
              required
              color={defaultTheme.colors.black3}
              src={getValues()?.logo}
              defaultImage={watch('logo')}
              handleFileUpload={e => {
                setValue('logo', e);
                setError('logo', { type: 'onChange' });
              }}
              error={errors?.logo?.message}
            />
            <Input
              {...register('url')}
              value={watch('url')}
              label="URL do anúncio"
              error={Boolean(errors.url)}
              message={errors.url?.message}
              required
              color="black4"
            />
            <LogoDropzone
              {...register('banner')}
              id="dropzone-banner-sponsor"
              height="18rem"
              className="banner-dropzone-create-event"
              label="Banner do anúncio"
              required
              color={defaultTheme.colors.black3}
              src={getValues()?.banner}
              defaultImage={watch('banner')}
              handleFileUpload={e => {
                setValue('banner', e);
                setError('banner', { type: 'onChange' });
              }}
              error={errors?.banner?.message}
            />
            <ContentInput>
              <NewSelect
                {...register('label')}
                label="Botão personalizado"
                error={Boolean(errors.label)}
                message={errors.label?.message}
                required
                menuPlacement="auto"
                menuPortalTarget={document.body}
                defaultValue={{ label: watch('label'), value: watch('label') }}
                options={[
                  { label: 'Saiba mais', value: 'Saiba mais' },
                  { label: 'Faça sua aposta', value: 'Faça sua aposta' },
                  { label: 'Compre agora', value: 'Compre agora' },
                  { label: 'Aposte aqui', value: 'Aposte aqui' },
                ]}
                onChange={e => {
                  handleSelect('label', e);
                  setError('label', { type: 'onBlur' });
                }}
                placeholder="Selecione um título para o botão"
              />
            </ContentInput>
            <div>
              <Label required>Cor do botão</Label>
              <CheckboxContainer>
                {colors.map(color => {
                  const colorIsActive = colorActive === color;
                  return (
                    <CheckBoxStyled
                      onClick={() => handleSelectColor(color)}
                      active={colorIsActive}
                    >
                      <CircleFill color={color} active={colorIsActive}>
                        <IconCheck className="icon-check-color" />
                      </CircleFill>
                    </CheckBoxStyled>
                  );
                })}
              </CheckboxContainer>
            </div>
          </Body>
          <Footer>
            <Button
              size="dynamic"
              style={{ width: '100%' }}
              className="btn-template create"
              title="Criar anúncio"
              colorstyle="blue2"
              type="submit"
              onClick={handleSubmit(submitForm)}
            />
            <Button
              size="dynamic"
              style={{ width: '100%' }}
              className="btn-template cancel"
              title="Cancelar"
              colorstyle="black3"
              type="button"
              onClick={handleCloseModal}
            />
          </Footer>
        </ModalContent>
      </ModalContainer>
    </FormProvider>
  ) : null;
};

export default CreateTemplate;
