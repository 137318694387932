import {
  iActionUpdateSocketToken,
  AuthTypes,
} from 'domain/interfaces/redux/auth/UpdateSocketToken';
import { UpdateSocketToken } from 'domain/usecases/auth/redux';

export const updateSocketToken = (
  payload: UpdateSocketToken.Params,
): iActionUpdateSocketToken => ({
  type: AuthTypes.UPDATE_SOCKET_TOKEN,
  payload,
});
