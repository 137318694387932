import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { BadRequestError, NotFound, UnexpectedError } from 'domain/errors';
import { GetDashboard } from 'domain/usecases/dashboard/remote';
import 'infra/global/variables';

export class RemoteGetDashboard implements GetDashboard {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetDashboard.Model>;

  constructor(url: string, httClient: HttpClient<GetDashboard.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (params: GetDashboard.Params): Promise<GetDashboard.Model> => {
    const makeUrlQuery = () => {
      let query = '';
      Object.entries(params.query).forEach(([key, value]) => {
        query += `${key}=${value}&`;
      });
      return query;
    };

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/?${makeUrlQuery()}`,
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return { ...httpResponse.body, currentPage: params.currentPage };
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
