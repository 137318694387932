type HiddenColumnResolution = '1024' | '768';

export interface Column {
  id:
    | 'id'
    | 'name'
    | 'category'
    | 'subCategory'
    | 'time'
    | 'schedule'
    | 'frequency'
    | 'status'
    | 'actions';
  label: string;
  hidden?: boolean;
  style: React.CSSProperties;
}

export const columns: Column[] = [
  {
    id: 'id',
    label: 'ID',
    style: {
      textAlign: 'start',
      maxWidth: '3.3rem',
    },
  },
  {
    id: 'name',
    label: 'Título',
    style: {
      textAlign: 'start',
      width: '20%',
    },
  },
  {
    id: 'category',
    label: 'Categoria',
    style: {
      textAlign: 'start',
      maxWidth: '10rem',
    },
  },
  {
    id: 'subCategory',
    label: 'Subcategoria ',
    style: {
      textAlign: 'start',
      maxWidth: '15rem',
    },
  },
  {
    id: 'schedule',
    label: 'Data',
    style: {
      textAlign: 'start',
      maxWidth: '7.5rem',
    },
  },
  {
    id: 'time',
    label: 'Horário',
    style: {
      textAlign: 'start',
      maxWidth: '15rem',
    },
  },
  {
    id: 'frequency',
    label: 'Frequência',
    style: {
      textAlign: 'start',
      maxWidth: '7.6rem',
    },
  },
  {
    id: 'status',
    label: 'Status',
    style: {
      textAlign: 'start',
      maxWidth: '9rem',
    },
  },
  {
    id: 'actions',
    label: 'Ações',
    style: {
      textAlign: 'start',
      maxWidth: '18rem',
    },
  },
];

export interface Row {
  id: number;
  name: string;
  category: string;
  subCategory: string;
  schedule: string;
  time: string;
  frequency: string;
  status: 'ACTIVE' | 'SCHEDULED' | 'SUSPENDED' | 'CONCLUDED';
}
