import React, { useEffect } from 'react';
import Reactions from '../ReactionsContent';
import { MobileContentContainer } from './styles';

interface MobileContentScreenProps {
  openReactions: boolean;
  reactionsButtons: any;
  setOpenReactions: () => void;
  handleReactionClick?: (reaction: string, reactionId: number) => void;
}

const MobileReactions: React.FC<MobileContentScreenProps> = ({
  openReactions,
  reactionsButtons,
  handleReactionClick,
  setOpenReactions,
}) => {
  return (
    <MobileContentContainer isDisplayed={openReactions}>
      {openReactions && (
        <Reactions
          reactions={reactionsButtons}
          handleReactionsClick={handleReactionClick}
          openReactions={openReactions}
          setOpenReactions={setOpenReactions}
        />
      )}
    </MobileContentContainer>
  );
};

export default MobileReactions;
