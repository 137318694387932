/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-template */
import React, { useCallback, useState } from 'react';
import { NewCloseIcon, IconUserRegisterModal } from 'presentation/base/icons';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import Checkbox from 'presentation/components/Checkbox';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { makeRemoteCreateUser } from 'main/factories/usecases/user/CreateUserFactory';
import { schemaRegisterUser } from 'validation/validators/Register/RegisterUserValidator';
import { ptBR } from 'date-fns/locale';
import { DatePicker } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import {
  ContainerModal,
  ContainerInputs,
  ContentInputs,
  Title,
  TitleContent,
  Body,
  Label,
  GridInputs,
  Agreements,
  LinkToAgreements,
  SendDateTimeOptions,
  Header,
  Content,
  Footer,
  GenreDateDiv,
} from './styles/StyledRegisterModal';

import { Input, Button, Select } from '../UI';
import { Container as GlobalContainer } from '../messages/Background';
import { Message } from '../UI/Input/styles';
import { Calendar } from '../Datepicker';

interface propsRegisterModal {
  message: iMessage;
}

interface iRegisterForm {
  firstName: string;
  lastName: string;
  birthDay: string;
  birthMonth: {
    label: string;
    value: string;
  };
  birthYear: string;
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
}

interface IFormRegisterUser {
  name: string;
  lastName: string;
  email: string;
  userName: string;
  date: Date;
  password: string;
  confirmPassword: string;
}

const RegisterModal: React.FC<propsRegisterModal> = ({ message }) => {
  const msgName = MessageOptions.registerModal;

  const { active, actionOk, actionCancel } = message;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm<IFormRegisterUser>({
    resolver: schemaRegisterUser,
    shouldFocusError: false,
  });

  const [form, setForm] = useState<iRegisterForm>({
    firstName: '',
    lastName: '',
    birthDay: '',
    birthMonth: {
      label: '',
      value: '',
    },
    birthYear: '',
    email: '',
    username: '',
    confirmPassword: '',
    password: '',
    acceptTerms: false,
  });

  const onDateChange = useCallback(
    (date: Date | null) => {
      if (date) setValue('date', date);
    },
    [setValue],
  );

  const handlePolicyExit = useCallback(() => {
    makeReduxActiveMessage().active({
      active: 'registerModal',
      actionOk,
      actionCancel,
    });
  }, [actionCancel, actionOk]);

  const handleChange = useCallback(() => {
    setForm({ ...form, acceptTerms: !form.acceptTerms });
  }, [form]);

  const handlePolicy = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.enterRoomPolicyModal,
      actionAccept: () => {
        setForm({ ...form, acceptTerms: true });
        handlePolicyExit();
      },
      actionCancel: () => {
        setForm({ ...form, acceptTerms: false });
        handlePolicyExit();
      },
    });
  }, [form, handlePolicyExit]);

  const handleLoginModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.loginModal,
      actionOk,
      actionCancel,
    });
  }, [actionCancel, actionOk]);

  // eslint-disable-next-line consistent-return
  const onSubmit = handleSubmit(data => {
    if (!form.acceptTerms)
      return toast.error('Aceite os termos de uso para continuar.');

    const firstName = data.name.split(' ')[0];
    const names = data.name.split(' ');
    const lastName = names[names.length - 1];

    makeRemoteCreateUser()
      .create({
        username: data.userName,
        fullName: data.name,
        firstName,
        lastName,
        email: data.email,
        // phone: '83999999339',
        // cpf: '77777777771',
        birthdate: `${data.date.toISOString()}`,
        password: data.password,
      })
      .then(res => {
        toast.success('Usuário criado com sucesso!');
        reset();
        handleLoginModal();
        // if (actionOk) actionOk();
      })
      .catch(err => {
        toast.error('Erro ao criar usuário!');
      });
  });

  return (
    <>
      {msgName === active ? (
        <GlobalContainer>
          <ContainerModal>
            <Content>
              <Header>
                <TitleContent>
                  <IconUserRegisterModal />
                  <Title>Cadastro</Title>
                </TitleContent>
                <div>
                  <Button
                    id="btn_close"
                    onClick={actionCancel}
                    colorstyle="black2"
                    size="very small"
                    icon={NewCloseIcon}
                  />
                </div>
              </Header>
              <Body onSubmit={onSubmit}>
                <ContainerInputs>
                  <ContentInputs>
                    <Input
                      {...register('name')}
                      id="name"
                      label="Nome completo"
                      error={Boolean(errors?.name)}
                      message={errors?.name?.message}
                      required
                    />
                    <SendDateTimeOptions>
                      <GenreDateDiv>
                        <Select
                          // {...register('month')}
                          label="Gênero"
                          id="month"
                          onChange={async val => {
                            // setValue('month', val.value);
                            // setError('month', { type: 'onBlur' });
                          }}
                          color="black3"
                          placeholder="Selecione"
                          options={[
                            { label: 'Masculino', value: '01' },
                            { label: 'Feminino', value: '02' },
                            { label: 'Outro(a)', value: '03' },
                          ]}
                          required
                        />
                      </GenreDateDiv>
                      <GenreDateDiv>
                        <Calendar
                          label="Data de Nascimento"
                          labelType="small"
                          date={watch('date')}
                          onDateChange={onDateChange}
                          required
                          minimumDate={new Date('1945-01-01')}
                        />
                      </GenreDateDiv>
                    </SendDateTimeOptions>
                    {errors && errors?.date ? (
                      <Message error={Boolean(errors?.date)}>
                        {errors?.date?.message}
                      </Message>
                    ) : (
                      <></>
                    )}

                    <Input
                      {...register('email')}
                      id="email"
                      error={Boolean(errors?.email)}
                      message={errors?.email?.message}
                      label="E-mail"
                      required
                    />
                    <Input
                      {...register('userName')}
                      id="userName"
                      error={Boolean(errors?.userName)}
                      message={errors?.userName?.message}
                      label="Nome do usuário"
                      required
                    />
                    <Input
                      {...register('password')}
                      id="password"
                      error={Boolean(errors?.password)}
                      message={errors?.password?.message}
                      label="Senha"
                      type="password"
                      required
                    />
                    <Input
                      {...register('confirmPassword')}
                      id="confirmPassword"
                      error={Boolean(errors?.confirmPassword)}
                      message={errors?.confirmPassword?.message}
                      label="Confirmar senha"
                      type="password"
                      required
                    />
                  </ContentInputs>
                </ContainerInputs>
                <Agreements>
                  <Checkbox
                    id="checkbox_termsOfService"
                    checked={form.acceptTerms}
                    onClick={handleChange}
                  />
                  <span>
                    Li e concordo com os
                    <LinkToAgreements
                      id="termsOfService"
                      to="/"
                      onClick={handlePolicy}
                    >
                      {' '}
                      Termos de Serviço e Política de Privacidade
                    </LinkToAgreements>{' '}
                    da plataforma Netfans.
                  </span>
                </Agreements>
                <Footer>
                  <Button
                    id="btn_register"
                    type="submit"
                    title="Criar conta"
                    data-testid="button_submit_test"
                    disabled={!form.acceptTerms}
                  />
                </Footer>
              </Body>
            </Content>
          </ContainerModal>
        </GlobalContainer>
      ) : undefined}
    </>
  );
};

export default RegisterModal;
