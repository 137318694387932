import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxGetDashboard } from 'main/factories/usecases/dashboard/GetDashboardFactory';
import { makeReduxDeleteEvent } from 'main/factories/usecases/event/DeleteEventFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import Pagination from 'presentation/components/Pagination';
import { Input } from 'presentation/components/UI';
import { closeModal } from 'utils/modalFunctions';
import ButtonAction from '../ButtonAction';
import { Column, Row, columns } from './mocks';
import {
  Actions,
  ActionsContainer,
  Container,
  ContainerHeaderAction,
  StatusBadge,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowHeader,
  TitleTable,
} from './styles';

const Table: React.FC = () => {
  const { currentPage, loading, pagesCache, totalPages } = useSelector(
    (store: iStore) => store.dashboard,
  );

  return (
    <Container>
      <TableContainer>
        <ContainerHeaderAction>
          <TitleTable>Lista de sessões</TitleTable>
          <Actions>
            <Input placeholder='Pesquisar em "Sessões"' isSearch width="100%" />
          </Actions>
        </ContainerHeaderAction>
        <TableHeader>
          <TableRowHeader>
            {columns.map(column => (
              <TableHeaderCell
                className={column.id}
                key={column.id}
                hidden={column.hidden}
                style={column.style}
              >
                {column.label}
              </TableHeaderCell>
            ))}
          </TableRowHeader>
        </TableHeader>
        <TableBody>
          {pagesCache[currentPage]?.records?.sessions?.map(row => (
            <TableRowCellBody row={row} />
          ))}
        </TableBody>
      </TableContainer>
      <Pagination />
    </Container>
  );
};

const TableRowCellBody: React.FC<{ row: Row }> = ({ row }) => {
  const frequencyMap: Record<string, string> = {
    ONCE: 'Única vez',
  };

  const statusMap: Record<Row['status'], string> = {
    ACTIVE: 'Ativo',
    SUSPENDED: 'Suspenso',
    CONCLUDED: 'Encerrado',
    SCHEDULED: 'Agendado',
  };

  const { user } = useSelector((store: iStore) => store.auth);
  const wrtc = useSelector((store: iStore) => store.wrtc);
  const { currentPage, pagesCache } = useSelector(
    (store: iStore) => store.dashboard,
  );

  const history = useHistory();

  const handleJoinEvent = () => {
    if (!user.email) {
      makeReduxActiveMessage().active({
        active: MessageOptions.loginModal,
        actionCancel: closeModal,
      });
    }

    if (wrtc.disconnect) wrtc.disconnect();

    history.push(`/rooms/${row.id}`);
  };
  const handleEditEvent = () => {
    history.push(`/edit-event/${row.id}`, {
      eventId: row.id,
    });
  };
  const handleSuspendEvent = () => {
    console.log('suspend event');
  };
  const handleDeleteEvent = () => {
    if (pagesCache[currentPage]?.records?.sessions?.length === 1) {
      makeReduxGetDashboard().setCurrentPage(currentPage - 1);
    }

    makeReduxDeleteEvent().delete({
      eventId: row.id,
    });
  };

  const renderCellContent = (column: Column) => {
    switch (column.id) {
      case 'actions':
        return (
          <ActionsContainer>
            <ButtonAction
              actionType="join"
              onClick={handleJoinEvent}
              disabled={row.status !== 'ACTIVE'}
            />
            <ButtonAction
              actionType="edit"
              onClick={handleEditEvent}
              disabled
              // disabled={row.status === 'CONCLUDED'}
            />
            <ButtonAction
              actionType="suspend"
              onClick={handleSuspendEvent}
              disabled
              // disabled={
              //   row.status === 'CONCLUDED' || row.status === 'SCHEDULED'
              // }
            />
            <ButtonAction
              actionType="delete"
              onClick={handleDeleteEvent}
              disabled={row.status === 'ACTIVE'}
            />
          </ActionsContainer>
        );

      case 'id':
        return row[column.id];
      case 'frequency':
        return frequencyMap[row[column.id]];
      case 'schedule':
        return new Date(row[column.id]).toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });

      case 'status':
        return (
          <StatusBadge status={row.status}>{statusMap[row.status]}</StatusBadge>
        );
      default:
        return row[column.id];
    }
  };

  return (
    <TableRow key={row.id}>
      {columns.map(column =>
        column.hidden ? null : (
          <TableBodyCell
            className={column.id}
            key={column.id}
            hidden={column.hidden}
            style={column.style}
          >
            {renderCellContent(column)}
          </TableBodyCell>
        ),
      )}
    </TableRow>
  );
};

export default Table;
