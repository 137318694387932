/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-wrap-multilines */
import { iEventItem } from 'domain/interfaces/models/Event';
import ReactionsContent from 'presentation/components/ReactionsContent';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { iReactions } from 'domain/interfaces/models';
import ClapprComponent from 'presentation/components/Player';
import {
  IconConfiguration,
  IconEmoticon,
  IconMic,
  IconMicMuted,
  IconMoreOption,
  IconRobot,
  IconShare2,
  IconSound,
} from 'presentation/base/icons';

import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { closeModal } from 'utils/modalFunctions';
import { makeRemoteStartWorkers } from 'main/factories/usecases/event/StartWorkersFactory';
import { useLocation, useParams } from 'react-router';
import { convertToWRTCRoomName } from 'utils';
import { TimingObject } from 'services/timingsrc/index.js';
import { Actions } from '../FooterInsideRoom/styles';
import { TooltipComponent } from '../TooltipComponent';
import {
  AboutContainer,
  Box,
  Container,
  Description,
  DescriptionContent,
  IconsContainer,
  VideoContainer,
} from './styles';
import { Chips, ContentChips } from '../MainPage/styles';
import VolumeSliderContent from '../VolumeSliderContent';
import { Button } from '../UI';

interface LivePlataformProps {
  selectedEvent: iEventItem;
  handleMuteMicrophone?: () => void;
  isMicMuted?: boolean;
  openReactions?: boolean;
  setOpenReactions?: (prevState: boolean) => void;
  reactionButtons?: iReactions[];
  handleReactionClick?: (name: string) => void;
  children?: React.ReactNode;
  hidePlayerControls?: boolean;
  showDescription?: boolean;
  eventVolume?: number;
  publicVolume?: number;
  handleEventVolumeChange?: (value: number) => void;
  handlePublicVolumeChange?: (value: number) => void;
  playerRef?: React.MutableRefObject<ReactPlayer | undefined>;
  to?: TimingObject;
}

interface iParams {
  id: string;
  roomId: string;
}

const LivePlataform: React.FC<LivePlataformProps> = ({
  selectedEvent,
  handleMuteMicrophone,
  isMicMuted,
  openReactions,
  setOpenReactions,
  reactionButtons,
  handleReactionClick,
  hidePlayerControls,
  showDescription = false,
  children,
  eventVolume = 100,
  publicVolume = 100,
  handleEventVolumeChange = () => {},
  handlePublicVolumeChange = () => {},
  playerRef,
  to,
}) => {
  const [showVolumeControl, setShowVolumeControl] = useState(false);

  const params: iParams = useParams();
  const location = useLocation();

  const isDemo = location.pathname.includes('demo');

  const handleInviteModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.inviteModal,
      actionCancel: () => closeModal(),
    });
  }, []);

  const handleConfigurationModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.roomsConfigure,
      actionCancel: () => closeModal(),
    });
  }, []);

  const handleAddRobots = useCallback(() => {
    const WRTCRoomName = convertToWRTCRoomName(params?.roomId ?? '');

    makeRemoteStartWorkers()
      .start({
        eventId: selectedEvent.id,
        body: {
          room: WRTCRoomName,
          ttl: 600,
        },
      })
      .then(() => {
        console.log('Robots added');
      })
      .catch(err => {
        console.log('Error adding robots: ', err);
      });
  }, [params?.roomId, selectedEvent.id]);

  const getHeight = useMemo(() => {
    if (!hidePlayerControls && showDescription) {
      return 'calc(100% - 288px)';
    }

    if (children) {
      return 'calc(100% - 84px)';
    }

    return '100%';
  }, [children, hidePlayerControls, showDescription]);

  const handlePlayerRef = useCallback(
    (player: any) => {
      if (playerRef?.current) playerRef.current = player;
    },
    [playerRef],
  );

  const displayRobot = useMemo(() => {
    return selectedEvent?.name?.includes('X2R4');
  }, [selectedEvent?.name]);

  return (
    <Container>
      <div style={{ height: getHeight }}>
        <ClapprComponent
          source={selectedEvent?.external || selectedEvent?.media}
          rounded
          playerRef={handlePlayerRef}
        />
      </div>
      <>
        {!hidePlayerControls ? (
          <IconsContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '16px 16px 8px',
              }}
            >
              <div>
                {children && (
                  <>
                    <AboutContainer hasPlayerControls={!hidePlayerControls}>
                      {children}
                    </AboutContainer>
                  </>
                )}
              </div>

              <div style={{ display: 'flex', gap: 12 }}>
                {handleMuteMicrophone && (
                  <Box
                    onClick={handleMuteMicrophone}
                    isMic
                    btnActive={isMicMuted}
                  >
                    {isMicMuted ? <IconMic /> : <IconMicMuted />}
                  </Box>
                )}

                <Box>
                  <TooltipComponent
                    title="teste"
                    placement="bottom"
                    tooltipType="volume"
                    open={showVolumeControl}
                    titleContent={
                      <VolumeSliderContent
                        eventVolume={eventVolume}
                        handleEventVolumeChange={handleEventVolumeChange}
                        publicVolume={publicVolume}
                        handlePublicVolumeChange={handlePublicVolumeChange}
                        handleClose={() =>
                          setShowVolumeControl(prevState => !prevState)
                        }
                      />
                    }
                  >
                    <Actions
                      onClick={() =>
                        setShowVolumeControl(prevState => !prevState)
                      }
                      id="btn_sound"
                    >
                      <IconSound />
                    </Actions>
                  </TooltipComponent>
                </Box>

                {reactionButtons && setOpenReactions && handleReactionClick && (
                  <Box>
                    <TooltipComponent
                      title="teste"
                      placement="bottom"
                      tooltipType="reactions"
                      open={openReactions}
                      titleContent={
                        <ReactionsContent
                          reactions={reactionButtons}
                          handleReactionsClick={handleReactionClick}
                          openReactions={openReactions}
                          setOpenReactions={() =>
                            setOpenReactions?.(!openReactions)
                          }
                        />
                      }
                    >
                      <Actions
                        onClick={() => setOpenReactions?.(!openReactions)}
                        id="btn_reaction"
                      >
                        <IconEmoticon />
                      </Actions>
                    </TooltipComponent>
                  </Box>
                )}

                {handleMuteMicrophone && (
                  <Box onClick={handleInviteModal}>
                    <IconShare2 />
                  </Box>
                )}

                {handleConfigurationModal && (
                  <Box onClick={handleConfigurationModal}>
                    <IconConfiguration />
                  </Box>
                )}

                {displayRobot && (
                  <Box onClick={handleAddRobots}>
                    <IconRobot />
                  </Box>
                )}

                <Box disabled>
                  <IconMoreOption />
                </Box>

                {isDemo && (
                  <>
                    <Button
                      id="reset"
                      title="Reset"
                      onClick={() => to?.update({ position: 0 })}
                    />
                    <Button
                      id="play"
                      title="Play"
                      onClick={() => to?.update({ velocity: 1 })}
                    />
                    <Button
                      id="pause"
                      title="Pause"
                      onClick={() => to?.update({ velocity: 0 })}
                    />
                    <Button
                      id="reverse"
                      title="Reverse"
                      onClick={() => to?.update({ velocity: -1 })}
                    />
                  </>
                )}
              </div>
            </div>
            {showDescription && (
              <div>
                <div
                  style={{
                    width: '100%',
                    height: 2,
                    backgroundColor: '#3D3B3B',
                    borderRadius: 0,
                    marginTop: 10,
                  }}
                />
                <div style={{ padding: 24 }}>
                  <Description>Descrição</Description>
                  <DescriptionContent>{selectedEvent.descr}</DescriptionContent>
                  <ContentChips style={{ marginTop: 24 }}>
                    {selectedEvent.subCategories?.map(item => (
                      <Chips active={false}>{item.subCategory}</Chips>
                    ))}
                  </ContentChips>
                </div>
              </div>
            )}
          </IconsContainer>
        ) : (
          <AboutContainer>{children}</AboutContainer>
        )}
      </>
    </Container>
  );
};

export default LivePlataform;
