import styled from 'styled-components';
import { defaultTheme } from 'presentation/base/themes';

const iconButtonClassName = 'icon-button';

type ActionButtonType = 'join' | 'edit' | 'suspend' | 'delete';

const iconColorByType: Record<
  ActionButtonType,
  {
    default: keyof typeof defaultTheme.colors;
    hover: keyof typeof defaultTheme.colors;
    focus: keyof typeof defaultTheme.colors;
    active: keyof typeof defaultTheme.colors;
    disabled?: keyof typeof defaultTheme.colors;
  }
> = {
  join: {
    default: 'white2',
    active: 'blue1',
    hover: 'white1',
    focus: 'white1',
  },
  edit: {
    default: 'white2',
    active: 'blue1',
    hover: 'white1',
    focus: 'white1',
  },
  suspend: {
    default: 'red1',
    active: 'red2',
    hover: 'red1',
    focus: 'red2',
  },
  delete: {
    default: 'red1',
    active: 'red2',
    hover: 'red1',
    focus: 'red2',
  },
};

export const ButtonActionStyled = styled.button<{
  actionType: ActionButtonType;
}>`
  border: 0;
  background-color: ${({ theme }) => theme.colors.black2};
  border-radius: 0.5rem;
  min-width: 3.6rem;
  min-height: 3.6rem;

  display: flex;
  justify-content: center;
  align-items: center;

  & svg.${iconButtonClassName} path {
    fill: ${({ theme, actionType }) =>
      theme.colors[iconColorByType[actionType].default]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.black3};
    & svg.${iconButtonClassName} path {
      fill: ${({ theme, actionType }) =>
        theme.colors[iconColorByType[actionType].hover]};
    }
  }

  &:focus-visible {
    outline: none;
    background-color: ${({ theme }) => theme.colors.black3};
    border: 0.2rem solid ${({ theme }) => theme.colors.blue1};
    box-shadow: 0px 0px 1rem ${({ theme }) => theme.colors.blue1};

    & svg.${iconButtonClassName} path {
      fill: ${({ theme, actionType }) =>
        theme.colors[iconColorByType[actionType].focus]};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.black3};
    & svg.${iconButtonClassName} path {
      fill: ${({ theme, actionType }) =>
        theme.colors[iconColorByType[actionType].active]};
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.black1};
    color: ${({ theme }) => theme.colors.white5};

    & svg.${iconButtonClassName} path {
      fill: ${({ theme, actionType }) => theme.colors.white5};
    }
  }
`;
