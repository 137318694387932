import { DashboardTypes } from 'domain/interfaces/redux/dashboard/types';
import { takeLatest } from 'redux-saga/effects';
import { onGet, onGetFailed, onGetSuccess, onSetCurrentPage } from './Get';

const tasks = [
  takeLatest(DashboardTypes.GET, onGet),
  takeLatest(DashboardTypes.GET_SUCCESS, onGetSuccess),
  takeLatest(DashboardTypes.GET_FAILED, onGetFailed),
  takeLatest(DashboardTypes.SET_CURRENT_PAGE, onSetCurrentPage),
];

export default tasks;
