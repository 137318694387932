/* eslint-disable react/react-in-jsx-scope */

import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { IconBackArrow, IconHelp } from 'presentation/base/icons';
import { defaultTheme } from 'presentation/base/themes';
import Header from 'presentation/components/Header';
import { Button } from 'presentation/components/UI';
import ButtonIcon from 'presentation/components/UI/ButtonIcon';
import DashboardPage from 'presentation/pages/Dasboard';
import { makeReduxGetDashboard } from '../../usecases/dashboard/GetDashboardFactory';
import {
  Container,
  SecondaryHeader,
  SecondaryHeaderActions,
  SecondaryHeaderTitle,
} from './styles';

const DashBoardFactory: React.FC = () => {
  const history = useHistory();

  const redirectGoBack = () => {
    history.goBack();
  };

  const loadSessionData = useCallback(() => {
    makeReduxGetDashboard().get({
      currentPage: 1,
      query: {
        limit: 10,
        offset: 0,
      },
    });
  }, []);

  useEffect(() => {
    loadSessionData();

    return () => {
      makeReduxGetDashboard().setCurrentPage(1);
    };
  }, [loadSessionData]);

  return (
    <>
      <Header notFixed />
      <SecondaryHeader>
        <SecondaryHeaderTitle>
          <ButtonIcon onClick={redirectGoBack}>
            <IconBackArrow />
          </ButtonIcon>
          <p className="title">Painel de acompanhamento</p>
        </SecondaryHeaderTitle>
        <SecondaryHeaderActions>
          <Button
            iconSize={20}
            icon={IconHelp}
            size="very small"
            style={{
              padding: '0.8rem',
            }}
            color={defaultTheme.colors.black3}
          />
        </SecondaryHeaderActions>
      </SecondaryHeader>
      <Container>
        <DashboardPage />
      </Container>
    </>
  );
};

export default DashBoardFactory;
