import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 40.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.black2};
  border-radius: 1rem;

  & main[id='modal-first-time-here'] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;
  }
`;

export const HeaderModal = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;

  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
`;

export const LeftHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const RightHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const IconHeaderModal = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 1.2rem;

  background-color: ${({ theme }) => theme.colors.black8};
`;

export const TitleHeaderModal = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.5rem;
  color: ${({ theme }) => theme.colors.white1};
`;

export const BodyModal = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 2.4rem;
  gap: 2rem;
`;

export const BodyTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.18rem;
  color: ${({ theme }) => theme.colors.white1};
`;

export const BodyText = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
  color: ${({ theme }) => theme.colors.white1};

  a {
    color: ${({ theme }) => theme.colors.pink2};
  }
`;

export const BrowserIconsGrid = styled.div`
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1.2rem;

  & button[class*='button-modal-guest-user'] {
    width: 100%;
  }
`;
