import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { iStore } from 'domain/interfaces/models';

import { externalProps, ownProps } from '../index';

export interface iParams {
  id: string;
  roomId: string;
}

const demoParams = {
  id: '36',
  roomId: '1',
};

/**
 * Mapping of the Room.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P & externalProps> {
  const Render: React.FC<P & externalProps> = ({ ...rest }) => {
    const params: iParams = useParams();

    const location = useLocation();

    const wrtc = useSelector((state: iStore) => state.wrtc);
    const { user: userLog, wrtcInfo } = useSelector(
      (store: iStore) => store.auth,
    );
    const { active } = useSelector((state: iStore) => state.message);
    const event = useSelector((state: iStore) => state.event);
    const spotsInfo = useSelector((state: iStore) => state.spotsInfo);

    const isDemo = location.pathname.includes('demo');

    Component.defaultProps = {
      eventId: isDemo ? demoParams.id : params.id,
      roomName: isDemo ? demoParams.roomId : params.roomId,
      wrtc,
      userLog,
      wrtcInfo,
      event,
      active,
      spotsInfo,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps & externalProps)} />;
  };

  return Render;
}
