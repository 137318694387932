import React, { useEffect, useMemo, useState } from 'react';
import { makeRemoteGetEventFriends } from 'main/factories/usecases/event/GetEventFriendsFactory';
import friendsJson from 'data/repository/mockData/friends.json';
import { GetEventFriendItem } from 'domain/usecases/event/remote';
import { makeRemoteCreateTvConnection } from 'main/factories/usecases/tv/CreateTvConnectionFactory';
import { v4 as uuidv4 } from 'uuid';
import { makeRemoteGetTvConnection } from 'main/factories/usecases/tv/GetTvConnectionFactory';
import BaseAnimation from 'presentation/components/BaseAnimation';
import { makeReduxUpdateSocketToken } from 'main/factories/usecases/auth/UpdateSocketTokenFactory';
import Socket from 'infra/socket';
import { SocketEvents } from 'domain/interfaces/socketEvents';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import {
  Container,
  FriendsContainer,
  Highlight,
  HighlightMyself,
  Text,
} from './styles';

const UniqueId = uuidv4();

const FriendsPage: React.FC = () => {
  const [friends, setFriends] = useState<GetEventFriendItem[]>([]);
  const [qrCode, setQrCode] = useState<string>('');
  const [jwt, setJwt] = useState<string>('');
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [reaction, setReaction] = useState<string>('');
  const { accessToken, socketToken } = useSelector(
    (store: iStore) => store.auth,
  );

  const friendsWatching = useMemo(() => {
    if (friends.length === 0) {
      return <Text>Não há amigos assistindo esse evento.</Text>;
    }

    if (friends.length === 1) {
      return (
        <FriendsContainer>
          <HighlightMyself>{friends[0].user.fullname}</HighlightMyself>
          <Text> e </Text>
          <Highlight>{friends[0].friend.fullname}</Highlight>
          <Text> estão assistindo.</Text>
        </FriendsContainer>
      );
    }

    return (
      <FriendsContainer>
        <HighlightMyself>{`${friends[0].user.fullname}, `}</HighlightMyself>
        <Highlight>{friends[0].friend.fullname}</Highlight>
        <Text>{`e mais ${friends.length - 1} pessoas estão assistindo.`}</Text>
      </FriendsContainer>
    );
  }, [friends]);

  useEffect(() => {
    makeRemoteCreateTvConnection()
      .create({
        token: UniqueId,
      })
      .then(res => {
        setQrCode(res.qrCode);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      makeRemoteGetTvConnection()
        .check({
          token: UniqueId,
        })
        .then(res => {
          if (res.jwt) {
            makeReduxUpdateSocketToken().update({
              token: res.jwt,
            });
            setJwt(res.jwt);
            setIsAuthenticated(res.status === 'AUTHENTICATED');
          }
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (jwt)
      interval = setInterval(() => {
        makeRemoteGetEventFriends()
          .list({
            event: friendsJson.roomId,
            token: jwt,
          })
          .then(response => {
            setFriends(response.records);
          });
      }, 5000);

    return () => clearInterval(interval);
  }, [jwt]);

  useEffect(() => {
    const subs = (Socket.getSocket() as any)?.subs;

    const token = accessToken?.length > 0 ? accessToken : socketToken;

    if (token && (subs === undefined || subs.length < 1)) {
      Socket.connect(token);
    }

    if (Socket.getSocket()) {
      Socket.emit({
        event: 'JOIN_TV_TO_EVENT',
        body: { event: friendsJson.roomId },
      });

      Socket.getSocket().on('REACTIONS', (data: SocketEvents) => {
        console.log('>>> Reactions: ', data);

        setReaction(data.reaction.reaction);
        setShowAnimation(true);

        setTimeout(() => {
          setShowAnimation(false);
        }, 5000);
      });
    }
  }, [accessToken, socketToken]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      {isAuthenticated && (
        <Container friends={friends.length}>
          {friendsWatching}
          {showAnimation && (
            <BaseAnimation style={{ position: 'relative', top: -10, left: 8 }}>
              <img src={reaction} alt="reaction" />
            </BaseAnimation>
          )}
        </Container>
      )}

      {!isAuthenticated && qrCode && (
        <img
          src={qrCode}
          alt="QRCode"
          width={64}
          height={64}
          style={{
            objectFit: 'contain',
          }}
        />
      )}
    </div>
  );
};

export default FriendsPage;
