import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { StartWorkers as UsecaseRemoteStartWorkers } from 'domain/usecases/event/remote';

import { RemoteStartWorkers } from 'data/repository/event';

/**
 * send request via API.
 */
export const makeRemoteStartWorkers = (): UsecaseRemoteStartWorkers =>
  new RemoteStartWorkers(
    makeApiUrl('/events/{eventId}/workers'),
    makeHttpClient(),
  );
