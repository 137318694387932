import { makeRemoteGetEventById } from 'main/factories/usecases/event/GetEventById';
import NewEditEvent from 'presentation/components/EditEventForm/NewEditEvent';
import {
  dateOptions,
  time,
} from 'presentation/components/EventsForm/mocks/time';
import Header from 'presentation/components/Header';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { roundTime } from 'utils/formattedDate';
import { Container } from './styles';

const NewEditEventPage: React.FC = () => {
  const [event, setEvent] = useState<any>();
  const [loading, setLoading] = useState(false);

  const params = useParams<{ id: string }>();

  useEffect(() => {
    setLoading(true);

    makeRemoteGetEventById()
      .getById({ id: Number(params?.id) })
      .then(res => {
        console.log('##res: ', res);
        console.log('##res: ', res.eventCategories);
        const multipleCategories = res?.eventCategories?.map(item => {
          console.log('item: ', item);
          return {
            id: item.category.id,
            categoryName: item.category.name,
            name: item.subCategory,
            checked: true,
            content: item.category.parent,
            type: item.type,
          };
        });

        console.log('content: ', multipleCategories?.[0]?.content);

        const categories = res.eventCategories.map(item => {
          console.log('item: ', item);
          return {
            categoryId: item.category.id,
            subCategory: item.subCategory,
            type: item.type,
          };
        });

        // add duration minutes to res.schedule and generate a new date
        const endDate = new Date(res.schedule);
        endDate.setMinutes(
          endDate.getMinutes() + res.duration + endDate.getTimezoneOffset(),
        );

        const startDate = new Date(res.schedule);
        startDate.setMinutes(
          startDate.getMinutes() + startDate.getTimezoneOffset(),
        );

        const labelHourStart = roundTime(
          startDate.getHours(),
          startDate.getMinutes(),
        );

        const labelHourEnd = roundTime(
          endDate.getHours(),
          endDate.getMinutes(),
        );

        const schedulingDates = {
          day: startDate.getDate(),
          month: String(startDate.getMonth() + 1),
          year: String(new Date(res.schedule).getFullYear()),
          hourStart: String(new Date(res.schedule).getHours()),
          hourEnd: String(endDate.toLocaleTimeString().substring(0, 5)),
        };

        const formattedEvent = {
          ...res,
          schedulingDay: schedulingDates.day,
          schedulingMonth: {
            label: dateOptions.find(
              item => item.value === schedulingDates.month,
            )?.label,
            value: schedulingDates.month,
          },
          schedulingYear: schedulingDates.year,
          schedulingHourStart: {
            label: time.find(item => item.label === labelHourStart)?.label,
            value: time.find(item => item.label === labelHourStart)?.value,
          },
          schedulingHourEnd: {
            label:
              res.duration === 0
                ? ''
                : time.find(item => item.label === labelHourEnd)?.label,
            value:
              res.duration === 0
                ? ''
                : time.find(item => item.label === labelHourEnd)?.value,
          },
          description: res.descr,
          eventName: res.name,
          miniature: res.thumbnail,
          banner: res.banner,
          multipleCategories:
            multipleCategories[0]?.content !== null ? multipleCategories : [],
          content: { id: multipleCategories[0]?.content },
          categories,
          category: {
            label: multipleCategories[0]?.categoryName,
            value: multipleCategories[0]?.id,
          },
          subCategory: multipleCategories[0]?.name,
          layoutId: { label: '', value: res.layout?.id },
          media: res.media,
          noForecast: res.duration === 0,
        };
        setEvent(formattedEvent);
      })
      .catch(err =>
        toast.error('Não foi possível carregar os detalhes do evento'),
      )
      .finally(() => setLoading(false));
  }, [params?.id]);

  return (
    <Container>
      <Header />
      {loading ? (
        <div>Loading...</div>
      ) : (
        event && <NewEditEvent event={event} />
      )}
    </Container>
  );
};

export default NewEditEventPage;
