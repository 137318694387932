import styled, { css } from 'styled-components';
import media from 'utils/mediaqueries';

interface RoomContainerProps {
  layout?: 'lounge' | 'cine' | 'theater' | 'arena';
  containerSize?: string; // 'small' | 'medium' | 'large' | 'default'; // | String;
  isRoomsPage?: boolean;
  embed?: boolean;
  isSelected?: boolean;
  robot?: boolean;
}

export const MapContainer = styled.div<RoomContainerProps>`
  width: ${({ containerSize, isRoomsPage }) => {
    if (isRoomsPage) return 'inherit';

    switch (containerSize) {
      case 'smaller':
        return 'none';
      case 'small':
        return '300px';
      case 'medium':
        return '100%';
      case 'large':
        return '720px';
      default:
        return '1080px';
    }
  }};

  /* max-width: 1920px; */
  /* max-width: 1080px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: ${({ isRoomsPage }) => (isRoomsPage ? '732px' : '100%')}; */
  width: 100%;
  height: ${({ containerSize, robot }) => {
    if (robot) return '100%';

    switch (containerSize) {
      case 'small':
        return '90%';
      case 'medium':
        return '90%';
      case 'large':
        return '90%';
      default:
        return '90%';
    }
  }};
  /* margin-top: 6rem; */
  margin-top: ${({ containerSize }) => {
    switch (containerSize) {
      case 'smaller':
        return '0px';
      case 'small':
        return '0px';
      case 'medium':
        return '0px';
      case 'large':
        return '0px';
      default:
        return '0rem';
    }
  }};
  padding: 0
    ${({ containerSize, isRoomsPage }) => {
      if (isRoomsPage) return '0';

      switch (containerSize) {
        case 'smaller':
          return '0rem';
        case 'small':
          return '0rem';
        case 'medium':
          return '1.4rem';
        case 'large':
          return '1.4rem';
        default:
          return '7.4rem';
      }
    }};
  justify-content: start;
  overflow: ${({ isRoomsPage }) => (isRoomsPage ? 'auto' : 'hidden')};

  ${({ layout }) => {
    return layout === 'theater' || layout === 'arena'
      ? css`
          margin: 0;
          .room-grid-container {
            margin: 0;
          }

          .player {
            /* max-width: 575px; */
            max-width: 50rem;
            height: 345px;
            /* padding: 3.3rem; */
            /* padding-bottom: 3rem; */
            background: none;
          }
        `
      : null;
  }}

  /* Alinha a esquerda para scroll horizontal funcionar no layout ARENA, considerar outros layouts */
  ${media.lessThan('1120px')} {
    align-items: ${({ isRoomsPage }) => isRoomsPage && 'flex-start'};
    width: ${({ isRoomsPage, embed }) =>
      isRoomsPage && !embed ? '614px' : '100%'};
    height: 100%;
    overflow: auto;
  }

  /* Alinha a esquerda para scroll vertical funcionar no layout ARENA, considerar outros layouts */
  ${media.lessThan('830px')} {
    justify-content: ${({ isRoomsPage }) => isRoomsPage && 'flex-start'};
  }

  ${media.lessThan('sm')} {
    align-items: ${({ layout }) => layout === 'cine' && 'center'};
    justify-content: ${({ layout }) => layout === 'cine' && 'center'};
    margin-top: 0;
  }

  /* Alinha a esquerda para scroll horizontal funcionar no layout CINE, considerar outros layouts */
  ${media.lessThan('xs')} {
    align-items: ${({ isRoomsPage }) => isRoomsPage && 'flex-start'};
  }
`;

export const MapTabContainer = styled.div<RoomContainerProps>`
  background: #252424;
  color: #fbfbfb;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

export const MapBodyContainer = styled.div`
  background: #1e1e1e;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const ChooseRoomsContainer = styled.div<RoomContainerProps>`
  display: grid;
  grid-template-columns: 30px 1fr 1fr 1fr 30px;
  grid-template-rows: 30px 1fr 1fr 1fr 30px;
  /*  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr; */
  max-height: 300px;
  height: 100%;
  width: 100%;
  gap: 24px;
  padding: 0px;

  margin-bottom: ${({ containerSize }) => {
    switch (containerSize) {
      case 'smaller':
        return '0rem';
      case 'small':
        return '0rem';
      default:
        return '4rem';
    }
  }};
`;

export const RoomContainer = styled.div<RoomContainerProps>`
  position: relative;
  padding: 24px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.black2};
  border: 1px solid
    ${props =>
      props.isSelected ? props.theme.colors.white5 : props.theme.colors.black3};
  color: ${({ theme }) => theme.colors.white5};
  cursor: ${props => (props.isSelected ? 'default' : 'pointer')};

  .roomname {
    position: absolute;
    display: flex;
    justify-content: center;
    width: ${({ robot }) => (robot ? '100px' : '75px')};
    z-index: 0;
    background: linear-gradient(
      0deg,
      rgba(37, 36, 36, 1) 50%,
      rgba(30, 30, 30, 1) 50%
    );
    right: ${({ robot }) => (robot ? 'calc(50% - 50px)' : 'calc(50% - 33px)')};
    top: -12px;
    color: ${props =>
      props.isSelected ? props.theme.colors.white2 : props.theme.colors.white1};
    font-weight: ${props => (props.isSelected ? 600 : 400)};
  }

  &:hover {
    .square {
      background-color: ${({ theme }) => theme.colors.black3};
      border-color: ${({ theme }) => theme.colors.white5};
    }

    .square-selected {
      background-color: ${({ theme }) => theme.colors.white5};
      border-color: ${({ theme }) => theme.colors.white3};
    }
  }
`;
