/* eslint-disable react/react-in-jsx-scope */
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxLogin } from 'main/factories/usecases/auth/LoginFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import {
  IconAlert,
  IconEdgeBrowser,
  IconChromeBrowser,
  IconFirefoxBrowser,
  IconSafariBrowser,
  IconOperaBrowser,
  CloseIcon,
} from 'presentation/base/icons';
import { closeModal } from 'utils/modalFunctions';
import { Fade, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, Input } from '../../UI';
import { ModalContainer } from '../styles';
import {
  BodyModal,
  BodyText,
  BodyTitle,
  ButtonContainer,
  Content,
  HeaderModal,
  IconHeaderModal,
  TitleHeaderModal,
  BrowserIconsGrid,
  LeftHeaderContainer,
  RightHeaderContainer,
} from './styles';
// import { schemaBrowserWarningModal } from './validator';

interface props {
  message: iMessage;
}

const BrowserWarningModal: React.FC<props> = ({ message }) => {
  const { active: actualMessageActive, actionCancel } = message;
  const messageName = MessageOptions.browserWarningModal;
  const [isFirefox, setIsFirefox] = useState(false);

  const isActive = actualMessageActive === messageName;

  // render conditionaly the modal content if the browser is mozila firefox
  useEffect(() => {
    const isFirefoxBrowser =
      navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (isFirefoxBrowser) {
      setIsFirefox(true);
    }
  }, []);

  const closeModalAndSaveTimestamp = () => {
    const timestamp = new Date().getTime();
    sessionStorage.setItem('browserWarningModal', timestamp.toString());
    closeModal();
  };

  return isActive ? (
    <ModalContainer>
      <Content>
        <HeaderModal>
          <LeftHeaderContainer>
            <IconHeaderModal>
              <IconAlert />
            </IconHeaderModal>
            <TitleHeaderModal>Alerta</TitleHeaderModal>
          </LeftHeaderContainer>
          <RightHeaderContainer>
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={closeModalAndSaveTimestamp}
            />
          </RightHeaderContainer>
        </HeaderModal>
        <BodyModal>
          <BodyTitle>Seu navegador não é suportado.</BodyTitle>
          <BodyText>
            Este navegador não é compatível com os recursos de cancelamento de
            eco. Isso significa que haverá ruído sempre que você habilitar o
            microfone.
          </BodyText>
          {isFirefox && (
            <>
              <BodyText>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <b>Atenção: </b>O Mozila Firefox pode apresentar instabilidade
                devido incopatibilidade com nosso algoritimo de tratamento de
                áudio.
              </BodyText>
            </>
          )}
          <BodyText>
            Para ter uma melhor experiência no Netfans, recomendamos que use um
            destes navegadores:
          </BodyText>

          <BrowserIconsGrid>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              enterDelay={200}
              leaveDelay={100}
              placement="top"
              title={
                <h5 style={{ fontSize: '1rem' }}>Download Microsoft Edge</h5>
              }
            >
              <IconEdgeBrowser
                onClick={() => {
                  window.open('https://www.microsoft.com/edge/', '_blank');
                }}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              enterDelay={100}
              leaveDelay={100}
              placement="top"
              title={
                <h5 style={{ fontSize: '1rem' }}>Download Google Chrome</h5>
              }
            >
              <IconChromeBrowser
                onClick={() => {
                  window.open('https://www.google.com/chrome/', '_blank');
                }}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
            <IconFirefoxBrowser style={{ cursor: 'not-allowed' }} />
            <IconSafariBrowser style={{ cursor: 'not-allowed' }} />
            <IconOperaBrowser style={{ cursor: 'not-allowed' }} />
          </BrowserIconsGrid>
        </BodyModal>
      </Content>
    </ModalContainer>
  ) : null;
};

export default BrowserWarningModal;
