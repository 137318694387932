import styled from 'styled-components';

interface ContainerProps {
  friends: number;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: ${({ friends }) => {
    if (friends === 0) return '326px';
    if (friends === 1) return '396px';
    return '496px';
  }};
  height: 60px;
  padding: 16px;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.black2};
`;

export const FriendsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  white-space: nowrap;
  gap: 4px;
`;

export const HighlightMyself = styled.h5`
  font: ${({ theme }) => theme.typography.h5};
  color: ${({ theme }) => theme.colors.white1};
  font-weight: bold;
  text-decoration: underline;
  margin: 0;
`;

export const Highlight = styled.h5`
  font: ${({ theme }) => theme.typography.h5};
  color: ${({ theme }) => theme.colors.white1};
  font-weight: bold;
  margin: 0;
`;

export const Text = styled.h6`
  font: ${({ theme }) => theme.typography.h6};
  color: ${({ theme }) => theme.colors.white3};
  font-weight: 400;
  margin: 0;
`;
