import styled, { css } from 'styled-components';
import { defaultTheme } from 'presentation/base/themes';
import { Row } from './mocks';

export const Container = styled.div`
  width: 100%;
  height: 67.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.black2};
  margin: 1.6rem 0;
  border-radius: 1rem;
`;

export const ContainerHeaderAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 1.6rem;
  padding: 1.6rem 2.4rem;

  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
`;

export const Actions = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  gap: 1.2rem;
`;

export const TitleTable = styled.h2`
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: ${({ theme }) => theme.colors.white2};
`;

export const TableContainer = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
`;

export const TableHeader = styled.thead`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.6rem;

  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
`;
export const TableRow = styled.tr`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1.6rem 2.4rem;

  gap: 1.6rem;

  background-color: ${({ theme }) => theme.colors.black5};

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
  }

  @media (max-width: 1366px) {
    & .category,
    & .subCategory {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    & .category,
    & .subCategory,
    & .frequency {
      display: none;
    }
  }
`;

export const TableRowHeader = styled(TableRow).attrs({
  as: 'tr',
})`
  padding: 1.6rem 2.4rem;
  background-color: ${({ theme }) => theme.colors.black2};
`;

export const TableBody = styled.tbody`
  & ${TableRow}:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.black7};
  }
`;

const tableCell = css`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-align: left;

  color: ${({ theme }) => theme.colors.white2};

  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  width: 100%;

  @media (max-width: 1024px) {
    gap: 0.5rem;
    &:nth-child(3) {
      display: none;
    }
    &:nth-child(4) {
      display: none;
    }
  }
`;

export const TableBodyCell = styled.td`
  ${tableCell}
`;

export const TableHeaderCell = styled.th`
  ${tableCell}
  font-weight: 600;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 1.2rem;
`;

const colorByStatus: Record<
  Row['status'],
  keyof typeof defaultTheme['colors']
> = {
  ACTIVE: 'green1',
  SCHEDULED: 'blue1',
  SUSPENDED: 'red2',
  CONCLUDED: 'white3',
};

type StatusBadgeProps = {
  status: Row['status'];
};

export const StatusBadge = styled.div<StatusBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  text-overflow: ellipsis;
  white-space: nowrap;

  &:before {
    content: '';
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 0.8rem;

    background-color: ${({ theme, status }) =>
      theme.colors[colorByStatus[status]]};
  }
`;
