import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateTvConnection as UsecaseRemoteCreateTvConnection } from 'domain/usecases/tv/remote';

import { RemoteCreateTvConnection } from 'data/repository/tv';

/**
 * send request via API.
 */
export const makeRemoteCreateTvConnection =
  (): UsecaseRemoteCreateTvConnection =>
    new RemoteCreateTvConnection(
      makeApiUrl('/tv/connection'),
      makeHttpClient(),
    );
