import React from 'react';
import { Container, FloatButtonLabel } from './styles';
import { FloatButtonProps } from './types';

const FloatButton: React.FC<FloatButtonProps> = ({
  children,
  icon: Icon,
  ...rest
}) => {
  return (
    <Container {...rest} onClick={rest.action}>
      <Icon className="icon-float-button" />
      <FloatButtonLabel>{children}</FloatButtonLabel>
    </Container>
  );
};

export default FloatButton;
