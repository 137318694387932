/* eslint-disable react/react-in-jsx-scope */
import { ButtonContainer } from './styles';

interface ButtonIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}
/**
 * @param children receive an icon as a children
 * @param rest receive all props from button element
 */
const ButtonIcon: React.FC<ButtonIconProps> = ({ children, ...rest }) => {
  return <ButtonContainer {...rest}>{children}</ButtonContainer>;
};

export default ButtonIcon;
