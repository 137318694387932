import styled, { keyframes } from 'styled-components';

const animateModal = keyframes`
  from {
    opacity: 0;
    transform: translateY(1.2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 67.2rem;
  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.black2};
  border-radius: 1rem;

  text-align: center;
  animation: ${animateModal} 0.3s ease-in-out;

  & main[id='modal-first-time-here'] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;
  }
`;

export const Title = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white2};
  margin-bottom: 0.4rem;
`;

export const Description = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white1};
  margin-bottom: 2.4rem;
`;

export const ButtonIcon = styled.div`
  width: 3.6rem;
  height: 3.6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.4rem;
  background: ${({ theme }) => theme.colors.black3};
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonTitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 700;
`;

export const ButtonText = styled.span`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;
  text-align: center;

  max-width: 16rem;

  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;

  color: ${({ theme }) => theme.colors.white1};
`;

export const ButtonAction = styled.button`
  width: 100%;
  max-width: 20rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 0.1rem solid ${({ theme }) => theme.colors.black3};
  background: ${({ theme }) => theme.colors.black9};

  padding: 2rem;
  gap: 1.2rem;
  color: ${({ theme }) => theme.colors.white2};

  border-radius: 0.5rem;

  &:not(:first-child) {
    margin-left: 1.2rem;
  }

  &:hover:not(:disabled):not(:active) {
    background: ${({ theme }) => theme.colors.black3};
    border-color: ${({ theme }) => theme.colors.white5};

    ${ButtonIcon} {
      background: ${({ theme }) => theme.colors.white5};
      svg path {
        fill: ${({ theme }) => theme.colors.white2};
      }
    }

    ${ButtonText} {
      color: ${({ theme }) => theme.colors.white2};
    }
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.black1};
    border-color: ${({ theme }) => theme.colors.black3};

    ${ButtonIcon} {
      background: ${({ theme }) => theme.colors.black1};
      svg path {
        fill: ${({ theme }) => theme.colors.white5};
      }
    }

    ${ButtonText}, ${ButtonTitle} {
      color: ${({ theme }) => theme.colors.white3};
    }
  }

  &:focus-visible {
    outline: 0.2rem solid ${({ theme }) => theme.colors.blue1};
    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};

    ${ButtonIcon} {
      background: ${({ theme }) => theme.colors.black3};
      svg path {
        fill: ${({ theme }) => theme.colors.white1};
      }
    }
  }

  &:active:not(:disabled) {
    outline: none;
    box-shadow: none;

    transition: all 0s ease-in;

    background: ${({ theme }) => theme.colors.black2};
    border-color: ${({ theme }) => theme.colors.black3};

    ${ButtonText}, ${ButtonTitle} {
      color: ${({ theme }) => theme.colors.blue1};
    }

    ${ButtonIcon} {
      background: ${({ theme }) => theme.colors.black3};
      svg path {
        fill: ${({ theme }) => theme.colors.blue1};
      }
    }
  }
`;
