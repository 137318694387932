import React, { useEffect, useState } from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import MessagesPublicChatProvider from 'presentation/contexts/MessagesPublicChatContext';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactNotifications } from 'react-notifications-component';
import AccessControl from './presentation/hooks/access';

import store from './data/store';
import translations from './infra/i18n/locales';
import { Routes } from './main/routes';
import { GlobalStyle } from './presentation/base/styles/global';
import Notifications from './presentation/components/messages';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-notifications-component/dist/theme.css';

import './infra/global/variables';
import { AudioDeviceConfigureProvider } from './presentation/contexts/AudioDeviceConfigureContext';
import { ThemeProviderNetfans } from './presentation/contexts/themeContext';
import { makeReduxActiveMessage } from './main/factories/usecases/message/UpdateFactory';
import { MessageOptions } from './domain/interfaces/redux/message';
import { closeModal } from './utils/modalFunctions';
import { ConnectSocket } from './infra/socket/ConnectSocket';

const persistor = persistStore(store);

export interface iSupportedLanguages {
  language:
    | 'pt-BR'
    | 'pt-br'
    | 'en-US'
    | 'en-us'
    | 'es'
    | 'es-ES'
    | 'es-es'
    | 'es-DO'
    | 'es-do'
    | 'es-GT'
    | 'es-gt'
    | 'es-HN'
    | 'es-hn'
    | 'es-MX'
    | 'es-mx'
    | 'es-PA'
    | 'es-pa'
    | 'es-PE'
    | 'es-pe'
    | 'es-SV'
    | 'es-SE'
    | 'es-sv'
    | 'es-se';
}

const App: React.FC = () => {
  const [selectedLocale, setSelectedLocale] =
    useState<iSupportedLanguages['language']>('pt-BR');

  const [connectToken, setConnectToken] = useState<string | undefined>();

  useEffect(() => {
    let userLanguage = localStorage.getItem('@netfans/react-intl');

    if (!userLanguage) {
      userLanguage = navigator.language;
      localStorage.setItem('@netfans/react-intl', userLanguage);
    }

    setSelectedLocale(userLanguage as iSupportedLanguages['language']);
  }, []);

  useEffect(() => {
    const isChrome = /Chrome/i.test(navigator.userAgent);
    const isEdge = /Edge/i.test(navigator.userAgent);

    if (isChrome || isEdge) {
      return;
    }

    const browserWarningModal = sessionStorage.getItem('browserWarningModal');
    const now = new Date().getTime();
    const cooldownMinutes = 10;
    const cooldown = 1000 * 60 * cooldownMinutes;

    if (browserWarningModal) {
      if (Number(browserWarningModal) + cooldown > now) {
        const timePassed = Math.floor(
          (now - Number(browserWarningModal)) / 1000 / 60,
        );
        console.info(
          `Browser Warning Modal ${timePassed}/${cooldownMinutes}min Cooldown`,
        );
        return;
      }
    }
    makeReduxActiveMessage().active({
      active: MessageOptions.browserWarningModal,
      actionCancel: () => closeModal(),
    });
  }, []);

  useEffect(() => {
    const unsub = store.subscribe(() => {
      const { accessToken, socketToken } = store.getState().auth;

      const token = accessToken?.length > 0 ? accessToken : socketToken;

      setConnectToken(token);
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    <IntlProvider
      messages={translations[selectedLocale]}
      locale={String(selectedLocale)}
      defaultLocale="pt-BR"
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AccessControl>
            <ConnectSocket token={connectToken}>
              <BrowserRouter>
                <AudioDeviceConfigureProvider>
                  <ThemeProviderNetfans>
                    <MessagesPublicChatProvider>
                      {/* <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}> */}
                      <Notifications>
                        <div className="App">
                          <Switch>
                            <Routes />
                          </Switch>
                          <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            closeOnClick
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                          <GlobalStyle />
                          <ReactNotifications />
                        </div>
                      </Notifications>
                      {/* </ThemeProvider> */}
                    </MessagesPublicChatProvider>
                  </ThemeProviderNetfans>
                </AudioDeviceConfigureProvider>
              </BrowserRouter>
            </ConnectSocket>
          </AccessControl>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
};

export default App;
