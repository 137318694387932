/* eslint-disable react/react-in-jsx-scope */
import React, { createContext, useCallback, useContext, useState } from 'react';

interface VolumeContextProps {
  volumePlayer: number;
  audienceVolume: number;
  playerRef: React.RefObject<HTMLVideoElement>;
  setVolumePlayer: (volume: number) => void;
  setAudienceVolume: (volume: number) => void;
}

interface VolumeProviderProps {
  playerRef: React.RefObject<HTMLVideoElement>;
}

export const VolumeContext = createContext<VolumeContextProps>(
  {} as VolumeContextProps,
);

const VolumeProvider: React.FC<VolumeProviderProps> = ({
  playerRef,
  children,
}) => {
  const [volumePlayer, setVolumePlayerState] = useState(50);
  const [audienceVolume, setAudienceVolumeState] = useState(100);

  const setVolumePlayer = useCallback(
    (value: number) => {
      setVolumePlayerState(value);
      if (playerRef?.current?.volume) {
        playerRef.current.volume = value / 100;
      }
    },
    [playerRef],
  );

  const setAudienceVolume = useCallback((value: number) => {
    const audios = document.getElementById('audioContainer')?.children;

    setAudienceVolumeState(value);

    if (audios) {
      Array.from(audios).forEach((audio: Element) => {
        const audioElement = audio as HTMLAudioElement;
        audioElement.volume = value / 100;
      });
    }
  }, []);

  return (
    <VolumeContext.Provider
      value={{
        playerRef,
        volumePlayer,
        audienceVolume,
        setVolumePlayer,
        setAudienceVolume,
      }}
    >
      {children}
    </VolumeContext.Provider>
  );
};

const useVolume = (): VolumeContextProps => {
  const context = useContext(VolumeContext);

  if (!context) {
    throw new Error('useVolume must be used within an VolumeProvider');
  }

  return context;
};

export { VolumeProvider, useVolume };
