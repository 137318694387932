/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios';
import { IconUploader } from 'presentation/base/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DropzoneInputProps as ReactDropzoneProps,
  useDropzone,
} from 'react-dropzone';
import { DropContainer, DropInput, DropText, Label, Message } from './styles';

interface DropzoneProps extends ReactDropzoneProps {
  id?: string;
  label?: string;
  handleFileUpload: (file: any) => void;
  fixedHeight?: boolean;
  defaultImage?: string;
  error?: string;
}

const LogoDropzone: React.FC<DropzoneProps> = ({
  id,
  label,
  defaultImage,
  handleFileUpload,
  fixedHeight,
  error,
  accept,
  ...rest
}) => {
  const [file, setFile] = useState<(File & { preview: string })[]>([]);

  useEffect(() => {
    if (!defaultImage) {
      setFile([]);
      return;
    }

    if (typeof defaultImage === 'string') {
      axios
        .get(`${defaultImage}`, {
          headers: { 'Content-Type': 'application/octet-stream' },
          responseType: 'blob',
        })
        .then(res => {
          const value = new File([res.data], 'defaultImage.png', {
            type: 'image/png',
          });
          const updatedFileValue = Object.assign(value, {
            preview: defaultImage,
          });

          setFile([updatedFileValue]);
        });
    } else {
      setFile([defaultImage]);
    }
  }, [defaultImage]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const updatedFile = acceptedFiles.map((file: File) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setFile(updatedFile);
      handleFileUpload(acceptedFiles[0]);
    },
    [handleFileUpload],
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const files = useMemo(
    () =>
      file.map((fileItem: File & { preview: string }) => (
        <div key={fileItem.name}>
          <img src={fileItem.preview} />
        </div>
      )),
    [file],
  );

  const { getRootProps, getInputProps, inputRef, isFileDialogActive } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: accept ?? ['image/jpeg', 'image/png'],
      maxFiles: 1,
    });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Label htmlFor={id} required={rest.required}>
        {label}
      </Label>
      <DropContainer
        {...rest}
        id={id}
        htmlFor={id}
        {...getRootProps()}
        height={rest.height ?? '26rem'}
        fixedHeight={fixedHeight}
        error={!!error}
      >
        <DropInput
          isFileDialogActive={isFileDialogActive}
          ref={inputRef}
          {...getInputProps()}
        />

        {files.length > 0 ? (
          files[0]
        ) : (
          <>
            <IconUploader className="icon-upload-file" />
            <DropText>
              <p>
                Escolha um arquivo
                <br />
                ou arraste-o aqui
              </p>
            </DropText>
          </>
        )}
      </DropContainer>
      <Message error={!!error}>{error}</Message>
    </div>
  );
};

export default LogoDropzone;
