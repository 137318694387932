/* eslint-disable react/react-in-jsx-scope */
import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { IconPlus } from 'presentation/base/icons';
import bannerBeta from 'presentation/base/images/bannerBeta.png';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { templateDefault } from 'services/mocks';
import { closeModal } from 'utils/modalFunctions';
import InfoEvent from '../InfoEvent';
import { NewSelect } from '../UI';
import ButtonToAddNewTemplate from './ButtonToAddNewTemplate';
import { EventContent } from './NewEventStyled';
import { BoxToPreviewTemplate } from './styles/PublicityStyled';
import { HeaderContent } from './styles/common';

// import { Container } from './styles';

const Publicity: React.FC = () => {
  const { sponsor } = useSelector((store: iStore) => store);
  const [template, setTemplate] = useState(
    sponsor.sponsors.find(
      item => item.id === 32 && item.name === 'Netfans (Padrão)',
    ),
  );
  const [isLoadingTemplateDefault, setIsLoadingTemplateDefault] =
    useState(true);

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const sponsorData = watch('sponsor');

  const optionsTemplate = useMemo(() => {
    return [
      ...(sponsor.sponsors.map(sponsorItem => ({
        label: sponsorItem.name,
        value: sponsorItem.id,
      })) ?? []),
    ];
  }, [sponsor.sponsors]);

  const handleSelectTemplateDefault = useCallback(() => {
    const { sponsors } = sponsor;

    const templateDefaultSelected = sponsors.find(
      templateItem => templateItem.default,
    );

    if (templateDefaultSelected) {
      setTemplate({
        ...templateDefaultSelected,
        logo: templateDefaultSelected.logo ?? bannerBeta,
      });
      setValue('sponsor', templateDefaultSelected.id);
    }
    setIsLoadingTemplateDefault(false);
  }, [setValue, sponsor]);

  const openNewTemplateModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.createTemplateToSponsor,
      actionCancel: closeModal,
    });
  }, []);

  const handleSelectTemplate = useCallback(
    (option: { label: string; value: number }) => {
      const templateSelected = sponsor.sponsors.find(
        sponsorItem => sponsorItem.id === option.value,
      );
      if (templateSelected) {
        setTemplate({
          ...templateSelected,
          logo: templateSelected.logo ?? bannerBeta,
        });
        setValue('sponsor', option.value);
      } else {
        handleSelectTemplateDefault();
      }
    },
    [sponsor.sponsors, setValue, handleSelectTemplateDefault],
  );

  useEffect(() => {
    if (sponsorData === undefined) {
      handleSelectTemplateDefault();
    }
  }, [
    handleSelectTemplate,
    handleSelectTemplateDefault,
    optionsTemplate,
    sponsorData,
  ]);

  return (
    <div>
      <HeaderContent>3. Anúncios</HeaderContent>
      <EventContent>
        <div className="form">
          <NewSelect
            {...register('sponsor')}
            label="Predefinição"
            placeholder="Escolha um anúncio"
            required
            isLoading={sponsor.loading || isLoadingTemplateDefault}
            error={errors.sponsor}
            message={errors.sponsor?.message}
            options={optionsTemplate}
            buttonOnSelect={() => (
              <ButtonToAddNewTemplate
                label="Criar novo anúncio"
                onClick={openNewTemplateModal}
                icon={<IconPlus />}
              />
            )}
            onChange={value => {
              handleSelectTemplate(value);
              setValue('sponsor', value.value);
            }}
            defaultValue={{
              ...templateDefault,
              value: templateDefault.id,
              label: templateDefault.name,
            }}
            value={{
              ...template,
              value: template?.id ?? 32,
              label: template?.name ?? 'Netfans (Padrão)',
            }}
          />
          <BoxToPreviewTemplate>
            <InfoEvent template={template} />
          </BoxToPreviewTemplate>
        </div>
      </EventContent>
    </div>
  );
};

export default Publicity;
