import { iStore } from 'domain/interfaces/models';
import { iAnimation } from 'presentation/pages/Room/interface';
import { RoomGridContainer } from 'presentation/pages/Room/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import GridRoom from '../GridRoom';
import { layoutsProps } from './types';

// containerSize and Size need to be the same

interface iParams {
  id: string;
  roomId: string;
}
interface iRoom {
  name: string;
  occup: number;
}

const LoungeLayout: React.FC<layoutsProps> = ({
  rooms,
  size,
  handleClick,
  setTriggerAnimation,
  speakingPeerIds,
  triggerAnimation,
  isHovering = false,
  isChangingRoom = false,
  preview,
}) => {
  const params: iParams = useParams();
  const [roomInfo, setRoomInfo] = useState<iRoom[]>();
  const { wrtc } = useSelector((state: iStore) => state);

  useEffect(() => {
    const floorToSearch = `floor_${params.id}`;
    setTimeout(() => {
      const onRequestAllFloorsInfosSuccess = (data: any) => {
        setRoomInfo(data?.rooms);
      };
      if (wrtc.requestRoomsOccupancies)
        wrtc.requestRoomsOccupancies(
          floorToSearch,
          0,
          onRequestAllFloorsInfosSuccess,
        );
    }, 10000);
  }, [params.id, wrtc]);

  const getGrid = useMemo(() => {
    return (
      <div>
        <RoomGridContainer>
          <GridRoom
            isLayout
            numCol={6}
            rooms={rooms.slice(0, 6)}
            handleClick={handleClick}
            size={size}
            preview={preview}
            speakingPeerIds={speakingPeerIds}
            animation={triggerAnimation}
            isHovering={isHovering}
            isChangingRoom={isChangingRoom}
            handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
          />
        </RoomGridContainer>
        <RoomGridContainer>
          <GridRoom
            isLayout
            numCol={6}
            rooms={rooms.slice(6, 12)}
            handleClick={handleClick}
            size={size}
            preview={preview}
            speakingPeerIds={speakingPeerIds}
            animation={triggerAnimation}
            isHovering={isHovering}
            isChangingRoom={isChangingRoom}
            handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
          />
        </RoomGridContainer>
        <RoomGridContainer>
          <GridRoom
            isLayout
            numCol={6}
            rooms={rooms.slice(12, 18)}
            handleClick={handleClick}
            size={size}
            preview={preview}
            speakingPeerIds={speakingPeerIds}
            animation={triggerAnimation}
            isHovering={isHovering}
            isChangingRoom={isChangingRoom}
            handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
          />
        </RoomGridContainer>
        <RoomGridContainer>
          <GridRoom
            isLayout
            numCol={6}
            rooms={rooms.slice(18, 24)}
            handleClick={handleClick}
            size={size}
            preview={preview}
            speakingPeerIds={speakingPeerIds}
            animation={triggerAnimation}
            isHovering={isHovering}
            isChangingRoom={isChangingRoom}
            handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
          />
        </RoomGridContainer>
      </div>
    );
  }, [
    handleClick,
    isChangingRoom,
    isHovering,
    preview,
    rooms,
    setTriggerAnimation,
    size,
    speakingPeerIds,
    triggerAnimation,
  ]);

  return getGrid;
};

export default LoungeLayout;
