/* eslint-disable react/react-in-jsx-scope */
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { iMessage, iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { IconAddUser, IconGuestUser, IconKey } from 'presentation/base/icons';
import { useLocation } from 'react-router';
import Checkbox from '../../Checkbox';
import { ModalContainer } from '../styles';
import { exceptionsRedirect, hideGuest } from './constants';
import {
  ButtonAction,
  ButtonContent,
  ButtonIcon,
  ButtonText,
  ButtonTitle,
  Content,
  Description,
  Title,
} from './styles';

interface props {
  message: iMessage;
}

type options = 'guest' | 'login' | 'signUp';

const FirstTimeHere: React.FC<props> = ({ message }) => {
  const { active: actualMessageActive, actionCancel } = message;
  const messageName = MessageOptions.firstTimeHere;

  const isActive = actualMessageActive === messageName;
  const location = useLocation();
  const { accessToken } = useSelector((store: iStore) => store.auth);

  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [timesShowed, setTimesShowed] = useState(0);

  const avoidModal = useMemo(
    () => exceptionsRedirect.some(item => location.pathname.includes(item)),
    [location],
  );

  const hideGuestModal = useMemo(
    () => hideGuest.some(item => location.pathname.includes(item)),
    [location],
  );

  const backToSelectAnotherOption = useCallback(() => {
    makeReduxActiveMessage().active({
      active: messageName,
      actionCancel,
    });
  }, [actionCancel, messageName]);

  const saveChoiceOnCookies = useCallback(() => {
    const expires = 60 * 60 * 24 * 30; // 30 days
    const dataToSave = JSON.stringify(doNotShowAgain);
    Cookies.set('doNotShowModalFirstTimeAgain', dataToSave, {
      expires: doNotShowAgain ? expires : undefined,
      path: '/',
    });
  }, [doNotShowAgain]);

  const cookies = Cookies.get();
  const doNotShowModalFirstTimeAgain = JSON.parse(
    cookies.doNotShowModalFirstTimeAgain ?? 'false',
  );

  const handleSelectOption = (option: options) => {
    const messagesModal: Record<options, iMessage['active']> = {
      guest: MessageOptions.guestLogin,
      login: MessageOptions.loginModal,
      signUp: MessageOptions.registerModal,
    };

    makeReduxActiveMessage().active({
      active: messagesModal[option],
      actionCancel: backToSelectAnotherOption,
      actionOk: saveChoiceOnCookies,
    });
  };

  useEffect(() => {
    if (timesShowed === 0 && !doNotShowModalFirstTimeAgain && !accessToken) {
      makeReduxActiveMessage().active({
        active: messageName,
        actionCancel,
      });

      setTimesShowed(timesShowed + 1);
    }
  }, [
    accessToken,
    actionCancel,
    doNotShowModalFirstTimeAgain,
    messageName,
    timesShowed,
  ]);

  return isActive && !avoidModal ? (
    <ModalContainer>
      <Content>
        <header>
          <Title>Primeira vez por aqui?</Title>
          <Description>Escolha como quer entrar no netfans</Description>
        </header>
        <main id="modal-first-time-here">
          {!hideGuestModal && (
            <ButtonAction onClick={() => handleSelectOption('guest')}>
              <ButtonIcon>
                <IconGuestUser />
              </ButtonIcon>
              <ButtonContent>
                <ButtonTitle>Convidado</ButtonTitle>
                <ButtonText>
                  Acesse o Netfans como um usuário anônimo
                </ButtonText>
              </ButtonContent>
            </ButtonAction>
          )}
          <ButtonAction onClick={() => handleSelectOption('login')}>
            <ButtonIcon>
              <IconKey />
            </ButtonIcon>
            <ButtonContent>
              <ButtonTitle>Tenho uma conta</ButtonTitle>
              <ButtonText>Clique aqui para fazer login</ButtonText>
            </ButtonContent>
          </ButtonAction>
          <ButtonAction onClick={() => handleSelectOption('signUp')}>
            <ButtonIcon>
              <IconAddUser />
            </ButtonIcon>
            <ButtonContent>
              <ButtonTitle>Cadastrar-se</ButtonTitle>
              <ButtonText>Crie agora a sua conta Netfans</ButtonText>
            </ButtonContent>
          </ButtonAction>
        </main>
        <footer>
          <Checkbox
            label="Não exibir esta mensagem novamente."
            checked={doNotShowAgain}
            onClick={() => setDoNotShowAgain(!doNotShowAgain)}
          />
        </footer>
      </Content>
    </ModalContainer>
  ) : null;
};

export default FirstTimeHere;
