import {
  getDashboardFailed,
  getDashboardSuccess,
} from 'data/store/reducer/dashboard/actions/get';
import { iActionGetDashboard } from 'domain/interfaces/redux/dashboard/get';
import { GetDashboard } from 'domain/usecases/dashboard/redux';
import { makeRemoteGetDashboard } from 'main/factories/usecases/dashboard/GetDashboardFactory';
import { call, put } from 'redux-saga/effects';

export function* onGet(action: iActionGetDashboard) {
  const remoteGetDashboard = makeRemoteGetDashboard();

  try {
    const response: GetDashboard.Model = yield call(
      remoteGetDashboard.get,
      action.payload,
    );
    yield put(
      getDashboardSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(getDashboardFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetFailed() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onSetCurrentPage() {}
