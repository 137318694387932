import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 40.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.black2};
  border-radius: 1rem;

  & main[id='modal-first-time-here'] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;
  }
`;

export const HeaderModal = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.2rem 1.6rem;

  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
`;

export const IconHeaderModal = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 1.2rem;

  background-color: ${({ theme }) => theme.colors.black8};
`;

export const TitleHeaderModal = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.5rem;
  color: ${({ theme }) => theme.colors.white1};
`;

export const BodyModal = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 2.4rem;
  gap: 2rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1.2rem;

  & button[class*='button-modal-guest-user'] {
    width: 100%;
  }
`;
