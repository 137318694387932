/* eslint-disable react/react-in-jsx-scope */

import {
  IconDeleteSession,
  IconEditSession,
  IconJoinSession,
  IconSuspendOffSession,
} from 'presentation/base/icons';
import { ButtonActionStyled as Button } from './styles';

const buttonActionType: Record<
  'join' | 'edit' | 'suspend' | 'delete',
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
> = {
  join: IconJoinSession,
  edit: IconEditSession,
  suspend: IconSuspendOffSession,
  delete: IconDeleteSession,
};

type ButtonActionType = keyof typeof buttonActionType;

interface ButtonActionProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  actionType: ButtonActionType;
}

const ButtonAction: React.FC<ButtonActionProps> = ({ actionType, ...rest }) => {
  const Icon = buttonActionType[actionType];

  return (
    <Button type="button" actionType={actionType} {...rest}>
      <Icon className="icon-button" />
    </Button>
  );
};

export default ButtonAction;
