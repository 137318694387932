import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const schema = {
  name: yup
    .string()
    .min(3, 'O nome da predefinição deve ter no mínimo 3 caracteres')
    .required('O nome da predefinição é obrigatório'),
  descr: yup
    .string()
    .min(3, 'O nome do anunciante deve ter no mínimo 3 caracteres')
    .max(256, 'O nome do anunciante deve ter no máximo 256 caracteres')
    .required('A descrição do anuncio é obrigatória'),
  url: yup.string().required('URL a ser acessada deve ser informada'),
  label: yup
    .string()
    .required('Titulo do botão deve ser informado')
    .default('Saiba mais'),
  color: yup.string().required('Por favor, selecione uma cor'),
  logo: yup.mixed().required('O logotipo do anunciante é obrigatório'),
  banner: yup.mixed().required('O banner do anunciante é obrigatório'),
};

export const schemaCreateSponsor = yupResolver(yup.object().shape(schema));
