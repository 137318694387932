import { call, put } from 'redux-saga/effects';

import {
  loginFailed,
  loginSuccess,
} from 'data/store/reducer/auth/actions/login';

import { makeRemoteLogin } from 'main/factories/usecases/auth/LoginFactory';

import {
  iActionLogin,
  iActionLoginSuccess,
} from 'domain/interfaces/redux/auth/Login';

import { iAuth } from 'domain/interfaces/models';
import { Login } from 'domain/usecases/auth/redux';

import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';

export function* onLogin(action: iActionLogin) {
  const user = makeRemoteLogin();

  try {
    if (action.payload.guest) {
      const nameSplited = action.payload.login.split(' ');
      const firstName = nameSplited[0];
      const lastName = nameSplited[nameSplited.length - 1];

      const guestUser: Login.Model['user'] = {
        birthdate: new Date().toLocaleDateString(),
        cpf: '412.854.854-12',
        email: 'teste@convidado.com',
        firstName,
        fullName: action.payload.login,
        lastName: nameSplited.length > 1 ? lastName : '',
        phone: '+55 (11) 99999-9999',
        username:
          nameSplited.length > 1 ? `${firstName} ${lastName}` : `${firstName}`,
      };

      const mapToRedux: Login.ModelToRedux = {
        accessToken: 'mock-token',
        accessTokenExpiration: 'mock-token-expiration',
        loading: false,
        refreshToken: 'mock-refresh-token',
        refreshTokenExpiration: 'mock-refresh-token-expiration',
        user: guestUser,
        wrtcInfo: {} as iAuth['wrtcInfo'],
      };

      yield put(loginSuccess(mapToRedux));
    } else if (action.payload.socialUser) {
      const { socialUser } = action.payload;

      const mapToRedux: Login.ModelToRedux = {
        accessToken: socialUser.accessToken,
        accessTokenExpiration: socialUser.accessTokenExpiration,
        loading: false,
        refreshToken: socialUser.refreshToken,
        refreshTokenExpiration: socialUser.refreshTokenExpiration,
        user: {
          birthdate: socialUser.user.birthdate,
          cpf: socialUser.user?.cpf ?? '412.854.854-12',
          email: socialUser.user?.email ?? 'teste@social.com',
          firstName: socialUser?.user?.firstName ?? 'Anônimo',
          fullName: socialUser?.user?.fullName ?? 'Anônimo',
          lastName: socialUser?.user?.lastName ?? 'Anônimo',
          phone: socialUser?.user?.phone ?? '+55 (11) 99999-9999',
          username: socialUser?.user?.firstName ?? 'Anônimo',
        },
        wrtcInfo: {} as iAuth['wrtcInfo'],
      };

      yield put(loginSuccess(mapToRedux));
    } else {
      const responseLogin: Login.Model = yield call(user.login, action.payload);

      const mapToRedux: Login.ModelToRedux = {
        accessToken: responseLogin.accessToken,
        accessTokenExpiration: responseLogin.accessTokenExpiration,
        loading: false,
        refreshToken: responseLogin.refreshToken,
        refreshTokenExpiration: responseLogin.refreshTokenExpiration,
        user: responseLogin.user,
        wrtcInfo: {} as iAuth['wrtcInfo'],
      };

      yield put(loginSuccess(mapToRedux));
    }
  } catch (e) {
    yield put(loginFailed());
  }
}

export function onLoginSuccess(action: iActionLoginSuccess) {
  // History.push('/appointment');
  makeReduxActiveMessage().active({
    active: MessageOptions.none,
  });
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLoginFailed() {}
