export enum AuthTypes {
  LOGIN = '@auth/LOGIN',
  LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS',
  LOGIN_FAILED = '@auth/LOGIN_FAILED',
  REFRESH = '@auth/REFRESH',
  REFRESH_SUCCESS = '@auth/REFRESH_SUCCESS',
  REFRESH_FAILED = '@auth/REFRESH_FAILED',
  LOGOUT = '@auth/LOGOUT',
  UPDATE_INFO = '@auth/UPDATE_INFO',
  UPDATE_WRTC_INFO = '@auth/UPDATE_WRTC_INFO',
  UPDATE_SOCKET_TOKEN = '@auth/UPDATE_SOCKET_TOKEN',
}
