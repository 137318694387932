import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1.2rem;

  background-color: ${({ theme }) => theme.colors.black2};
  border-top: 0.1rem solid ${({ theme }) => theme.colors.black3};
`;

type PaginationItemProps = {
  active?: boolean;
};

export const PaginationButton = styled.button<PaginationItemProps>`
  width: 3.6rem;
  height: 3.6rem;

  border: 1px solid ${({ theme }) => theme.colors.black3};
  background-color: ${({ theme, active }) =>
    theme.colors[active ? 'blue2' : 'black2']};

  color: ${({ theme }) => theme.colors.white1};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: normal;

  &:first-child,
  &:last-child {
    padding: 0 1.4rem;
    width: auto;
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  &:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  &:hover {
    background-color: ${({ theme, active }) =>
      theme.colors[active ? 'blue1' : 'black3']};
    color: ${({ theme }) => theme.colors.white2};
  }

  &:focus-visible {
    outline: none;
    border: 2px solid
      ${({ theme, active }) => theme.colors[active ? 'white2' : 'blue1']};
    background-color: ${({ theme, active }) =>
      theme.colors[active ? 'blue2' : 'black3']};
    color: ${({ theme }) => theme.colors.white2};
    box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.colors.blue1};
  }

  &:active {
    background-color: ${({ theme, active }) =>
      theme.colors[active ? 'blue1' : 'black3']};
    color: ${({ theme, active }) => theme.colors[active ? 'blue2' : 'blue1']};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.black1};
    color: ${({ theme }) => theme.colors.white5};
    border-color: ${({ theme }) => theme.colors.black1};
  }
`;
