import store from 'data/store';
import { getDashboardRequest } from 'data/store/reducer/dashboard/actions/get';
import {
  DashboardTypes,
  iActionGetDashboard,
} from 'domain/interfaces/redux/dashboard/get';
import { GetDashboard } from 'domain/usecases/dashboard/redux';
import { Dispatch } from 'redux';

export class ReduxGetDashboard implements GetDashboard {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetDashboard.Params): iActionGetDashboard =>
    this.send(getDashboardRequest(params));

  setCurrentPage = (currentPage: number): void => {
    this.send({
      type: DashboardTypes.SET_CURRENT_PAGE,
      payload: currentPage,
    });
  };
}
