import { Avatar as AvatarMui } from '@material-ui/core';
import { StoreIcon } from 'presentation/base/icons';
import { Button } from 'presentation/components/UI';
import styled from 'styled-components';
import media from 'utils/mediaqueries';
import { HeaderProps } from './types';

export const Container = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  position: ${({ notFixed }) => (notFixed ? 'sticky' : 'fixed')};
  width: 100%;
  height: 6rem;
  padding: 12px 48px;
  background-color: ${({ theme }) => theme.colors.black2};
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.black3}; */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 3;

  ${media.lessThan('md')} {
    padding: 12px 21px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 100%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: flex-end;

  /* ${media.lessThan('xs')} {
    width: 250px;
  } */

  /* ${media.lessThan('sm')} {
    justify-content: end;
  } */
`;

export const LogoContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;

  a {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;

    .name {
      margin-left: 13px;
      padding-top: 2px;

      ${media.lessThan('xxl')} {
        display: none;
      }
    }
  }

  ${media.lessThan('md')} {
    width: 150px;
    /* border: 1px solid red; */
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 1.8rem;
  margin: 0px 10px;
  font-family: 'Poppins';

  &:hover {
    color: white;
  }

  @media (max-width: 700px) {
    font-size: 0.8rem;
  }
  ${media.lessThan('md')} {
    font-size: 1.5rem;
  }
`;

export const LinkGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 0 30px 0 0;

  a {
    :link {
      text-decoration: none;
    }
    :visited {
      text-decoration: none;
    }
    :hover {
      text-decoration: none;
    }
    :active {
      text-decoration: none;
    }
  }

  &:after,
  &:before {
    content: '';
    width: 1px;
    height: 100%;
    background: ${({ theme }) => theme.colors.white1};
    opacity: 0.08;
    margin: 1.2rem 0;
  }

  ${media.lessThan('lg')} {
    display: none;
  }
`;

export const MenuContainer = styled.div`
  display: none;
  align-items: center;
  margin: 0 30px 0 0;

  &:after,
  &:before {
    content: '';
    width: 1px;
    height: 100%;
    background: ${({ theme }) => theme.colors.white1};
    opacity: 0.08;
    margin: 1.2rem 0;
  }

  ${media.lessThan('lg')} {
    display: flex;
  }

  ${media.lessThan('sm')} {
    display: none;
  }
`;

export const LinkContent = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0px 30px;

  font-family: 'Poppins';
  &.sports {
    color: ${({ theme }) => theme.colors.blue1};
  }
  &.music {
    color: ${({ theme }) => theme.colors.green1};
  }
  &.entertainment {
    color: ${({ theme }) => theme.colors.pink1};
  }

  &:hover {
    color: white;
  }

  @media (max-width: 700px) {
    font-size: 0.8rem;
  }
`;

export const SearchInput = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: 30rem;
  max-width: 495px;
  border: none;

  ${media.lessThan('xxl')} {
    max-width: 341px;
  }

  ${media.lessThan('xl')} {
    max-width: 337px;
  }

  ${media.lessThan('lg')} {
    max-width: 350px;
  }

  ${media.lessThan('md')} {
    display: none;
  }
`;

export const StyledButton = styled(Button)`
  height: 100%;
`;

export const MiniSearch = styled.div`
  display: none;
  align-items: center;

  button {
    background: #3f4660;
    border-radius: 5px;
    width: 34px;
    height: 34px;
    border: none;
    margin: 0px 7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${media.lessThan('md')} {
    display: flex;
  }

  ${media.lessThan('sm')} {
    button {
      background: none;
    }
  }
`;

export const MobileLogoContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .teste {
    display: none;
  }

  ${media.lessThan('md')} {
    .teste {
      display: flex;
      border: none;
    }
    .button {
      display: none;
    }
  }
`;

export const Avatar = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 159px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Poppins';
  gap: 1rem;
  margin-left: 30px;

  div {
    /* background: ${({ theme }) => theme.colors.black5}; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.2rem;
    border-radius: 5px;
  }

  .info {
    width: 110px;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    overflow: hidden;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* white */

    color: #ffffff;

    .name {
      display: flex;
      justify-content: start;
    }

    .value {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #ffffff;
      opacity: 0.55;
    }
  }

  .icon {
    width: 15px;
  }

  ${media.lessThan('sm')} {
    display: none;
  }
`;

export const AvatarResponsive = styled.button`
  width: 0px;
  overflow: hidden;
  height: 34px;
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;

  ${media.lessThan('sm')} {
    width: 34px;
    margin-left: 7px;
  }
`;

export const LoginAvatar = styled(AvatarMui)`
  &.makeStyles-root-35 {
    cursor: pointer;

    width: 34px;
    height: 34px;
    background-color: ${({ theme }) => theme.colors.black5};
  }
`;

export const StoreIconContent = styled(StoreIcon)`
  width: 16px;
  height: 16px;
`;

export const LoggedActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  button {
    background: #3f4660;
    border-radius: 5px;
    width: 34px;
    height: 34px;
    border: none;
    margin: 0px 7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  ${media.lessThan('md')} {
    margin: 0;
  }

  ${media.lessThan('sm')} {
    button {
      background: none;
    }
  }

  &:after,
  &:before {
    content: '';
    width: 1px;
    height: 100%;
    background: ${({ theme }) => theme.colors.white1};
    opacity: 0.08;
    margin: 1.2rem 0;
  }

  &:before {
    margin-right: 23px;

    ${media.lessThan('md')} {
      display: none;
      margin: 0;
    }
  }

  &:after {
    margin-left: 23px;

    ${media.lessThan('sm')} {
      display: none;
      margin: 0;
    }
  }
`;

export const MiniMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  button {
    background: ${({ theme }) => theme.colors.black2};
    border-radius: 5px;
    width: 34px;
    height: 34px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* &:after {
    content: '';
    width: 1px;
    height: 100%;
    background: ${({ theme }) => theme.colors.white1};
    opacity: 0.08;
    margin: 1.2rem 30px 1.2rem 30px;

    ${media.lessThan('md')} {
      display: none;
    }
  } */

  ${media.lessThan('lg')} {
    display: flex;
  }

  ${media.lessThan('sm')} {
    button {
      background: none;
    }
  }
`;
