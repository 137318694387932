import { iDashboard } from 'domain/interfaces/models/Dashboard';
import { DashboardTypes } from 'domain/interfaces/redux/dashboard/types';
import { DashboardActions } from './actions';

export const initialState: iDashboard = {
  loading: false,
  currentPage: 1,
  totalPages: 1,
  pagesCache: {} as iDashboard['pagesCache'],
};

const reducer = (
  state = initialState,
  action: DashboardActions,
): iDashboard => {
  switch (action.type) {
    case DashboardTypes.GET:
      return { ...state, loading: true };
    case DashboardTypes.GET_FAILED:
      return { ...state, loading: false };
    case DashboardTypes.GET_SUCCESS: {
      const { records, metadata, currentPage } = action.payload;

      const { total, limit } = metadata;

      const totalPages = Math.ceil(total / limit);

      return {
        ...state,
        totalPages,
        currentPage,
        pagesCache: {
          ...state.pagesCache,
          [currentPage]: {
            records: {
              ...records,
              sessions: records.sessions.sort((a, b) => a.id - b.id),
            },
            timestamp: Date.now(),
          },
        },
        loading: false,
      };
    }

    case DashboardTypes.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };

    default:
      return state;
  }
};

export default reducer;
