import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeRemotePostConfirmUser } from '../../usecases/confirmuser/ConfirmUser';
import Home from '../../../../presentation/pages/Home';

export const ConfirmUserFactory: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const tokenConfirm = query.get('token');

  const history = useHistory();

  useEffect(() => {
    console.log('e', tokenConfirm);
    if (tokenConfirm) {
      makeRemotePostConfirmUser()
        .post({
          token: tokenConfirm,
        })
        .then(res => toast.success('Usuário confirmado com sucesso!'))
        .catch(error => toast.error('Falha ao confirmar usuário'))
        .finally(() => history.push('/'));
    }
  }, [tokenConfirm, history]);

  return <div />;
};
