import io, { Socket } from 'socket.io-client';
import {
  SocketClient,
  SocketRequest,
  SocketResponse,
} from 'data/protocols/socket';

import { SocketEvents } from 'domain/interfaces/socketEvents';

class SocketIO implements SocketClient {
  private static Instance: SocketIO;

  private static Socket: typeof Socket;

  public static getInstance(): SocketIO {
    if (!SocketIO.Instance) {
      SocketIO.Instance = new SocketIO();
    }

    return SocketIO.Instance;
  }

  public getSocket = (): typeof Socket => {
    return SocketIO.Socket;
  };

  /**
   * connect to the server.
   */
  public connect = (token: string): void => {
    console.log('>>> Connect token: ', token);
    SocketIO.Socket = io(window.config.socketBaseUrl, {
      query: {
        token,
      },
    });

    this.listening();
  };

  /**
   * Disconnect to the server.
   */
  public disconnect = (): void => {
    SocketIO.Socket.disconnect();
  };

  /**
   * Emit event.
   */
  public emit = (data: SocketRequest): Promise<SocketResponse> => {
    console.log('>>> Emit event socket: ', data);
    return new Promise((resolve, reject) => {
      SocketIO.Socket.emit(
        data.event,
        data.body,
        (err: any, dataResponse: any) => {
          console.log('error: ', err);
          console.log('data: ', dataResponse);
          if (dataResponse) resolve({ body: dataResponse });
          if (err) reject(err);
        },
      );
    });
  };

  /**
   * Listen event.
   */
  private listening = (): void => {
    console.log('>>> Listening socket: ', SocketIO.Socket);

    SocketIO.Socket.on('REACTIONS', (data: SocketEvents) => {
      console.log('>>> Receive: ', data);
    });
  };
}

export default SocketIO.getInstance();
