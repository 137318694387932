import { CreateEvent } from 'domain/usecases/event/remote';
import NewEvent from 'presentation/components/EventsForm/NewEvent';
import Header from 'presentation/components/Header';
import NewEventProvider, {
  NewEventContext,
} from 'presentation/contexts/NewEventContext';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { schemaCreateEvent } from 'validation/validators/CreateEvent/CreateEventValidator';
import { Container } from './styles';

const NewEventPage: React.FC = () => {
  const formHook = useForm<CreateEvent.Params>({
    resolver: schemaCreateEvent,
    shouldFocusError: true,
    shouldUnregister: true,
    reValidateMode: 'onChange',
    context: NewEventContext,
    defaultValues: {
      mediaType: 'LIVE',
      subCategories: [],
      banner: undefined,
      media: undefined,
      thumbnail: undefined,
    },
  });

  return (
    <FormProvider {...formHook}>
      <NewEventProvider>
        <Container>
          <Header notFixed />
          <NewEvent />
        </Container>
      </NewEventProvider>
    </FormProvider>
  );
};

export default NewEventPage;
