import React from 'react';

import { IconSound, NewCloseIcon } from 'presentation/base/icons';
import { useVolume } from 'presentation/contexts/VolumeContext';
import {
  Box,
  Container,
  Content,
  Header,
  PublicVolumeSlider,
  Title,
  TitleContent,
  TransmissionVolumeSlider,
  VolumeContainer,
  VolumeTitle,
} from './styles/StyledVolumeSliderContent';

import { Button } from '../UI';

interface ownProps {
  eventVolume: number;
  publicVolume: number;
  handleEventVolumeChange: (value: number) => void;
  handlePublicVolumeChange: (value: number) => void;
  handleClose: () => void;
}

const VolumeSliderContent: React.FC<ownProps> = ({
  eventVolume,
  publicVolume,
  handleEventVolumeChange,
  handlePublicVolumeChange,
  handleClose,
}) => {
  const { audienceVolume, volumePlayer, setAudienceVolume, setVolumePlayer } =
    useVolume();

  return (
    <Container>
      <Header>
        <TitleContent>
          <Box>
            <IconSound />
          </Box>
          <Title>Ajustar volume</Title>
        </TitleContent>
        <div>
          <div>
            <Button
              id="btn_close"
              onClick={handleClose}
              colorstyle="black2"
              size="very small"
              icon={NewCloseIcon}
            />
          </div>
        </div>
      </Header>
      <Content>
        <div>
          <VolumeTitle>Volume da transmissão</VolumeTitle>
          <VolumeContainer>
            <IconSound />
            <TransmissionVolumeSlider
              value={volumePlayer}
              onChange={(e, newVolume) => setVolumePlayer(newVolume as number)}
            />
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Checkbox checked={false} onClick={() => console.log('error')} />
              <VolumeContentText>Sem som</VolumeContentText>
            </div> */}
          </VolumeContainer>
        </div>

        <div>
          <VolumeTitle>Volume da platéia</VolumeTitle>
          <VolumeContainer>
            <IconSound />
            <PublicVolumeSlider
              value={audienceVolume}
              onChange={(e, newVolume) =>
                setAudienceVolume(newVolume as number)
              }
            />
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Checkbox checked={false} onClick={() => console.log('error')} />
              <VolumeContentText>Sem som</VolumeContentText>
            </div> */}
          </VolumeContainer>
        </div>
      </Content>
    </Container>
  );
};

export default VolumeSliderContent;
