import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.4rem 1.6rem;

  @media (min-width: 1024px) {
    padding: 2.4rem 4.8rem;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.black5};
  border-radius: 1rem;

  overflow: hidden;
  color: ${({ theme }) => theme.colors.white1};

  flex-grow: 1;

  @media (max-width: 1366px) {
    min-width: inherit;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  gap: 1.8rem;
  width: 100%;

  @media (min-width: 768px) {
    gap: 1.6rem;
  }

  @media (min-width: 1366px) {
    width: auto;
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  gap: 1.8rem;

  flex-wrap: wrap;
  width: 100%;
  @media (min-width: 1366px) {
    flex-wrap: nowrap;
  }
`;

export const CardTitle = styled.div`
  width: 100%;
  height: 6rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2rem;

  background-color: ${({ theme }) => theme.colors.black2};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
  padding: 1.2rem 1.6rem;

  p.title {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.5rem;
  }
`;

export const CardTitleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  background: ${({ theme }) => theme.colors.black4};
  border-radius: 50%;
`;

export const CardContent = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.4rem;
  padding: 2.4rem;
`;

export const CardContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;

  @media (min-width: 1024px) {
    max-width: 75%;
  }

  & > p {
    margin: 0;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin-bottom: 0.4rem;
  }

  & > span {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: normal;

    text-align: start;
    overflow-wrap: break-word;
    white-space: wrap;
  }
`;
