import { GetDashboard as UsecaseReduxGetDashboard } from 'domain/usecases/dashboard/redux';
import { GetDashboard as UsecaseRemoteGetDashboard } from 'domain/usecases/dashboard/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { RemoteGetDashboard } from 'data/repository/dashboard';
import { ReduxGetDashboard } from 'data/store/reducer/dashboard/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetDashboard = (): UsecaseRemoteGetDashboard =>
  new RemoteGetDashboard(makeApiUrl('/dashboard/event'), makeHttpClient());

/**  send request via REDUX */
export const makeReduxGetDashboard = (): UsecaseReduxGetDashboard =>
  new ReduxGetDashboard();
