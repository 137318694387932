import { LoginWithGoogle as UseCaseRemoteLoginWithGoogle } from 'domain/usecases/auth/remote';

import { RemoteAuthLoginWithGoogle } from 'data/repository/auth';
import { makeApiUrl, makeHttpClient } from '../../http';

/**
 * make login with google via API.
 * @example
 * makeRemoteLoginWithGoogle()
 * .loginWithGoogle({ token: '1234567890' })
 * .then((response) => {
 * // do something
 * })
 * .catch((error) => {
 * // handle error
 * })
 * @example
 * const { loginWithGoogle } = makeRemoteLoginWithGoogle();
 * loginWithGoogle({ token: 'token' })
 * .then((response) => {
 * // do something
 * })
 * .catch((error) => {
 * // handle error
 * });
 */
export const makeRemoteLoginWithGoogle = (): UseCaseRemoteLoginWithGoogle =>
  new RemoteAuthLoginWithGoogle(makeApiUrl('/auth/social'), makeHttpClient());
