import React from 'react';

import Socket from './index';

export const ConnectSocket: React.FC<{ token?: string }> = ({
  token,
  children,
}): JSX.Element => {
  const subs = (Socket.getSocket() as any)?.subs;

  const isFriends = window.location.pathname.includes('/friends');

  if (isFriends && token && (subs === undefined || subs.length < 1)) {
    Socket.connect(token);
  }

  return <>{children}</>;
};
