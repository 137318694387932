/* eslint-disable react/react-in-jsx-scope */

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import {
  IconPersonPanel,
  IconPlus,
  IconSession,
  IconTrophy,
} from 'presentation/base/icons';
import FloatButton from 'presentation/components/UI/FloatButton';
import Table from './Table';
import {
  Body,
  Card,
  CardContent,
  CardContentText,
  CardTitle,
  CardTitleIcon,
  ContainerCards,
  ContainerRow,
} from './styles';

const DashboardPage: React.FC = () => {
  const history = useHistory();
  const { accessToken } = useSelector((store: iStore) => store.auth);
  const { currentPage, loading, pagesCache } = useSelector(
    (store: iStore) => store.dashboard,
  );

  const users = pagesCache[currentPage]?.records?.users;
  const records = pagesCache[currentPage]?.records?.records;
  const { sessionActive, sessionTotal } = pagesCache[currentPage]?.records ?? {
    sessionActive: 0,
    sessionTotal: 0,
  };

  const redirectToCreateEventPage = () => {
    if (!accessToken) {
      makeReduxActiveMessage().active({
        active: MessageOptions.loginModal,
      });
    }
    history.push('/new-event');
  };

  return (
    <Body>
      <ContainerCards>
        <ContainerRow>
          <Card>
            <CardTitle>
              <CardTitleIcon>
                <IconPersonPanel />
              </CardTitleIcon>
              <p className="title">Usuários</p>
            </CardTitle>
            <CardContent>
              <CardContentText>
                <p>{users?.total ?? 0}</p>
                <span>Usuários cadastrados até o momento</span>
              </CardContentText>
              <CardContentText>
                <p>{users?.active ?? 0}</p>
                <span>Usuários estão on-line</span>
              </CardContentText>
            </CardContent>
          </Card>
          <Card>
            <CardTitle>
              <CardTitleIcon>
                <IconSession />
              </CardTitleIcon>
              <p className="title">Sessões</p>
            </CardTitle>
            <CardContent>
              <CardContentText>
                <p>{sessionTotal ?? 0}</p>
                <span>Sessões criadas até o momento</span>
              </CardContentText>
              <CardContentText>
                <p>{sessionActive ?? 0}</p>
                <span>Acontecendo agora</span>
              </CardContentText>
            </CardContent>
          </Card>
        </ContainerRow>
        <Card>
          <CardTitle>
            <CardTitleIcon>
              <IconTrophy />
            </CardTitleIcon>
            <p className="title">Recordes</p>
          </CardTitle>
          <CardContent>
            <CardContentText>
              <p>{records?.onDay ?? 0}</p>
              <span>Recorde de sessões em um único dia</span>
            </CardContentText>
            <CardContentText>
              <p>{records?.usersMaxActive ?? 0}</p>
              <span>Recorde de usuários on-line em um único dia</span>
            </CardContentText>
            <CardContentText>
              <p>{records?.usersMaxOnSession ?? 0}</p>
              <span>Recorde de público em uma só sessãos</span>
            </CardContentText>
          </CardContent>
        </Card>
      </ContainerCards>
      <Table />
      <FloatButton icon={IconPlus} action={redirectToCreateEventPage}>
        Agendar sessão
      </FloatButton>
    </Body>
  );
};

export default DashboardPage;
